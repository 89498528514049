import styled from "styled-components";

import { Link } from "react-router-dom";

export const RadioButton = styled.label`
  display: flex;
  flex: 1 1 auto;
  & + & {
    margin-left: 10px;
  }
`;
export const RadioWrap = styled.div`
  display: flex;
  background: #f4f7ff;
  border-radius: 10px;
  flex: 1 1 auto;
  padding: 5px;
`;

export const ErrorBox = styled.div`
  color: #d71c1c;
  background: #f5d7d7;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
`;

export const RadioLabel = styled.div`
  border-radius: 10px;
  height: 50px;
  width: 100%;
  padding: 0 15px;
  font-size: 18px;
  line-height: 23px;
  color: #8a949e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
`;

export const RadioInput = styled.input`
  display: none;
  &:checked + ${RadioLabel} {
    font-weight: 700;
    background: #286fb7;
    border-radius: 10px;
    color: #fdfdfd;
  }
`;

export const Head = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #293a47;
  margin-bottom: 24px;
`;

export const Input = styled.input`
  background: #ffffff;
  border: 0.5px solid #efefef;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-size: 18px;
  padding: 0 15px;
  outline: 0;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  margin-bottom: 4px;
`;

export const Field = styled.label`
  display: block;
  position: relative;
  & + & {
    margin-top: 33px;
  }
  &[data-valid="false"] > ${Input} {
    border-color: red;
  }
  &[data-valid="false"] > ${Label} {
    color: red;
  }
`;

export const FieldLine = styled.div`
  display: flex;
  flex: 1 1 auto;
  & > ${Field} + ${Field} {
    margin-left: 15px;
    margin-top: 0;
    width: 50%;
  }
  & + ${Field} {
    margin-top: 30px;
  }
`;

export const Button = styled.button`
  margin-top: 30px;
  width: 100%;
  background: #f4f7ff;
  cursor: pointer;
  border: 1px solid #009769;
  border-radius: 10px;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #009769;
  &[data-type="gray"] {
    color: #767778;
    border: 1px solid #767778;
  }
`;

export const ButtonLink = styled(Link)`
  margin-top: 30px;
  width: 100%;
  display: block;
  text-decoration: none;
  background: #f4f7ff;
  cursor: pointer;
  border: 1px solid #009769;
  border-radius: 10px;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #009769;
  &[data-type="gray"] {
    color: #767778;
    border: 1px solid #767778;
  }
`;

export const ButtonSmallLink = styled(Link)`
  width: 100%;
  background: #f4f7ff;
  cursor: pointer;
  border: 1px solid #009769;
  border-radius: 10px;
  height: 40px;
  font-weight: 700;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  color: #009769;
  text-decoration: none;
`;

export const LinkStyled = styled.button`
  border: 0;
  padding: 0;
  background: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #293a47;
  opacity: 0.5;
  margin-top: 22px;
  cursor: pointer;
  text-decoration: none;
`;
