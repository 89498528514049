import React from "react";

import Modal, {
  Container844,
  Head,
  Body,
  Row,
  Col,
  Button,
  ButtonsField,
} from "../Modal";

import CardModal from "./CardModal";

function CreditModal({
  show,
  hide,
  data,
  toggleAction,
  setModalInsurance,
  setModalInsuranceCredit,
  setModalRegister,
  setRegisterMode,
}) {
  const [bid1, setBid1] = React.useState("");
  const [bid2, setBid2] = React.useState("");
  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [modalError, setModalError] = React.useState(false);

  const makeAction = async (obj) => {
    const resp = await toggleAction(obj);
    if (resp.success) {
      setModalSuccess(true);
    } else {
      setModalError(true);
    }
  };

  return (
    <>
      <Modal show={show} valign="center">
        <Container844>
          <Head data-mobile-align="left">Кредитование</Head>
          <Body>
            <Row data-align="center" data-mobile-mt="0" data-mx="-15" data-mb="-10" data-mobile-direction="column">
              <Col data-w="50" data-mobile-w="100" data-px="15" data-mobile-mb="20">
                <Row
                  data-align="center"
                  data-round="10"
                  data-bg="lightBlue"
                  data-py="10"
                  data-text="18"
                  data-bold="true"
                  data-mb="10"
                  data-mobile-mb="5"
                  data-mobile-bold="false"
                  data-mobile-text="17"
                >
                  Потребительский кредит
                </Row>
                <Row
                  data-align="left"
                  data-round="10"
                  data-bg="lightBlue"
                  data-py="20"
                  data-mobile-p="10"
                  data-px="30"
                  data-text="17"
                  data-mh="100"
                >
                  Ставка по потребительскому кредиту 2% в месяц.
                  <br />
                  Чтобы взять дополнительный потребительский кредит —
                  застрахуйте предыдущий.
                </Row>
                <Row data-mt="20" data-mobile-mt="5">
                  {data && data.vars && (
                    <>
                      {data.vars.creditInsured ? (
                        <>
                          <ButtonsField
                            type="number"
                            min="0"
                            step="1"
                            placeholder="Введите сумму"
                            value={bid1}
                            onChange={(e) => setBid1(e.target.value)}
                            data-mobile-mr="0"
                            data-w="100"
                          />
                          <Button
                            data-type="blue"
                            data-mb="0"
                            onClick={() => {
                              makeAction({
                                consumerCreditCost: bid1,
                              });
                              setBid1("");
                            }}
                          >
                            Получить
                          </Button>
                        </>
                      ) : (
                        <Button
                          data-type="blue"
                          data-w="100"
                          data-max-w="none"
                          data-mb="0"
                          onClick={() => setModalInsuranceCredit(true)}
                        >
                          Застраховать предыдущий кредит
                        </Button>
                      )}
                    </>
                  )}
                </Row>
              </Col>
              <Col data-w="50" data-mobile-w="100" data-px="15">
                <Row
                  data-align="center"
                  data-round="10"
                  data-bg="lightBlue"
                  data-py="10"
                  data-text="18"
                  data-bold="true"
                  data-mb="10"
                  data-mobile-mb="5"
                  data-mobile-bold="false"
                  data-mobile-text="17"
                >
                  Кредитная карта
                </Row>
                <Row
                  data-align="left"
                  data-round="10"
                  data-bg="lightBlue"
                  data-py="20"
                  data-px="30"
                  data-text="17"
                  data-mh="100"
                  data-mobile-p="10"
                >
                  Вы можете снять наличные с кредитной карты лимитом в 200 000
                  р.
                  <br />
                  Процентная ставка составит 2% в месяц.
                </Row>
                <Row data-mt="20" data-mobile-mt="5">
                  <ButtonsField
                    type="number"
                    min="0"
                    step="1"
                    placeholder="Введите сумму"
                    value={bid2}
                    onChange={(e) => setBid2(e.target.value)}
                    data-mobile-mr="0"
                    data-w="100"
                  />
                  <Button
                    data-type="blue"
                    data-mb="0"
                    onClick={() => {
                      makeAction({
                        creditCardCost: bid2,
                      });
                      setBid2("");
                    }}
                  >
                    Получить
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
              data-mobile-mx="0"
            >
              <Button
                data-type="green"
                onClick={() => {
                  setModalRegister(true);
                  setRegisterMode("passive");
                }}
                data-mobile-w="100"
                data-mobile-mx="0"
              >
                Погасить кредиты
              </Button>
              <Button data-type="green" onClick={hide} data-mobile-w="100" data-mobile-mx="0">
                К игре
              </Button>
            </Row>
          </Body>
        </Container844>
      </Modal>
      {modalSuccess && (
        <CardModal
          show={modalSuccess}
          hide={() => setModalSuccess(false)}
          modal={{
            type: "info",
            text: "Кредит успешно получен.",
            buttons: [
              {
                type: "green",
                name: "К игре",
              },
            ],
          }}
          action={() => {
            hide();
            setModalSuccess(false);
          }}
        />
      )}
      {modalError && (
        <CardModal
          show={modalError}
          hide={() => setModalError(false)}
          modal={{
            type: "info",
            text: "Ваше финансовое состояние в данный момент игры не позволяет взять указанную сумму.",
            buttons: [
              {
                type: "green",
                name: "К игре",
              },
            ],
          }}
          action={() => {
            hide();
            setModalError(false);
          }}
        />
      )}
    </>
  );
}

export default CreditModal;
