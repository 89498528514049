import React from "react";

import Modal, {
  Container580,
  Head,
  Body,
  Row,
  Box,
  Button,
  ButtonsField,
} from "../Modal";

function EconomyModal({ show, hide, data, toggleAction }) {
  const [bid, setBid] = React.useState("");
  return (
    <Modal show={show} valign="center">
      <Container580>
        <Head>Депозит</Head>
        <Body>
          <Row
            data-align="center"
            data-round="10"
            data-bg="lightBlue"
            data-py="20"
            data-px="30"
            data-text="17"
            data-mh="100"
          >
            Вы можете положить наличные на депозит под 0,5% в месяц. Проценты
            начисляются по итогам полного месяца.
          </Row>
          {data && data.vars && data.vars.deposit > 0 && (
            <Row
              data-align="center"
              data-p="10"
              data-round="10"
              data-bg="lightBlue"
              data-mt="20"
            >
              <Box
                data-align="center"
                data-round="10"
                data-bg="lightGray"
                data-text="17"
                data-p="10"
                data-w="100"
              >
                На депозите
                <br />
                <strong>
                  {data &&
                    data.vars &&
                    data.vars.deposit.toLocaleString("ru-RU")}{" "}
                  &#8381;
                </strong>
              </Box>
            </Row>
          )}

          <Row
            data-align="center"
            data-p="10"
            data-round="10"
            data-bg="lightBlue"
            data-mt="20"
            data-mobile-mt="0"
            data-mb="-10"
            data-wrap="wrap"
          >
            {data && data.vars && (
              <>
                <ButtonsField
                  type="number"
                  min="0"
                  step="1"
                  placeholder="Введите сумму"
                  value={bid}
                  onChange={(e) => setBid(e.target.value)}
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                />
                <Button
                  data-type="blue"
                  data-mb="0"
                  onClick={() => toggleAction({ deposit: bid })}
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                >
                  Положить
                </Button>
              </>
            )}
            {data && data.vars && data.vars.deposit > 0 && (
              <Button
                data-type="blue"
                data-mb="0"
                onClick={() => toggleAction({ _closeDeposit: bid })}
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-mt="10"
              >
                Снять
              </Button>
            )}
          </Row>

          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
          >
            <Button data-type="green" onClick={hide}>
              К игре
            </Button>
          </Row>
        </Body>
      </Container580>
    </Modal>
  );
}

export default EconomyModal;
