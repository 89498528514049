import React, { useRef, useEffect } from "react";

import styled from "styled-components";

import Side1 from "./../img/die/1.png";
import Side2 from "./../img/die/2.png";
import Side3 from "./../img/die/3.png";
import Side4 from "./../img/die/4.png";
import Side5 from "./../img/die/5.png";
import Side6 from "./../img/die/6.png";
import FieldBg from "./../img/FieldBg.png";

import * as THREE from "three";
//window.THREE = THREE;
//import {OrbitControls} from "three/examples/js/controls/OrbitControls";
import OrbitControls from "./three/OrbitControls";
import CANNON from "cannon";
import { DiceManager, DiceD6 } from "threejs-dice/lib/dice";

const Wrap = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Dice = ({ roll, dices }) => {
  const canvasRef = useRef(null);

  // standard global variables
  var scene,
    camera,
    renderer,
    controls,
    world,
    dice = [];

  const handleResize = () => {
    const rect = canvasRef.current.getBoundingClientRect();
    renderer.setSize(rect.width, rect.height);
    camera.aspect = rect.width / rect.height;
    camera.updateProjectionMatrix();
  }

  useEffect(() => {
    //console.log("init");
    /*
    let imageList = [Side1, Side2, Side3, Side4, Side5, Side6];
    imageList.map((image) => {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
          resolve();
        };
        img.src = image;
      });
    });
    Promise.all(imageList).then(() => {
      init();
    });
    */
    window.addEventListener('resize', handleResize);
    init();
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, []);

  // FUNCTIONS
  function init() {
    const loader = new THREE.TextureLoader();
    const dieMaterial = [
      new THREE.MeshBasicMaterial({ map: loader.load(Side1) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side1) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side1) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side2) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side3) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side4) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side5) }),
      new THREE.MeshBasicMaterial({ map: loader.load(Side6) }),
    ];

    // SCENE
    scene = new THREE.Scene();
    // CAMERA
    //console.log(canvasRef.current);
    var SCREEN_WIDTH = canvasRef.current.offsetWidth,
      SCREEN_HEIGHT = canvasRef.current.offsetHeight;
    var VIEW_ANGLE = 40,
      ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT,
      NEAR = 0.01,
      FAR = 20000;
    camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
    scene.add(camera);
    camera.position.set(0, 50, 0);
    // RENDERER
    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;


    canvasRef.current.appendChild(renderer.domElement);
    // EVENTS
    // CONTROLS
    //debugger;
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enabled = false;

    let ambient = new THREE.AmbientLight("#ffffff", 0.5);
    scene.add(ambient);

    let directionalLight = new THREE.DirectionalLight("#ffffff", 0.5);
    directionalLight.position.x = -1000;
    directionalLight.position.y = 1000;
    directionalLight.position.z = 1000;
    scene.add(directionalLight);

    let light = new THREE.SpotLight(0xefdfd5, 0.5);
    light.position.y = 100;
    light.target.position.set(0, 0, 0);
    light.castShadow = true;
    light.shadow.camera.near = 50;
    light.shadow.camera.far = 110;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    scene.add(light);

    /*
    // SUBFLOOR
    var subFloorMaterial = new THREE.MeshPhongMaterial({
      color: "#222222",
      side: THREE.DoubleSide,
    });
    var subFloorGeometry = new THREE.PlaneGeometry(50, 50, 10, 10);
    var subFloor = new THREE.Mesh(subFloorGeometry, subFloorMaterial);
    subFloor.receiveShadow = true;
    subFloor.rotation.x = Math.PI / 2;
    //subFloor.position.x = -10;
    subFloor.position.y = -2;
    //subFloor.position.z = -10;
    scene.add(subFloor);
    */

    // FLOOR
    /*
    var floor1Material = new THREE.MeshPhongMaterial({
      color: "#17732B",
      side: THREE.DoubleSide,
    });
    //var floorGeometry = new THREE.PlaneGeometry(30, 30, 10, 10);
    var floor1Geometry = new THREE.CircleGeometry(18.5, 60);
    var floor1 = new THREE.Mesh(floor1Geometry, floor1Material);
    floor1.receiveShadow = true;
    floor1.rotation.x = Math.PI / 2;
    floor1.position.y = -1;
    scene.add(floor1);
    */

    // FLOOR
    var floorMaterial = new THREE.MeshPhongMaterial({
      //color: "#b4bbc3",
      //specular: '#000000',
      //emissive: '#000000',
      //reflectivity:0.2,
      side: THREE.DoubleSide,
      map: loader.load(FieldBg),
    });
    var floorGeometry = new THREE.PlaneGeometry(80, 80, 10, 10);
    //var floorGeometry = new THREE.CircleGeometry(15.5, 60);
    var floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.receiveShadow = true;
    floor.rotation.x = Math.PI / 2;
    scene.add(floor);
    // SKYBOX/FOG
    var skyBoxGeometry = new THREE.BoxGeometry(10000, 10000, 10000);
    var skyBoxMaterial = new THREE.MeshPhongMaterial({
      color: 0x0,
      side: THREE.BackSide,
    });
    var skyBox = new THREE.Mesh(skyBoxGeometry, skyBoxMaterial);
    scene.add(skyBox);
    scene.fog = new THREE.FogExp2(0x0, 0.00025);

    ////////////
    // CUSTOM //
    ////////////
    world = new CANNON.World();

    world.gravity.set(0, -9.82 * 20, 0);
    world.broadphase = new CANNON.NaiveBroadphase();
    world.solver.iterations = 16;

    DiceManager.setWorld(world);

    //Floor
    let floorBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
      material: DiceManager.floorBodyMaterial,
    });
    floorBody.quaternion.setFromAxisAngle(
      new CANNON.Vec3(1, 0, 0),
      -Math.PI / 2
    );
    world.add(floorBody);

    //Walls

    let barrier = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(5, 5000, 5000)),
    });
    barrier.position.set(-20 * ASPECT, 0, 0);
    world.addBody(barrier);

    let wall = new THREE.Mesh(
      new THREE.BoxGeometry(5, 8000, 5000),
      new THREE.MeshPhongMaterial({
        color: "#0000ff",
        opacity: 0,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
    wall.quaternion.set(
      barrier.quaternion.x,
      barrier.quaternion.y,
      barrier.quaternion.z,
      barrier.quaternion.w
    );
    wall.position.set(
      barrier.position.x,
      barrier.position.y,
      barrier.position.z
    );
    scene.add(wall);

    barrier = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(5, 5000, 5000)),
      // material: (diceManager as any).barrierBodyMaterial,
    });
    barrier.position.set(20 * ASPECT, 0, 0);
    world.addBody(barrier);

    wall = new THREE.Mesh(
      new THREE.BoxGeometry(5, 8000, 5000),
      new THREE.MeshPhongMaterial({
        color: "#00ff00",
        opacity: 0,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
    wall.quaternion.set(
      barrier.quaternion.x,
      barrier.quaternion.y,
      barrier.quaternion.z,
      barrier.quaternion.w
    );
    wall.position.set(
      barrier.position.x,
      barrier.position.y,
      barrier.position.z
    );
    scene.add(wall);

    barrier = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(5000, 5000, 5)),
      // material: (diceManager as any).barrierBodyMaterial,
    });
    barrier.position.set(0, 0, -20);
    world.addBody(barrier);

    wall = new THREE.Mesh(
      new THREE.BoxGeometry(7500, 8105, 5),
      new THREE.MeshPhongMaterial({
        color: "#ff0000",
        opacity: 0,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
    wall.quaternion.set(
      barrier.quaternion.x,
      barrier.quaternion.y,
      barrier.quaternion.z,
      barrier.quaternion.w
    );
    wall.position.set(
      barrier.position.x,
      barrier.position.y,
      barrier.position.z
    );
    scene.add(wall);

    barrier = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(5000, 5000, 5)),
      // material: (diceManager as any).barrierBodyMaterial,
    });
    barrier.position.set(0, 0, 20);
    world.addBody(barrier);

    wall = new THREE.Mesh(
      new THREE.BoxGeometry(7500, 8015, 5),
      new THREE.MeshPhongMaterial({
        color: "#ff0000",
        opacity: 0,
        transparent: true,
        side: THREE.DoubleSide,
      })
    );
    wall.quaternion.set(
      barrier.quaternion.x,
      barrier.quaternion.y,
      barrier.quaternion.z,
      barrier.quaternion.w
    );
    wall.position.set(
      barrier.position.x,
      barrier.position.y,
      barrier.position.z
    );
    scene.add(wall);

    var colors = ["#fff", "#fff"];
    for (var i = 0; i < 1; i++) {
      var die = new DiceD6({
        size: 3,
        backColor: colors[i],
        fontColor: "#000",
      });
      var dieObject = die.getObject();
      //debugger;

      if (i === 0) {
        dieObject.position.set(0, 2, 0);
        dieObject.rotation.x = 90;
      } else if (i === 1) {
        dieObject.position.set(5, 1, 0);
        dieObject.rotation.x = 90;
      }
      die.updateBodyFromMesh();

      /*
      var radius = 10;
      var width = 200,
        height = 100;
      var geometry = new THREE.BoxGeometry(width, height, 50, 100, 50, 10);

      var v1 = new THREE.Vector3();
      var w1 = (width - radius * 2) * 0.5,
        h1 = (height - radius * 2) * 0.5;
      var vTemp = new THREE.Vector3(),
        vSign = new THREE.Vector3(),
        vRad = new THREE.Vector3();
      geometry.vertices.forEach((v) => {
        v1.set(w1, h1, v.z);
        vTemp.multiplyVectors(v1, vSign.set(Math.sign(v.x), Math.sign(v.y), 1));
        vRad.subVectors(v, vTemp);
        if (
          Math.abs(v.x) > v1.x &&
          Math.abs(v.y) > v1.y &&
          vRad.length() > radius
        ) {
          vRad.setLength(radius).add(vTemp);
          v.copy(vRad);
        }
      });
      */

      dieObject.material = dieMaterial;

      //let roudedDie = new RoundedBoxGeometry2(3, 3, 3, 1, 10);

      //let boxGeom = new THREE.BoxBufferGeometry(4, 4, 4, 50, 40, 30);
      //debugger;
      //boxGeom.cannon_shape = dieObject.geometry.cannon_shape;
      //boxGeom.faceVertexUvs = dieObject.geometry.faceVertexUvs;
      //boxGeom.faces = dieObject.geometry.faces;
      //dieObject.geometry = boxGeom;
      scene.add(dieObject);
      dice.push(die);
    }

    function randomDiceThrow() {
      console.log("throw");
      var diceValues = [];
      for (var i = 0; i < dice.length; i++) {
        dice[i].getObject().position.x = -10 - (i % 3) * 1.5;
        dice[i].getObject().position.y = 5 + Math.floor(i / 3) * 1.5;
        dice[i].getObject().position.z = -10 + (i % 3) * 1.5;
        dice[i].getObject().quaternion.x =
          ((Math.random() * 90 - 45) * Math.PI) / 180;
        dice[i].getObject().quaternion.z =
          ((Math.random() * 90 - 45) * Math.PI) / 180;
        dice[i].updateBodyFromMesh();

        let yRand = Math.random() * 95;
        let rand = Math.random() * 95;

        dice[i].getObject().body.velocity.set(45 + rand, 30 + yRand, 35 + rand);
        dice[i]
          .getObject()
          .body.angularVelocity.set(
            80 * Math.random() - 10,
            80 * Math.random() - 15,
            80 * Math.random() - 12
          );

        let v = dices.current[i]; //Math.ceil(Math.random() * 20);
        diceValues.push({ dice: dice[i], value: v });
        //console.log(v);
      }

      DiceManager.prepareValues(diceValues);
    }

    roll.current = randomDiceThrow;

    /**
        canvasRef.current.addEventListener(
            "click",
            function () {
                randomDiceThrow();
            },
            false
        );
        // setInterval(randomDiceThrow, 3000);
        // randomDiceThrow();
        */

    requestAnimationFrame(animate);
  }

  function animate() {
    updatePhysics();
    render();
    update();

    requestAnimationFrame(animate);
  }

  function updatePhysics() {
    world.step(1.0 / 60.0);

    for (var i in dice) {
      dice[i].updateMeshFromBody();
    }
  }

  function update() {
    controls.update();
  }

  function render() {
    renderer.render(scene, camera);
  }
  return <Wrap ref={canvasRef}></Wrap>;
};

export default Dice;
