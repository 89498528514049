import React from "react";
import styled from "styled-components";
import { sleep } from "../../Utils";

import Modal, {
  Container580,
  Head,
  Body,
  Text,
  Row,
  Box,
  Button,
  ErrorBox,
} from "../../Modal";

import ShieldIcon from "../../../img/ShieldIcon.svg";

export const ButtonWrap = styled.div`
  background: #f5d7d7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;

const Credit = ({ show, hide, action, data, errors, mode, setModalTechDefolt }) => {
  React.useEffect(() => {
    if (
      show &&
      data &&
      data.vars.consumerCreditInsuranceExpense - data.vars.cash > 0 &&
      data.vars.creditCardLimit -
        data.vars.creditCardCost -
        (data.vars.consumerCreditInsuranceExpense - data.vars.cash) <
        0
    ) {
      const cb = async() => {
        await sleep(1000);
        setModalTechDefolt(true);
      }
      cb();
    }
    // eslint-disable-next-line
  }, [data, show]);

  return (
    <Modal show={show} valign="center">
      <Container580>
        <Head data-align="left">Страхование потребительского кредита</Head>
        <Body>
          <Row data-align="left">
            При страховании жизни и здоровья Вы защищаете себя от невозможности
            выплачивать кредит при неприятностях со здоровьем, таких как разного
            рода травмы и болезни. При страховании от потери работы Вы защищены
            от невозможности выплачивать кредит при потере работы.
            <br />
            <br />
            Страховая премия (стоимость страхования):
            <br />
            5% от суммы кредита сразу.
            <br />
            <br />
            Игровые преимущества:
            <br />
            Возможность взять дополнительный потребительский кредит.
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="100"
                data-halign="center"
                data-mobile-mb="10"
              >
                <Text data-text="17">Потребительский кредит</Text>
                <Text data-text="17">
                  <strong>
                    {data &&
                      data.vars.consumerCreditInsuranceDiff.toLocaleString(
                        "ru-RU"
                      )}{" "}
                    &#8381;
                  </strong>
                </Text>
              </Box>
              {data && data.vars.creditInsured ? (
                <div data-direction="row">
                  <Box
                    data-round="10"
                    data-bg="darkBlue"
                    data-p="10"
                    data-h="60"
                    data-valign="center"
                    data-w="100"
                    data-max-w="254"
                    data-mobile-max-w="none"
                    data-halign="center"
                    data-mr="0"
                    data-ml="10"
                    data-mobile-mx="0"
                  >
                    <Text
                      data-text="17"
                      data-align="center"
                      data-valign="center"
                      data-color="white"
                    >
                      <strong>Застрахован</strong>
                      <img src={ShieldIcon} alt="" data-ml="10" />
                    </Text>
                  </Box>
                </div>
              ) : (
                <Button
                  data-type="blue"
                  data-h="60"
                  data-ml="10"
                  data-mb="0"
                  data-mr="0"
                  data-min-w="254"
                  onClick={() => action({ _insurance: { credit: true } })}
                >
                  Застраховать
                </Button>
              )}
            </Box>
          </Row>
          {data &&
            data.vars.consumerCreditInsuranceExpense - data.vars.cash > 0 && (
              <>
                <Row>
                  <ErrorBox>
                    Не хватает наличных для страхования. Необходимо{" "}
                    {data.vars.consumerCreditInsuranceExpense.toLocaleString(
                      "Ru-ru"
                    )}{" "}
                    &#8381;
                  </ErrorBox>
                </Row>
                <Row>
                  <ButtonWrap data-mt="10">
                    <Button
                      data-type="red"
                      data-h="40"
                      data-mb="0"
                      data-w="100"
                      onClick={() =>
                        action({ _insurance: { creditFromCard: true } })
                      }
                    >
                      Застраховать с оплатой остатка кредитной картой
                    </Button>
                  </ButtonWrap>
                </Row>
              </>
            )}
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
          >
            <Button data-type="green" onClick={hide}>
              {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
            </Button>
          </Row>
        </Body>
      </Container580>
    </Modal>
  );
};

export default Credit;
