import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
  background: #f4f7ff;
  border-radius: 10px;
  height: 70px;
  border: 0;
  cursor: pointer;
  padding: 10px;
  display: flex;
  text-decoration: none;
  @media (max-width: 900px) {
    background: transparent;
  }
`;

export const ButtonName = styled.div`
  border: 1.5px solid #009769;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.408px;
  color: #009769;
  flex: 1 1 auto;
  height: 50px;
  padding: 0 34px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  margin-right: 5px;
`;

export const ButtonIcon = styled.div`
  border: 1.5px solid #009769;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;