import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { request } from "../components/Utils";

import MainWrap from "../view/MainWrap";
import Table from "../components/Table";

import ArrowIcon from "../img/ArrowIcon.svg";
import Place1Icon from "../img/Place1Icon.svg";
import Place2Icon from "../img/Place2Icon.svg";
import Place3Icon from "../img/Place3Icon.svg";
import DownloadIcon from "../img/DownloadIcon.svg";
import RatingFilterIcon from "../img/RatingFilterIcon.svg";
import CloseIcon from "../img/CloseIcon.svg";

import Modal, {
  Container286,
  Head,
  Body,
  Row,
  Button,
} from "../components/Modal";

const Col = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: #f4f7ff;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #293a47;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 0;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: rotate(-180deg);
  margin-right: 30px;
`;

const Place = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background: #009769;
  &[data-place="1"] {
    background: #ffd15b;
  }
  &[data-place="2"] {
    background: #d7d7d7;
  }
  &[data-place="3"] {
    background: #ffa370;
  }
  @media (max-width: 900px) {
    width: 40px;
    margin-right:-5px;
  }
`;

const PlaceText = styled.div`
  display: flex;
  @media (max-width: 900px) {
    width: 100%;
    margin-left: 4px;
  }
`;

export const RadioLabel = styled.div`
  width: 100%;
  font-size: 17px;
  line-height: 27px;
  color: #293a47;
  cursor: pointer;
  padding-left: 40px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: transparent;
    left: 9px;
    top: 9px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 23px;
    background: transparent;
    border: 2.5px solid rgba(0, 0, 0, 0.12);
    left: 0;
    top: 0;
  }
`;

export const RadioInput = styled.input`
  display: none;
  &:checked + ${RadioLabel} {
    font-weight: 700;
    &:before {
      background: #286fb7;
    }
  }
`;

export const RadioButton = styled.label`
  display: flex;
  flex: 1 1 auto;
  & + & {
    margin-top: 20px;
  }
`;
export const RadioWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: #f4f7ff;
  border-radius: 10px;
  flex: 1 1 auto;
`;

const Rating = ({ app }) => {
  const { tournamentId } = useParams();
  const [title, setTitle] = React.useState("");
  const [filterModal, setFilterModal] = React.useState(false);
  const [filter, setFilter] = React.useState("0");
  const [reloadTable, setReloadTable] = React.useState(+new Date());

  const removeGame = async (id) => {
    await request({
      url: "/api/game/remove/",
      params: {
        method: "POST",
        body: {
          id,
        },
      },
      app: app,
    });
    setReloadTable(+new Date());
  };

  return (
    <>
      <MainWrap app={app} panel="main">
        <div data-direction="row" data-w="100" data-mt="40">
          <Col data-w="100">
            <Row data-valign="center">
              <BackButton
                to={tournamentId !== undefined ? "/cp/tournaments" : "/cp"}
              >
                <img src={ArrowIcon} alt="Back icon" />
              </BackButton>
              {tournamentId === undefined
                ? "Общий рейтинг"
                : `Общий рейтинг "${title}"`}
            </Row>
            {app.current.user.role === "admin" && tournamentId !== undefined && (
              <Button
                data-type="green"
                onClick={() => {
                  window.location.href = `/api/rating/download?id=${tournamentId}&token=${app.current.token}`;
                }}
                data-max-w="210"
                data-mb="0"
              >
                <img
                  src={DownloadIcon}
                  alt="Download icon"
                  data-mr="10"
                  style={{ verticalAlign: "text-bottom" }}
                />
                Выгрузить результат
              </Button>
            )}
            <Button
              data-type="blueBg"
              data-form="square"
              data-h="40"
              data-w="40px"
              data-mb="0"
              data-mr="0"
              onClick={() => setFilterModal(true)}
            >
              <img
                src={RatingFilterIcon}
                alt="Rating filter"
                style={{ verticalAlign: "text-bottom" }}
              />
            </Button>
          </Col>
        </div>
        <Table
          source="/api/rating/my/"
          filter={{ tournamentId, filter }}
          app={app}
          rows={10}
          reload={reloadTable}
          cb={(data) => setTitle(data.title)}
          fields={[
            {
              key: "place",
              name: "Место",
              smallName: "Рейтинг",
              render: (row) => (
                <>
                  {[1].includes(row.place) && (
                    <Place data-place="1">
                      <img src={Place1Icon} alt="Place 1" />
                    </Place>
                  )}
                  {[2].includes(row.place) && (
                    <Place data-place="2">
                      <img src={Place2Icon} alt="Place 2" />
                    </Place>
                  )}
                  {[3].includes(row.place) && (
                    <Place data-place="3">
                      <img src={Place3Icon} alt="Place 3" />
                    </Place>
                  )}
                  {![1, 2, 3].includes(row.place) && <>{row.place}</>}
                </>
              ),
              renderMobile: (row) => (
                <>
                  <PlaceText>{row.place} место</PlaceText>
                  {[1].includes(row.place) && (
                    <Place data-place="1">
                      <img src={Place1Icon} alt="Place 1" />
                    </Place>
                  )}
                  {[2].includes(row.place) && (
                    <Place data-place="2">
                      <img src={Place2Icon} alt="Place 2" />
                    </Place>
                  )}
                  {[3].includes(row.place) && (
                    <Place data-place="3">
                      <img src={Place3Icon} alt="Place 3" />
                    </Place>
                  )}
                </>
              ),
            },
            {
              key: "started_at",
              name: "Участник",
              render: (row) => <>{`${row.name} ${row.surname}`}</>,
            },
            {
              key: "score",
              name: "Финансовый результат",
              render: (row) => (
                <>{row.score && row.score.toLocaleString("ru-RU")}</>
              ),
            },
            {
              key: "status",
              name: "",
              condition: () => app.current.user.role === "admin",
              style: { width: "40px", flex: "0 0 auto" },
              smallName: '',
              render: (row) => (
                <>
                  {app.current.user.role === "admin" && (
                    <>
                      <Button
                        data-type="redBg"
                        onClick={() => removeGame(row.id)}
                        data-m="0"
                        data-ml="0"
                        data-h="40"
                        data-w="40px"
                        data-px="0"
                      >
                        <img src={CloseIcon} alt="Close icon" />
                      </Button>
                    </>
                  )}
                </>
              ),
            },
          ]}
        />
      </MainWrap>
      <Modal show={filterModal} valign="center">
        <Container286>
          <Body>
            <Row>
              <Head data-mt="0">Период результатов</Head>
            </Row>
            <Row data-mt="40">
              <RadioWrap>
                <RadioButton>
                  <RadioInput
                    type="radio"
                    name="toggle1"
                    value="0"
                    checked={filter === "0"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <RadioLabel>Всё время</RadioLabel>
                </RadioButton>
                <RadioButton>
                  <RadioInput
                    type="radio"
                    name="toggle1"
                    value="1"
                    checked={filter === "1"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <RadioLabel>Год</RadioLabel>
                </RadioButton>
                <RadioButton>
                  <RadioInput
                    type="radio"
                    name="toggle1"
                    value="2"
                    checked={filter === "2"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <RadioLabel>Месяц</RadioLabel>
                </RadioButton>
                <RadioButton>
                  <RadioInput
                    type="radio"
                    name="toggle1"
                    value="3"
                    checked={filter === "3"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <RadioLabel>Неделя</RadioLabel>
                </RadioButton>
                <RadioButton>
                  <RadioInput
                    type="radio"
                    name="toggle1"
                    value="4"
                    checked={filter === "4"}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <RadioLabel>День</RadioLabel>
                </RadioButton>
              </RadioWrap>
            </Row>
            <Row data-mt="40">
              <Button
                data-type="green"
                data-w="100"
                data-mx="0"
                data-mb="0"
                onClick={() => {
                  setFilterModal(false);
                  setReloadTable(+new Date());
                }}
              >
                Сохранить
              </Button>
            </Row>
          </Body>
        </Container286>
      </Modal>
    </>
  );
};

Rating.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Rating;
