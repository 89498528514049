import React from "react";
//import PropTypes from "prop-types";
import styled from "styled-components";
import { request } from "../components/Utils";

import ArrowIcon from "../img/ArrowIcon.svg";

export const TableWrap = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const Col = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #293a47;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  & + & {
    margin-left: 10px;
  }
  @media (max-width: 900px) {
    & + & {
      margin-top: 5px;
    }
    justify-content: flex-start;
  }
`;

export const Head = styled.div`
  margin-bottom: 10px;
  & ${Col} {
    font-weight: 700;
  }
  &[data-origin="true"] {
    min-width: 1100px;
  }
`;

export const Body = styled.div`
  &[data-desktop="block"]{
    min-width: 1100px;
  }
`;

export const BodyWrap = styled.div`
  overflow-x: auto;
`;

export const Foot = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const Row = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: #f4f7ff;
  & + & {
    margin-top: 10px;
  }
  @media (max-width: 900px) {
    padding: 5px;
    &[data-mobile-wrap="true"] {
      flex-direction: column;
      & > div {
        width: 100% !important;
        margin-left:0;
        margin-top: 10px;
        text-align: center;
        justify-content: center;
      }
    }
  }
`;

export const PagesWrap = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: #f4f7ff;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Page = styled.button`
  border: 0;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #293a47;
  & + & {
    margin-left: 10px;
  }
  &[data-disabled="true"] {
    opacity: 0.5;
  }
  @media (max-width: 900px) {
    width: 33.33%;
  }
`;

export const LeftArrow = styled.img`
  transform: rotate(-180deg);
`;

export const RightArrow = styled.img``;

const Table = ({
  source,
  app,
  fields,
  rows,
  reload,
  footerActions,
  filter,
  cb,
}) => {
  //
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    const loadData = async () => {
      const resp = await request({
        url: source,
        params: {
          method: "GET",
          //redirect: "follow",
          body: {
            offset: (page - 1) * rows,
            limit: rows,
            filter: JSON.stringify(filter),
          },
        },
        app: app,
      });
      if (resp && resp.success) {
        setCount(resp.result.count);
        setData(resp.result.data);
        if (cb) {
          cb(resp.result);
        }
      }
    };
    loadData();
    //Получаем данные
    // eslint-disable-next-line
  }, [page, app, source, rows, reload]);

  return (
    <TableWrap>
      <BodyWrap>
        <Head data-origin data-desktop="block">
          <Row>
            {fields.map((col, i) => (
              <React.Fragment key={i}>
                {(col.condition == null || col.condition(col)) && (
                  <Col
                    key={i}
                    style={
                      col.style
                        ? col.style
                        : { width: `${100 / fields.length}%` }
                    }
                  >
                    {col.name}
                  </Col>
                )}
              </React.Fragment>
            ))}
          </Row>
        </Head>
        <Body data-desktop="block">
          {data.map((row) => (
            <Row key={row.id}>
              {fields.map((col, i) => (
                <React.Fragment key={i}>
                  {(col.condition == null || col.condition(col)) && (
                    <Col
                      key={i}
                      style={
                        col.style
                          ? col.style
                          : { width: `${100 / fields.length}%` }
                      }
                    >
                      {col.render
                        ? col.render(row, (page - 1) * rows, i)
                        : row[col.key]}
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          ))}
        </Body>
        <Body data-mobile="block">
          {data.map((row) => (
            <Row key={row.id} data-direction="column">
              {fields.map((col, i) => (
                <React.Fragment key={i}>
                  {(col.condition == null || col.condition(col)) && (
                    <Col
                      style={
                        col.style
                          ? col.style
                          : { width: `${100 / fields.length}%` }
                      }
                      data-w="100"
                      data-mx="0"
                      data-px={col.noPadding === true ? 0 : 5}
                      data-bold={col.bold === true}
                    >
                      {`${col.smallName == null ? col.name : col.smallName}${col.smallName !== '' ? ':' : ''}`} {col.render
                        ? col[col.renderMobile != null ? 'renderMobile' : 'render'](row, (page - 1) * rows, i)
                        : row[col.key]}
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          ))}
        </Body>
      </BodyWrap>
      <Foot>
        {footerActions != null && (
          <PagesWrap data-mr="10">{footerActions}</PagesWrap>
        )}
        <PagesWrap>
          <Page
            onClick={() => (page > 1 ? setPage(page - 1) : "")}
            data-disabled={page === 1 ? "true" : "false"}
          >
            <LeftArrow src={ArrowIcon} />
          </Page>
          <Page>{page}</Page>
          <Page
            onClick={() => (page < count / rows ? setPage(page + 1) : "")}
            data-disabled={page === Math.ceil(count / rows) ? "true" : "false"}
          >
            <RightArrow src={ArrowIcon} />
          </Page>
        </PagesWrap>
      </Foot>
    </TableWrap>
  );
};

export default Table;
