import React from "react";

import Modal, { Container1040, Head, Body, Row, Col, Button } from "../Modal";

import {
  RadioInput,
  RadioLabel,
  RadioWrap,
  RadioButton,
} from "../../view/Form";

function EconomyModal({ show, hide, data, toggleAction }) {
  const [clothesEconomy, setClothesEconomy] = React.useState(
    data && data.vars ? data.vars.clothesEconomy : "0"
  );
  const [entertainmentEconomy, setEntertainmentEconomy] = React.useState(
    data && data.vars ? data.vars.entertainmentEconomy : "0"
  );
  const [otherEconomy, setOtherEconomy] = React.useState(
    data && data.vars ? data.vars.otherEconomy : "0"
  );

  React.useEffect(() => {
    if (data && data.vars) {
      setClothesEconomy(data.vars.clothesEconomy);
      setEntertainmentEconomy(data.vars.entertainmentEconomy);
      setOtherEconomy(data.vars.otherEconomy);
    }
  }, [data]);

  React.useEffect(() => {
    if (data && data.vars) {
      let params = {};
      if (data.vars.clothesEconomy !== clothesEconomy) {
        params.clothesEconomy = clothesEconomy;
      }
      if (data.vars.entertainmentEconomy !== entertainmentEconomy) {
        params.entertainmentEconomy = entertainmentEconomy;
      }
      if (data.vars.otherEconomy !== otherEconomy) {
        params.otherEconomy = otherEconomy;
      }

      if (Object.keys(params).length > 0) toggleAction(params);
    }
    // eslint-disable-next-line
  }, [clothesEconomy, entertainmentEconomy, otherEconomy]);

  return (
    <Modal show={show} valign="center">
      <Container1040>
        <Head data-align="left">Экономия</Head>
        <Body>
          <Row data-align="left" data-mt="10" data-mb="20" data-desktop="block">
            Если вы включите экономию хотя бы на один из пунктов, то упустите
            возможность вытащить 2 дополнительные карточки “возможности”. Эти
            карточки открываются после 5 ходов без экономии подряд, но не более
            двух раз за всю игру.
          </Row>
          <Row data-align="center" data-mt="40" data-mobile-mt="0" data-mobile-direction="column" data-mx="-15" data-mb="-10">
            <Col data-w="33.33" data-mobile-w="100" data-px="15" data-mobile-mb="20">
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-px="30"
                data-py="10"
                data-text="18"
                data-mb="10"
                data-mobile-mb="5"
              >
                Экономить на одежде
              </Row>
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-py="20"
                data-px="30"
                data-text="17"
                data-mh="100"
              >
                Вы решаете каждый месяц экономить на покупке одежды.
                <br />
                Это сократит ваши текущие расходы на 4 000 р.
              </Row>
              <Row data-mt="20" data-mobile-mt="5">
                <RadioWrap>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle1"
                      value="0"
                      checked={clothesEconomy === "0"}
                      onChange={(e) => setClothesEconomy(e.target.value)}
                    />
                    <RadioLabel>Выключить</RadioLabel>
                  </RadioButton>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle1"
                      value="1"
                      checked={clothesEconomy === "1"}
                      onChange={(e) => setClothesEconomy(e.target.value)}
                    />
                    <RadioLabel>Включить</RadioLabel>
                  </RadioButton>
                </RadioWrap>
              </Row>
            </Col>
            <Col data-w="33.33" data-mobile-w="100" data-px="15" data-mobile-mb="20">
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-py="10"
                data-px="30"
                data-mb="10"
                data-mobile-mb="5"
                data-text="18"
              >
                Экономить на развлечениях
              </Row>
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-py="20"
                data-px="30"
                data-text="17"
                data-mh="100"
              >
                Вы решаете каждый месяц экономить на развлечениях.
                <br />
                Это сократит ваши текущие расходы на 4 000 р.
              </Row>
              <Row data-mt="20" data-mobile-mt="5">
                <RadioWrap>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle2"
                      value="0"
                      checked={entertainmentEconomy === "0"}
                      onChange={(e) => setEntertainmentEconomy(e.target.value)}
                    />
                    <RadioLabel>Выключить</RadioLabel>
                  </RadioButton>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle2"
                      value="1"
                      checked={entertainmentEconomy === "1"}
                      onChange={(e) => setEntertainmentEconomy(e.target.value)}
                    />
                    <RadioLabel>Включить</RadioLabel>
                  </RadioButton>
                </RadioWrap>
              </Row>
            </Col>
            <Col data-w="33.33" data-mobile-w="100" data-px="15" data-mobile-mb="20">
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-px="30"
                data-py="10"
                data-text="18"
                data-mb="10"
                data-mobile-mb="5"
              >
                Экономить на прочих затратах
              </Row>
              <Row
                data-align="left"
                data-round="10"
                data-bg="lightBlue"
                data-py="20"
                data-px="30"
                data-text="17"
                data-mh="100"
              >
                Вы решаете каждый месяц экономить на прочих затратах.
                <br />
                Это сократит ваши текущие расходы на 4 000 р.
              </Row>
              <Row data-mt="20" data-mobile-mt="5">
                <RadioWrap>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle3"
                      value="0"
                      checked={otherEconomy === "0"}
                      onChange={(e) => setOtherEconomy(e.target.value)}
                    />
                    <RadioLabel>Выключить</RadioLabel>
                  </RadioButton>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      name="toggle3"
                      value="1"
                      checked={otherEconomy === "1"}
                      onChange={(e) => setOtherEconomy(e.target.value)}
                    />
                    <RadioLabel>Включить</RadioLabel>
                  </RadioButton>
                </RadioWrap>
              </Row>
            </Col>
          </Row>
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-mobile-mt="15"
          >
            <Button data-type="green" onClick={hide}>
              К игре
            </Button>
          </Row>
        </Body>
      </Container1040>
    </Modal>
  );
}

export default EconomyModal;
