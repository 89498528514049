import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MainWrap from "../view/MainWrap";


const Wrap = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NotFound = () => (
  <MainWrap panel="404">
    <Wrap>
      Страница не найдена. <Link to="/">Вернуться на главную</Link>
    </Wrap>
  </MainWrap>
);

export default NotFound;
