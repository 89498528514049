import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Logo from "../img/Logo.svg";
import LogoBCC from "../img/LogoBCC.svg";
import LoginIcon from "../img/LoginIcon.svg";
import UserIcon from "../img/UserIcon.svg";
import LogoutIcon from "../img/LogoutIcon.svg";

import { Button, ButtonName, ButtonIcon } from "../view/Layout";

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: auto;
  padding: 43px 50px 35px;
  background: #e5edfb;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    padding: 30px 10px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  & > div > img[data-id="game"] {
    display: none;
    width: 70px;
    margin-left: 20px;
  }
  @media (max-width: 900px) {
    & > div > img[data-id="logo"] {
      width: 100px;
    }
    & > div > img[data-id="game"] {
      display: block;
    }
  }
`;

const Body = styled.div`
  margin: 0 auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;
`;

const BodyHead = styled.div`
  width: 100%;
  text-align: center;
  & > div {
    margin-top: 10px;
    max-width: 375px;
    margin: 0 auto;
  }
  @media (max-width: 900px) {
    & > img {
      display: none;
    }
    & > div {
      text-align: left;
      font-size: 17px;
      margin: 20px 0 0 0;
    }
  }
`;

export const Container = styled.form`
  margin-top: 37px;
  background: #f4f7ff;
  border-radius: 10px;
  padding: 50px 30px 40px;
  @media (max-width: 900px) {
    padding: 10px;
    margin-top: 25px;
  }
`;

const Footer = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #293a47;
  opacity: 0.5;
  margin-top: 30px;
`;

const MainWrap = ({ app, panel, children }) => (
  <Wrap>
    <Head>
      <div data-direction="row">
        <img src={LogoBCC} alt="BCC logo" data-id="logo" />
        <img src={Logo} alt="Logo" data-id="game" />
      </div>

      {(panel === "register" || panel === "login") && (
        <div data-desktop="block">
          {panel === "register" && (
            <Button to="/login">
              <ButtonName>Войти</ButtonName>
              <ButtonIcon>
                <img src={LoginIcon} alt="Login icon" />
              </ButtonIcon>
            </Button>
          )}
          {panel === "login" && (
            <Button to="/register">
              <ButtonName>Зарегистрироваться</ButtonName>
              <ButtonIcon>
                <img src={UserIcon} alt="User icon" />
              </ButtonIcon>
            </Button>
          )}
        </div>
      )}

      {(panel === "admin" || panel === "main") && (
        <div>
          <Button to="/logout">
            <ButtonName data-desktop="flex">Выйти</ButtonName>
            <ButtonIcon>
              <img src={LogoutIcon} alt="Logout icon" />
            </ButtonIcon>
          </Button>
        </div>
      )}
    </Head>
    <Body>
      <BodyHead>
        <img src={Logo} alt="Logo" />
        <div>
          Деловая игра по повышению финансовой грамотности в сфере страхования
        </div>
      </BodyHead>
      {children}
    </Body>
    <Footer>© 2018-{new Date().getFullYear()} Всероссийский союз страховщиков</Footer>
  </Wrap>
);

MainWrap.propTypes = {
  app: PropTypes.object,
  panel: PropTypes.string.isRequired,
};

export default MainWrap;
