import React from "react";
import PropTypes from "prop-types";

import MainWrap from "../view/MainWrap";
import { ButtonSmallLink } from "../view/Form";
import Table from "../components/Table";
import Menu from "../components/Menu";
import { request } from "../components/Utils";

import Modal, {
  Container620,
  Container554,
  Head,
  Body,
  Row,
  Box,
  Button,
} from "../components/Modal";

import { FieldLine, Field, Input, Label } from "../view/Form";

import RemoveIcon from "../img/RemoveIcon.svg";
import CloseIcon from "../img/CloseIcon.svg";
import DownloadIcon from "../img/DownloadIcon.svg";

export const EditModal = ({ app, show, hide, setReloadTable, userId }) => {
  const [data, setData] = React.useState({});
  const [password, setPassword] = React.useState("");
  const [removeModal, setRemoveModal] = React.useState(false);

  React.useEffect(() => {
    if (userId !== null) {
      const load = async () => {
        const resp = await request({
          url: "/api/user",
          params: {
            method: "GET",
            body: {
              id: userId,
            },
          },
          app: app,
        });
        if (resp && resp.success) {
          setData(resp.result.data);
        }
      };
      load();
    }
    // eslint-disable-next-line
  }, [userId]);

  const send = async () => {
    const resp = await request({
      url: "/api/user/",
      params: {
        method: "POST",
        body: { password, id: userId },
      },
      app: app,
    });
    if (resp.success) {
      hide();
      setReloadTable(+new Date());
    }
  };

  const remove = async () => {
    await request({
      url: "/api/user/",
      params: {
        method: "DELETE",
        body: { id: userId },
      },
      app: app,
    });
    hide();
    setReloadTable(+new Date());
  };

  return (
    <>
      <Modal show={show} valign="center">
        <Container620 data-bg="lightBlue">
          <Head data-align="left">
            Редактирование пользователя {data.email} ({data.name} {data.surname}
            )
          </Head>
          <Body>
            <Row data-mt="30">
              <FieldLine>
                <Field data-valid="true" data-w="50" data-mobile-w="100">
                  <Label>Новый пароль</Label>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Field>
                <Field data-w="50" data-desktop="block"></Field>
              </FieldLine>
            </Row>
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <Button
                data-type="red"
                onClick={() => setRemoveModal(true)}
                data-mobile-w="100"
                data-mobile-mx="0"
              >
                Удалить пользователя
              </Button>
            </Row>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <Button
                data-type="red"
                onClick={hide}
                data-mobile-w="100"
                data-mobile-mx="0"
              >
                Закрыть
              </Button>
              <Button
                data-type="green"
                onClick={send}
                data-mobile-w="100"
                data-mobile-mx="0"
              >
                Сохранить
              </Button>
            </Row>
          </Body>
        </Container620>
      </Modal>
      <Modal show={removeModal} valign="center">
        <Container554 data-bg="lightBlue">
          <Box data-valign="center" data-mb="20">
            <img src={RemoveIcon} alt="Remove icon" />
          </Box>
          <Head data-align="center">
            Вы уверены, что хотите удалить пользователя?
          </Head>
          <Body>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <Button
                data-type="red"
                onClick={() => {
                  setRemoveModal(false);
                  remove();
                  hide();
                }}
              >
                Да, удалить
              </Button>
              <Button data-type="green" onClick={() => setRemoveModal(false)}>
                Отменить
              </Button>
            </Row>
          </Body>
        </Container554>
      </Modal>
    </>
  );
};

const Main = ({ app }) => {
  const [reloadTable, setReloadTable] = React.useState(+new Date());
  const [userId, setUserId] = React.useState(null);
  const [modalEdit, setModalEdit] = React.useState(false);

  const [filterSearch, setFilterSearch] = React.useState("");

  const removeGame = async (id) => {
    await request({
      url: "/api/game/remove/",
      params: {
        method: "POST",
        body: {
          id,
        },
      },
      app: app,
    });
    setReloadTable(+new Date());
  };

  return (
    <>
      <MainWrap app={app} panel="main">
        <Menu app={app} />
        {app.current.user.role === "admin" ? (
        <Row data-mt="30">
          <FieldLine>
            <Field data-valid="true" data-w="50" data-mobile-w="100">
              <Input
                type="text"
                placeholder="Поиск"
                value={filterSearch}
                onChange={(e) => setFilterSearch(e.target.value)}
              />
            </Field>
            <Button
              data-type="green"
              onClick={() => setReloadTable(+new Date())}
              data-mobile-w="100"
              data-mobile-mx="0"
            >
              Найти
            </Button>
          </FieldLine>
        </Row>) : null}
        <Table
          source="/api/game/my/"
          app={app}
          rows={10}
          reload={reloadTable}
          footerActions={
            app.current.user.role === "admin" ? (
              <Button
                data-type="green"
                onClick={() => {
                  window.location.href = `/api/game/download?token=${app.current.token}`;
                }}
                data-max-w="210"
                data-mx="0"
                data-mb="0"
              >
                <img
                  src={DownloadIcon}
                  alt="Download icon"
                  data-mr="10"
                  style={{ verticalAlign: "text-bottom" }}
                />
                Выгрузить результат
              </Button>
            ) : null
          }
          filter={{ filterSearch }}
          fields={
            app.current.user.role === "admin"
              ? [
                  {
                    key: "name",
                    name: "Имя и фамилия",
                    render: (row) => <>{`${row.name} ${row.surname}`}</>,
                  },
                  {
                    key: "started_at",
                    name: "Создан",
                    render: (row) => (
                      <>
                        {new Date(row.started_at).toLocaleDateString("ru-ru", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        {new Date(row.started_at).toLocaleTimeString("ru-ru")}
                      </>
                    ),
                  },
                  {
                    key: "score",
                    name: "Финансовый результат",
                    render: (row) => (
                      <>
                        {row.score !== undefined &&
                        row.score !== null &&
                        row.score > 0
                          ? row.score.toLocaleString("ru-RU")
                          : "—"}
                      </>
                    ),
                  },
                  {
                    key: "actions",
                    name: "Действия",
                    render: (row) => (
                      <>
                        {
                          <Button
                            data-type="green"
                            onClick={() => {
                              setUserId(row.user_id);
                              setModalEdit(true);
                            }}
                            data-m="0"
                            data-bg="white"
                            data-max-w="none"
                            data-w="100"
                          >
                            Редактировать
                          </Button>
                        }
                      </>
                    ),
                  },
                ]
              : [
                  {
                    key: "score",
                    name: "Рейтинг",
                    bold: true,
                    render: (row) => (
                      <>
                        {row.score !== undefined &&
                          row.score !== null &&
                          row.score.toLocaleString("ru-RU")}
                      </>
                    ),
                  },
                  {
                    key: "started_at",
                    name: "Создан",
                    render: (row) => (
                      <>
                        {new Date(row.started_at).toLocaleDateString("ru-ru", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                        {new Date(row.started_at).toLocaleTimeString("ru-ru")}
                      </>
                    ),
                  },
                  {
                    key: "status",
                    name: "Статус",
                    smallName: "",
                    noPadding: true,
                    render: (row) => (
                      <>
                        {row.status === 0 && (
                          <ButtonSmallLink to={`/cp/game/${row.id}`}>
                            Играть
                          </ButtonSmallLink>
                        )}
                        {row.status === 1 && (
                          <ButtonSmallLink to={`/cp/game/${row.id}`}>
                            Посмотреть
                          </ButtonSmallLink>
                        )}
                        <Button
                          data-type="redBg"
                          onClick={() => removeGame(row.id)}
                          data-m="0"
                          data-ml="10"
                          data-h="40"
                          data-w="40px"
                          data-px="0"
                        >
                          <img src={CloseIcon} alt="Close icon" />
                        </Button>
                      </>
                    ),
                  },
                ]
          }
        />
      </MainWrap>
      <EditModal
        app={app}
        show={modalEdit}
        hide={() => {
          setModalEdit(false);
          setUserId(null);
        }}
        setReloadTable={setReloadTable}
        userId={userId}
      />
    </>
  );
};

Main.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Main;
