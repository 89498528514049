import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

import { request } from "../components/Utils";

import MainWrap from "../view/MainWrap";

import Table from "../components/Table";

import ArrowIcon from "../img/ArrowIcon.svg";
import Place1Icon from "../img/Place1Icon.svg";
import Place2Icon from "../img/Place2Icon.svg";
import Place3Icon from "../img/Place3Icon.svg";
import CloseIcon from "../img/CloseIcon.svg";
import DownloadIcon from "../img/DownloadIcon.svg";

import { Row, Button } from "../components/Modal";

const Col = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: #f4f7ff;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #293a47;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 0;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: rotate(-180deg);
  margin-right: 30px;
`;

const Place = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background: #009769;
  &[data-place="1"] {
    background: #ffd15b;
  }
  &[data-place="2"] {
    background: #d7d7d7;
  }
  &[data-place="3"] {
    background: #ffa370;
  }
`;

const Rating = ({ app }) => {
  const { tournamentId } = useParams();
  const [title, setTitle] = React.useState("");
  const [reloadTable, setReloadTable] = React.useState(+new Date());

  const removeParticipant = async (id) => {
    await request({
      url: "/api/tournament/removeparticipant/",
      params: {
        method: "POST",
        body: {
          participantId: id,
          tournamentId,
        },
      },
      app: app,
    });
    setReloadTable(+new Date());
  };

  return (
    <MainWrap app={app} panel="main">
      <div data-direction="row" data-w="100" data-mt="40">
        <Col data-w="100">
          <Row data-valign="center">
            <BackButton
              to={tournamentId !== undefined ? "/cp/tournaments" : "/cp"}
            >
              <img src={ArrowIcon} alt="Back icon" />
            </BackButton>
            Участники турнира "{title}"
          </Row>
          {app.current.user.role === "admin" && tournamentId !== undefined && (
            <Button
              data-type="green"
              onClick={() => {
                window.location.href = `/api/tournament/participantsdownload?id=${tournamentId}&token=${app.current.token}`;
              }}
              data-max-w="210"
              data-mb="0"
            >
              <img
                src={DownloadIcon}
                alt="Download icon"
                data-mr="10"
                style={{ verticalAlign: "text-bottom" }}
              />
              Выгрузить список
            </Button>
          )}
        </Col>
      </div>
      <Table
        source="/api/tournament/participants/"
        filter={{ tournamentId: tournamentId }}
        app={app}
        rows={10}
        reload={reloadTable}
        cb={(data) => setTitle(data.title)}
        fields={[
          {
            key: "place",
            name: "Место",
            render: (row) => (
              <>
                {[1].includes(row.place) && (
                  <Place data-place="1">
                    <img src={Place1Icon} alt="Place 1" />
                  </Place>
                )}
                {[2].includes(row.place) && (
                  <Place data-place="2">
                    <img src={Place2Icon} alt="Place 2" />
                  </Place>
                )}
                {[3].includes(row.place) && (
                  <Place data-place="3">
                    <img src={Place3Icon} alt="Place 3" />
                  </Place>
                )}
                {![1, 2, 3].includes(row.place) && (
                  <>{row.place != null ? row.place : "—"}</>
                )}
              </>
            ),
          },
          {
            key: "started_at",
            name: "Участник",
            render: (row) => <>{`${row.name} ${row.surname}`}</>,
          },
          {
            key: "score",
            name: "Финансовый результат",
            render: (row) => (
              <>{row.score != null ? row.score.toLocaleString("ru-RU") : "—"}</>
            ),
          },
          {
            key: "status",
            name: "",
            condition: () => app.current.user.role === "admin",
            style: { width: "40px", flex: "0 0 auto" },
            render: (row) => (
              <>
                {app.current.user.role === "admin" && (
                  <>
                    <Button
                      data-type="redBg"
                      onClick={() => removeParticipant(row.id)}
                      data-m="0"
                      data-ml="0"
                      data-h="40"
                      data-w="40px"
                      data-px="0"
                    >
                      <img src={CloseIcon} alt="Close icon" />
                    </Button>
                  </>
                )}
              </>
            ),
          },
        ]}
      />
    </MainWrap>
  );
};

Rating.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Rating;
