import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { request } from "../components/Utils";

import MainWrap, { Container } from "../view/MainWrap";

import { ErrorBox } from "../components/Modal";

import { Head, Field, Input, Label, Button, LinkStyled } from "../view/Form";

const Remind = ({ app }) => {
  const [sent, setSent] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [pass1, setPass1] = React.useState("");
  const [pass2, setPass2] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const code = new URLSearchParams(location.search).get("code");
  const remail = new URLSearchParams(location.search).get("email");

  const [error1, setError1] = React.useState("");
  const [error2, setError2] = React.useState("");

  const getRemindCode = async () => {
    const resp = await request({
      url: "/api/user/remind/",
      params: {
        method: "POST",
        body: {
          email,
        },
      },
      app: app,
    });
    if (resp.success) {
      setSent(true);
    } else {
      setError1(resp.errors.email);
    }
  };

  const changePassword = async () => {
    const resp = await request({
      url: "/api/user/reset/",
      params: {
        method: "POST",
        body: {
          email: remail,
          code: code,
          pass1,
          pass2,
        },
      },
      app: app,
    });
    if (resp.success) {
      navigate("/cp");
    } else {
      setError2(resp.errors.pass2);
    }
  };

  return (
    <MainWrap app={app} panel="login">
      <Container style={{width: '100%', maxWidth: '475px'}}>
        <Head>Восстановление пароля</Head>
        {code === null && (
          <>
            {!sent && (
              <>
                {error1 && (
                  <ErrorBox data-mb="10">
                    {error1}
                  </ErrorBox>
                )}
                <Field>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Field>
                <Button type="button" onClick={getRemindCode}>
                  Восстановить
                </Button>
              </>
            )}
            {sent && <>Ссылка для смены пароля отправлена на Ваш адрес email</>}
          </>
        )}
        {code !== null && (
          <>
          {error2 && (
                  <ErrorBox data-mb="10">
                    {error2}
                  </ErrorBox>
                )}
            <Field>
              <Label>Новый пароль</Label>
              <Input
                type="password"
                value={pass1}
                onChange={(e) => setPass1(e.target.value)}
              />
            </Field>
            <Field>
              <Label>Повторите новый пароль</Label>
              <Input
                type="password"
                value={pass2}
                onChange={(e) => setPass2(e.target.value)}
              />
            </Field>
            <Button type="button" onClick={changePassword}>
              Сменить пароль
            </Button>
          </>
        )}
      </Container>
      <LinkStyled onClick={() => navigate("/login")}>Войти</LinkStyled>
    </MainWrap>
  );
};

Remind.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Remind;
