import React from "react";

import Modal, {
  Container730,
  Head,
  Body,
  Text,
  Row,
  Box,
  Button,
} from "../Modal";

import CustomButton from "../CustomButton";

export const getList = function (data) {
  let list = [];

  for (const stuffId of Object.keys(data.vars.stuff)) {
    const stuff = data.vars.stuff[stuffId];
    list.push(
      Object.assign(
        { id: `stuff-${stuffId}`, sellValue: stuff.value * 0.8 },
        stuff
      )
    );
  }

  for (const carId of Object.keys(data.vars.car)) {
    const car = data.vars.car[carId];
    if (car.credit === 0) {
      list.push(
        Object.assign({ id: `car-${carId}`, sellValue: car.value * 0.8 }, car)
      );
    }
  }

  for (const roomId of Object.keys(data.vars.room)) {
    const room = data.vars.room[roomId];
    if (room.credit === 0) {
      list.push(
        Object.assign(
          { id: `room-${roomId}`, sellValue: room.value * 0.8 },
          room
        )
      );
    }
  }

  for (const flatId of Object.keys(data.vars.flat)) {
    const flat = data.vars.flat[flatId];
    if (flat.credit === 0) {
      list.push(
        Object.assign(
          { id: `flat-${flatId}`, sellValue: flat.value * 0.8 },
          flat
        )
      );
    }
  }

  for (const partnershipId of Object.keys(data.vars.partnership)) {
    const partnership = data.vars.partnership[partnershipId];
    list.push(
      Object.assign(
        {
          id: `partnership-${partnershipId}`,
          sellValue: partnership.value * 0.8,
        },
        partnership
      )
    );
  }

  for (const onlineBusinessId of Object.keys(data.vars.onlineBusiness)) {
    const onlineBusiness = data.vars.onlineBusiness[onlineBusinessId];
    list.push(
      Object.assign(
        {
          id: `onlineBusiness-${onlineBusinessId}`,
          sellValue: onlineBusiness.value * 0.8,
        },
        onlineBusiness
      )
    );
  }

  for (const offlineBusinessId of Object.keys(data.vars.offlineBusiness)) {
    const offlineBusiness = data.vars.offlineBusiness[offlineBusinessId];
    list.push(
      Object.assign(
        {
          id: `offlineBusiness-${offlineBusinessId}`,
          sellValue: offlineBusiness.value * 0.8,
        },
        offlineBusiness
      )
    );
  }

  if (data.vars.deposit > 0) {
    list.push(
      Object.assign({
        id: "deposit",
        name: "Депозит",
        value: data.vars.deposit,
        sellValue: data.vars.deposit,
      })
    );
  }

  if (data.vars.buyLifeInsuranceAmount > 0) {
    list.push(
      Object.assign({
        id: "isg",
        name: "ИСЖ",
        value: data.vars.buyLifeInsuranceAmount,
        sellValue: data.vars.buyLifeInsuranceAmount * 0.8,
      })
    );
  }

  return list;
};

const TechDefoltModal = ({ show, hide, toggleAction, data }) => {
  const [allStuff, setAllStuff] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [summ, setSumm] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      let list = getList(data);
      setAllStuff(list);
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    let a = 0;
    selected.forEach((s) => {
      allStuff.forEach((item) => {
        if (item.id === s) {
          a += item.sellValue;
        }
      });
    });
    setSumm(a);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Modal show={show} valign="center">
      <Container730>
        <Head data-align="left">Технический дефолт</Head>
        <Body>
          <Row data-align="left">
            Вы оказались в затруднительной ситуации. Банки перестали выдавать
            вам кредиты, однако вы располагаете рядом дорогостоящих активов.
            Выберите, какие из них вы хотите продать за 80% от их рыночной
            стоимости. Депозит снимается в 100% размере.
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">
                  <strong>Активы</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mx="5"
              >
                <Text data-text="17">
                  <strong>Стоимость актива</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mx="5"
              >
                <Text data-text="17">
                  <strong>Стоимость продажи</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="40px"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">
                  <strong></strong>
                </Text>
              </Box>
            </Box>
          </Row>

          {allStuff.map((item, i) => (
            <Row data-align="left" data-mt="10" data-mx="-10" key={i}>
              <Box
                data-round="10"
                data-bg={
                  selected.includes(item.id) ? "lightestGreen" : "lightBlue"
                }
                data-p="10"
                data-w="100"
                data-direction="row"
              >
                <Box
                  data-round="10"
                  data-bg={
                    selected.includes(item.id) ? "lightGreen" : "lightGray"
                  }
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-mr="5"
                >
                  <Text data-text="17">{item.name}</Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg={
                    selected.includes(item.id) ? "lightGreen" : "lightGray"
                  }
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-mx="5"
                >
                  <Text data-text="17">
                    {item.value.toLocaleString("ru-RU")} &#8381;
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg={
                    selected.includes(item.id) ? "lightGreen" : "lightGray"
                  }
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-mx="5"
                >
                  <Text data-text="17">
                    {item.sellValue.toLocaleString("ru-RU")} &#8381;
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="40px"
                  data-halign="center"
                  data-ml="0"
                  data-direction="row"
                  data-px="0"
                >
                  <CustomButton
                    id={item.id}
                    active={true}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Box>
              </Box>
            </Row>
          ))}

          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="66.66"
                data-halign="center"
              >
                <Text data-text="17">Сумма продажи</Text>
                <Text data-text="17">
                  <strong>{summ.toLocaleString("ru-RU")} &#8381;</strong>
                </Text>
              </Box>
              <Button
                data-type="green"
                data-h="60"
                data-ml="10"
                data-mb="0"
                data-mr="0"
                data-w="33.33"
                onClick={() => {
                  if (selected.length > 0) {
                    toggleAction({
                      _defolt: JSON.parse(JSON.stringify(selected)),
                    });
                    hide();
                    setSelected([]);
                  }
                }}
              >
                Продать
              </Button>
            </Box>
          </Row>
        </Body>
      </Container730>
    </Modal>
  );
};

export default TechDefoltModal;
