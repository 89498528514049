import React from "react";

import Modal, {
  Container730,
  Container554,
  Header,
  HeaderIcon,
  Head,
  Body,
  Text,
  Row,
  Button,
  ButtonLink,
  Loader,
  Col,
  AmorphButton,
  Field,
  ButtonsField,
  Die,
  List,
  ListHead,
  ListHeadName,
  ListHeadControl,
  ListItem,
  ListItemName,
  ListItemControl,
  TextBlock,
  TextBlockIcon,
  HobbyName,
  ValueBlock,
} from "../Modal";

import { Page, LeftArrow, RightArrow } from "../Table";

import ErrorIcon from "../../img/ErrorIcon.svg";
import LoaderIcon from "../../img/LoaderIcon.svg";
import CalendarIconBig from "../../img/CalendarIconBig.svg";
import ProfDevIcon from "../../img/ProfDevIcon.svg";
import NetMarketingIcon from "../../img/NetMarketingIcon.svg";
import NewProfessionIcon from "../../img/NewProfessionIcon.svg";
import MasterClassIcon from "../../img/MasterClassIcon.svg";
import DentistIcon from "../../img/DentistIcon.svg";
import RestaurantIcon from "../../img/RestaurantIcon.svg";
import VacationIcon from "../../img/VacationIcon.svg";
import HobbyIcon from "../../img/HobbyIcon.svg";
import LifeInsuranceIcon from "../../img/LifeInsuranceIcon.svg";
import SideJobIcon from "../../img/SideJobIcon.svg";
import MedicalExaminationIcon from "../../img/MedicalExaminationIcon.svg";
import GymIcon from "../../img/GymIcon.svg";
import PhoneIcon from "../../img/PhoneIcon.svg";
import TvIcon from "../../img/TvIcon.svg";
import FloodIcon from "../../img/FloodIcon.svg";
import FireIcon from "../../img/FireIcon.svg";
import TaxRefundIcon from "../../img/TaxRefundIcon.svg";
import DismissalIcon from "../../img/DismissalIcon.svg";
import CopyrightIcon from "../../img/CopyrightIcon.svg";
import RoomIcon from "../../img/RoomIcon.svg";
import BookIcon from "../../img/BookIcon.svg";
import CarAccidentIcon from "../../img/CarAccidentIcon.svg";
import HurricaneIcon from "../../img/HurricaneIcon.svg";
import HailIcon from "../../img/HailIcon.svg";
import SuccessIcon from "../../img/SuccessIcon.svg";
import SportcarIcon from "../../img/SportcarIcon.svg";
import FlatIcon from "../../img/FlatIcon.svg";
import OfflineBusinessIcon from "../../img/OfflineBusinessIcon.svg";
import OnlineBusinessIcon from "../../img/OnlineBusinessIcon.svg";
import PartnershipIcon from "../../img/PartnershipIcon.svg";
import BuyLifeInsuranceIcon from "../../img/BuyLifeInsuranceIcon.svg";
import UseAutoIcon from "../../img/UseAutoIcon.svg";
import CarCrashIcon from "../../img/CarCrashIcon.svg";
import HijackingIcon from "../../img/HijackingIcon.svg";
import SellSmallBusinessIcon from "../../img/SellSmallBusinessIcon.svg";
import BlackMinusIcon from "../../img/BlackMinusIcon.svg";
import WhitePlusIcon from "../../img/WhitePlusIcon.svg";
import WhiteCheckIcon from "../../img/WhiteCheckIcon.svg";
import SellRealtyIcon from "../../img/SellRealtyIcon.svg";
import BusinessGrowthIcon from "../../img/BusinessGrowthIcon.svg";
import SellStuffIcon from "../../img/SellStuffIcon.svg";
import CompetitionIcon from "../../img/CompetitionIcon.svg";
import RansomIcon from "../../img/RansomIcon.svg";
import ReorganisationIcon from "../../img/ReorganisationIcon.svg";

import ArrowIcon from "../../img/ArrowIcon.svg";

function CardModal({
  show,
  hide,
  modal,
  data,
  action,
  setModalRegister,
  setModalCredit,
}) {
  const [loading, setLoading] = React.useState(null);
  const [bid, setBid] = React.useState("");
  const [dieValue, setDieValue] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [hobby, setHobby] = React.useState(1);
  const [modalWarning, setModalWarning] = React.useState(false);
  const [warningAction, setWarningAction] = React.useState(null);

  React.useEffect(() => {
    setLoading(null);
  }, [modal]);

  return (
    <>
      <Modal show={show} valign="flex-start">
        <>
          {modal.type === "error" && (
            <Container554>
              <Row data-align="center" data-mb="20">
                <img src={ErrorIcon} alt="Error icon" />
              </Row>
              <Row data-align="center">
                <Head data-align="center">{modal.text}</Head>
              </Row>
              <Row
                data-align="center"
                data-mt="40"
                data-ml="-5"
                data-mr="-5"
                data-mb="-10"
              >
                {modal.buttons.map((button, i) => (
                  <React.Fragment key={i}>
                    {button.visible !== false && (
                      <Button
                        key={i}
                        data-type={button.type}
                        onClick={() => {
                          if (button.link === "credit") {
                            setModalCredit(true);
                          } else {
                            setLoading(i);
                            action(i);
                          }
                        }}
                        data-loading={loading === i}
                        data-disabled={loading !== null}
                      >
                        <Loader
                          src={LoaderIcon}
                          alt="Loader icon"
                          data-loader
                        />
                        {button.name}
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Container554>
          )}
          {modal.type === "success" && (
            <Container554>
              <Row data-align="center" data-mb="20">
                <img src={SuccessIcon} alt="Success icon" />
              </Row>
              <Row data-align="center">
                <Head data-align="center">{modal.text}</Head>
              </Row>
              <Row
                data-align="center"
                data-mt="40"
                data-ml="-5"
                data-mr="-5"
                data-mb="-10"
              >
                {modal.buttons.map((button, i) => (
                  <React.Fragment key={i}>
                    {button.visible !== false && (
                      <Button
                        key={i}
                        data-type={button.type}
                        onClick={() => {
                          if (button.link === "credit") {
                            setModalCredit(true);
                          } else {
                            setLoading(i);
                            action(i);
                          }
                        }}
                        data-loading={loading === i}
                        data-disabled={loading !== null}
                      >
                        <Loader
                          src={LoaderIcon}
                          alt="Loader icon"
                          data-loader
                        />
                        {button.name}
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Container554>
          )}
          {modal.type === "notification" && (
            <Container554>
              <Row data-align="center" data-mb="20">
                {modal.icon === "calendar" && (
                  <img src={CalendarIconBig} alt="Notification icon" />
                )}
              </Row>
              <Row data-align="center">
                <Head data-align="center">{modal.text}</Head>
              </Row>
              <Row
                data-align="center"
                data-mt="40"
                data-ml="-5"
                data-mr="-5"
                data-mb="-10"
              >
                {modal.buttons.map((button, i) => (
                  <React.Fragment key={i}>
                    {button.visible !== false && (
                      <Button
                        key={i}
                        data-type={button.type}
                        onClick={() => {
                          if (button.link === "credit") {
                            setModalCredit(true);
                          } else {
                            setLoading(i);
                            action(i);
                          }
                        }}
                        data-loading={loading === i}
                        data-disabled={loading !== null}
                      >
                        <Loader
                          src={LoaderIcon}
                          alt="Loader icon"
                          data-loader
                        />
                        {button.name}
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Container554>
          )}
          {modal.type === "end" && (
            <>
              {data.isTournament && (
                <Container730>
                  <Row data-align="center">
                    <Head data-align="center">Завершение турнира</Head>
                  </Row>
                  <Row data-align="center" data-mt="10" data-direction="column">
                    <Text data-align="center">
                      Поздравляем с завершением игры!
                    </Text>
                    <ValueBlock data-mt="20">
                      Ваш финальный результат:{" "}
                      {data.vars.finResult.toLocaleString("ru-RU")}
                    </ValueBlock>
                  </Row>
                  <Row data-align="center" data-mt="40" data-wrap="wrap">
                    <Button
                      data-type="green"
                      data-mr="10"
                      data-ml="0"
                      data-mobile-w="100"
                      data-mobile-mx="0"
                      onClick={() => setModalRegister(true)}
                    >
                      Игровая ведомость
                    </Button>
                    <Button
                      data-type="green"
                      onClick={hide}
                      data-mobile-w="100"
                      data-mobile-mx="0"
                    >
                      Посмотреть
                    </Button>
                    <ButtonLink
                      data-ml="10"
                      data-type="green"
                      to="/cp/tournaments"
                      data-mobile-w="100"
                      data-mobile-mx="0"
                      data-mobile-mt="10"
                      data-mobile-max-w="none"
                    >
                      Закрыть
                    </ButtonLink>
                  </Row>
                </Container730>
              )}
              {!data.isTournament && (
                <Container730>
                  <Row data-align="center">
                    <Head data-align="center">Завершение игры</Head>
                  </Row>
                  <Row data-align="center" data-mt="10" data-direction="column">
                    <Text data-align="center">
                      Поздравляем Вас с успешным завершением игры «Предел
                      риска»!
                    </Text>
                    <ValueBlock data-mt="20">
                      Ваш результат:{" "}
                      {data.vars.finResult.toLocaleString("ru-RU")}
                    </ValueBlock>
                  </Row>
                  <Row data-align="center" data-mt="40" data-wrap="wrap">
                    <Button
                      data-type="green"
                      data-mr="10"
                      data-mobile-w="100"
                      data-mobile-mx="0"
                      onClick={() => setModalRegister(true)}
                    >
                      Игровая ведомость
                    </Button>
                    <ButtonLink
                      data-type="green"
                      to="/cp/rating"
                      data-mobile-w="100"
                      data-mobile-mx="0"
                      data-mobile-max-w="none"
                    >
                      Общий рейтинг
                    </ButtonLink>
                    <ButtonLink
                      to="/cp"
                      data-type="green"
                      data-ml="10"
                      data-mobile-w="100"
                      data-mobile-mx="0"
                      data-mobile-mt="10"
                      data-mobile-max-w="none"
                    >
                      Выйти в меню
                    </ButtonLink>
                  </Row>
                </Container730>
              )}
            </>
          )}
          {modal.type === "bancrupt" && (
            <Container554>
              <Row data-align="center">
                <Head data-align="center">Банкротство</Head>
              </Row>
              <Row data-align="center" data-mt="10">
                <Text data-align="center">
                  К сожалению, вы не можете расплатиться по собственным
                  обязательствам. Также вы не обладаете активами для продажи,
                  кроме стартовых.
                </Text>
              </Row>
              <Row data-align="center" data-mt="40">
                <ButtonLink to="/cp" data-type="red" data-ml="10">
                  Завершить игру
                </ButtonLink>
              </Row>
            </Container554>
          )}
          {modal.type === "info" && (
            <Container554>
              <Body>
                <Row data-align="center">
                  <Text data-align="center">{modal.text}</Text>
                </Row>
                <Row
                  data-align="center"
                  data-mt="40"
                  data-ml="-5"
                  data-mr="-5"
                  data-mb="-10"
                  data-wrap="wrap"
                >
                  {modal.buttons.map((button, i) => (
                    <React.Fragment key={i}>
                      {button.visible !== false && (
                        <Button
                          key={i}
                          data-type={button.type}
                          onClick={() => {
                            if (button.link === "credit") {
                              setModalCredit(true);
                            } else {
                              setLoading(i);
                              action(i);
                            }
                          }}
                          data-loading={loading === i}
                          data-disabled={loading !== null}
                        >
                          <Loader
                            src={LoaderIcon}
                            alt="Loader icon"
                            data-loader
                          />
                          {button.name}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </Row>
              </Body>
            </Container554>
          )}
          {modal.type === "infoLarge" && (
            <Container730>
              <Body>
                <Row data-align="center">
                  <Text data-align="center">{modal.text}</Text>
                </Row>
                <Row
                  data-align="center"
                  data-mt="40"
                  data-ml="-5"
                  data-mr="-5"
                  data-mb="-10"
                >
                  {modal.buttons.map((button, i) => (
                    <React.Fragment key={i}>
                      {button.visible !== false && (
                        <Button
                          key={i}
                          data-type={button.type}
                          onClick={() => {
                            if (button.link === "credit") {
                              setModalCredit(true);
                            } else {
                              setLoading(i);
                              action(i);
                            }
                          }}
                          data-loading={loading === i}
                          data-disabled={loading !== null}
                        >
                          <Loader
                            src={LoaderIcon}
                            alt="Loader icon"
                            data-loader
                          />
                          {button.name}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </Row>
              </Body>
            </Container730>
          )}
          {modal.type === "lottery" && (
            <Container730>
              <Header data-panel={modal.panel}>{modal.head}</Header>
              <Body>
                <Row data-align="left">
                  <Text>{modal.text}</Text>
                </Row>
                <Row data-mt="20" data-wrap="wrap">
                  <Col direction="row">
                    <Row data-align="left">
                      <AmorphButton onClick={() => setDieValue(1)}>
                        <Die data-active={dieValue === 1}>
                          <i data-i="7" />
                        </Die>
                      </AmorphButton>
                      <AmorphButton onClick={() => setDieValue(2)}>
                        <Die data-active={dieValue === 2}>
                          <i data-i="1" />
                          <i data-i="6" />
                        </Die>
                      </AmorphButton>
                      <AmorphButton onClick={() => setDieValue(3)}>
                        <Die data-active={dieValue === 3}>
                          <i data-i="3" />
                          <i data-i="4" />
                          <i data-i="7" />
                        </Die>
                      </AmorphButton>
                      <AmorphButton onClick={() => setDieValue(4)}>
                        <Die data-active={dieValue === 4}>
                          <i data-i="1" />
                          <i data-i="3" />
                          <i data-i="4" />
                          <i data-i="6" />
                        </Die>
                      </AmorphButton>
                      <AmorphButton onClick={() => setDieValue(5)}>
                        <Die data-active={dieValue === 5}>
                          <i data-i="1" />
                          <i data-i="3" />
                          <i data-i="4" />
                          <i data-i="6" />
                          <i data-i="7" />
                        </Die>
                      </AmorphButton>
                      <AmorphButton onClick={() => setDieValue(6)}>
                        <Die data-active={dieValue === 6}>
                          <i data-i="1" />
                          <i data-i="2" />
                          <i data-i="3" />
                          <i data-i="4" />
                          <i data-i="5" />
                          <i data-i="6" />
                        </Die>
                      </AmorphButton>
                    </Row>
                  </Col>
                  <Col data-mobile-w="100" data-mobile-mt="20">
                    <Field
                      type="number"
                      placeholder="Ваша ставка, &#8381;"
                      value={bid}
                      onChange={(e) => setBid(e.target.value)}
                      data-mobile-ml="0"
                    />
                  </Col>
                </Row>
                <Row
                  data-align="left"
                  data-mt="40"
                  data-ml="-5"
                  data-mr="-5"
                  data-mb="-10"
                  data-wrap="wrap"
                >
                  {modal.buttons.map((button, i) => (
                    <React.Fragment key={i}>
                      {button.visible !== false && (
                        <Button
                          data-type={button.type}
                          onClick={() => {
                            if (button.link === "credit") {
                              setModalCredit(true);
                            } else {
                              setLoading(i);
                              action(i, JSON.stringify({ bid, dieValue }));
                            }
                          }}
                          data-loading={loading === i}
                          data-disabled={loading !== null}
                        >
                          <Loader
                            src={LoaderIcon}
                            alt="Loader icon"
                            data-loader
                          />
                          {button.name}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </Row>
              </Body>
            </Container730>
          )}
          {modal.type === undefined && (
            <Container730>
              <Row data-mt="-20">
                {modal.icon && (
                  <HeaderIcon data-panel={modal.panel}>
                    {modal.icon === "ProfDevIcon" && (
                      <img src={ProfDevIcon} alt="Header icon" />
                    )}
                    {modal.icon === "netMarketing" && (
                      <img src={NetMarketingIcon} alt="Header icon" />
                    )}
                    {modal.icon === "newProfession" && (
                      <img src={NewProfessionIcon} alt="Header icon" />
                    )}
                    {modal.icon === "masterClass" && (
                      <img src={MasterClassIcon} alt="Header icon" />
                    )}
                    {modal.icon === "dentist" && (
                      <img src={DentistIcon} alt="Header icon" />
                    )}
                    {modal.icon === "restaurant" && (
                      <img src={RestaurantIcon} alt="Header icon" />
                    )}
                    {modal.icon === "vacation" && (
                      <img src={VacationIcon} alt="Header icon" />
                    )}
                    {modal.icon === "hobby" && (
                      <img src={HobbyIcon} alt="Header icon" />
                    )}
                    {modal.icon === "lifeInsurance" && (
                      <img src={LifeInsuranceIcon} alt="Header icon" />
                    )}
                    {modal.icon === "sideJob" && (
                      <img src={SideJobIcon} alt="Header icon" />
                    )}
                    {modal.icon === "medicalExamination" && (
                      <img src={MedicalExaminationIcon} alt="Header icon" />
                    )}
                    {modal.icon === "gym" && (
                      <img src={GymIcon} alt="Header icon" />
                    )}
                    {modal.icon === "phone" && (
                      <img src={PhoneIcon} alt="Header icon" />
                    )}
                    {modal.icon === "tv" && (
                      <img src={TvIcon} alt="Header icon" />
                    )}
                    {modal.icon === "flood" && (
                      <img src={FloodIcon} alt="Header icon" />
                    )}
                    {modal.icon === "fire" && (
                      <img src={FireIcon} alt="Header icon" />
                    )}
                    {modal.icon === "taxRefund" && (
                      <img src={TaxRefundIcon} alt="Header icon" />
                    )}
                    {modal.icon === "dismissal" && (
                      <img src={DismissalIcon} alt="Header icon" />
                    )}
                    {modal.icon === "copyright" && (
                      <img src={CopyrightIcon} alt="Header icon" />
                    )}
                    {modal.icon === "room" && (
                      <img src={RoomIcon} alt="Header icon" />
                    )}
                    {modal.icon === "book" && (
                      <img src={BookIcon} alt="Header icon" />
                    )}
                    {modal.icon === "carAccident" && (
                      <img src={CarAccidentIcon} alt="Header icon" />
                    )}
                    {modal.icon === "hurricane" && (
                      <img src={HurricaneIcon} alt="Header icon" />
                    )}
                    {modal.icon === "hail" && (
                      <img src={HailIcon} alt="Header icon" />
                    )}
                    {modal.icon === "sportcar" && (
                      <img src={SportcarIcon} alt="Header icon" />
                    )}
                    {modal.icon === "flat" && (
                      <img src={FlatIcon} alt="Header icon" />
                    )}
                    {modal.icon === "offlineBusiness" && (
                      <img src={OfflineBusinessIcon} alt="Header icon" />
                    )}
                    {modal.icon === "onlineBusiness" && (
                      <img src={OnlineBusinessIcon} alt="Header icon" />
                    )}
                    {modal.icon === "partnership" && (
                      <img src={PartnershipIcon} alt="Header icon" />
                    )}
                    {modal.icon === "buyLifeInsurance" && (
                      <img src={BuyLifeInsuranceIcon} alt="Header icon" />
                    )}
                    {modal.icon === "useAuto" && (
                      <img src={UseAutoIcon} alt="Header icon" />
                    )}
                    {modal.icon === "carCrash" && (
                      <img src={CarCrashIcon} alt="Header icon" />
                    )}
                    {modal.icon === "hijacking" && (
                      <img src={HijackingIcon} alt="Header icon" />
                    )}
                    {modal.icon === "sellSmallBusiness" && (
                      <img src={SellSmallBusinessIcon} alt="Header icon" />
                    )}
                    {modal.icon === "sellRealty" && (
                      <img src={SellRealtyIcon} alt="Header icon" />
                    )}
                    {modal.icon === "businessGrowth" && (
                      <img src={BusinessGrowthIcon} alt="Header icon" />
                    )}
                    {modal.icon === "sellStuff" && (
                      <img src={SellStuffIcon} alt="Header icon" />
                    )}
                    {modal.icon === "competition" && (
                      <img src={CompetitionIcon} alt="Header icon" />
                    )}
                    {modal.icon === "ransom" && (
                      <img src={RansomIcon} alt="Header icon" />
                    )}
                    {modal.icon === "reorganization" && (
                      <img src={ReorganisationIcon} alt="Header icon" />
                    )}
                  </HeaderIcon>
                )}
                {modal.panel === 14 && (
                  <Header
                    data-panel={modal.panel}
                    data-direction="column"
                    data-align="flex-left"
                    data-py="10"
                    data-line="22px"
                  >
                    {modal.head}
                    <br />
                    <span data-text="17" data-color="white" data-bold="true">
                      Внешний фактор
                    </span>
                  </Header>
                )}
                {modal.panel !== 14 && (
                  <Header data-panel={modal.panel}>{modal.head}</Header>
                )}
              </Row>
              <Body>
                <Row data-align="left">
                  <Text>{modal.text}</Text>
                </Row>
                {modal.value !== undefined && (
                  <Row data-align="center" data-mt="40">
                    <Head>{modal.value}</Head>
                  </Row>
                )}
                {modal.input && modal.input.type === "lifeInsuranceEnd" && (
                  <Row data-mt="20">
                    <TextBlock
                      data-selected={
                        data && data.vars.buyLifeInsuranceType === "Базовая"
                      }
                    >
                      <TextBlockIcon
                        src={WhiteCheckIcon}
                        alt="Selected icon"
                      ></TextBlockIcon>
                      Базовая:
                      <br />
                      5-6 - Доход 30%
                      <br />
                      3-4 - Доход 20%
                      <br />
                      1-2 - Доход 10%
                      <br />
                    </TextBlock>
                    <TextBlock
                      data-selected={
                        data && data.vars.buyLifeInsuranceType !== "Базовая"
                      }
                    >
                      <TextBlockIcon
                        src={WhiteCheckIcon}
                        alt="Selected icon"
                      ></TextBlockIcon>
                      Премьер:
                      <br />
                      6 - Доход 100%
                      <br />
                      5 - Доход 80%
                      <br />
                      4 - Доход 50%
                      <br />
                      1-3 - Доход 0%
                      <br />
                    </TextBlock>
                  </Row>
                )}
                {modal.input && modal.input.type === "hobby" && (
                  <Row data-mt="30">
                    <Page
                      onClick={() => (hobby > 1 ? setHobby(hobby - 1) : "")}
                      data-disabled={hobby === 1 ? "true" : "false"}
                    >
                      <LeftArrow src={ArrowIcon} />
                    </Page>
                    <HobbyName>
                      {
                        {
                          1: "Игра на гитаре",
                          2: "Уроки живописи",
                          3: "Выжигание по дереву",
                          4: "Уроки танцев",
                        }[hobby]
                      }
                    </HobbyName>
                    <Page
                      onClick={() => (hobby < 4 ? setHobby(hobby + 1) : "")}
                      data-disabled={hobby === 4 ? "true" : "false"}
                    >
                      <RightArrow src={ArrowIcon} />
                    </Page>
                  </Row>
                )}
                {modal.input && modal.input.type === "list" && (
                  <Row data-mt="20">
                    <List>
                      <ListHead>
                        <ListHeadName>{modal.input.caption}</ListHeadName>
                        <ListHeadControl onClick={() => {}}>
                          <img src={BlackMinusIcon} alt="Switch all" />
                        </ListHeadControl>
                      </ListHead>
                      {modal.input.source.map((item, i) => (
                        <ListItem
                          data-checked={list.includes(i)}
                          key={i}
                          onClick={() => {
                            setList((prev) => {
                              let res = prev.includes(i)
                                ? prev.filter((j) => j !== i)
                                : modal.input.max === 1
                                ? [i]
                                : [...prev, i];
                              return res;
                            });
                          }}
                        >
                          <ListItemName>{item.name}</ListItemName>
                          <ListItemControl>
                            <img
                              src={
                                list.includes(i)
                                  ? WhiteCheckIcon
                                  : WhitePlusIcon
                              }
                              alt="Switch all"
                            />
                          </ListItemControl>
                        </ListItem>
                      ))}
                    </List>
                  </Row>
                )}
                <Row
                  data-align="left"
                  data-mt="40"
                  data-ml="-5"
                  data-mr="-5"
                  data-mb="-10"
                  data-wrap="wrap"
                >
                  {modal.input && modal.input.type === "text" && (
                    <ButtonsField
                      type="number"
                      min="0"
                      step="1"
                      placeholder="Введите сумму"
                      value={bid}
                      onChange={(e) => setBid(e.target.value)}
                      data-mobile-w="100"
                      data-mobile-mx="5"
                      data-mobile-mb="10"
                    />
                  )}
                  {modal.buttons.map((button, i) => (
                    <React.Fragment key={i}>
                      {button.visible !== false && (
                        <Button
                          data-type={button.type}
                          data-mobile-w="100"
                          onClick={() => {
                            if (button.link === "credit") {
                              setModalCredit(true);
                            } else if (
                              button.change &&
                              data.vars.cash + button.change < 0
                            ) {
                              setModalWarning(true);
                              setWarningAction(
                                [action, i, JSON.stringify({ bid, list, hobby })]
                              );
                            } else {
                              setLoading(i);
                              action(i, JSON.stringify({ bid, list, hobby }));
                            }
                          }}
                          data-loading={loading === i}
                          data-disabled={loading !== null}
                        >
                          <Loader
                            src={LoaderIcon}
                            alt="Loader icon"
                            data-loader
                          />
                          {button.name}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </Row>
              </Body>
            </Container730>
          )}
        </>
      </Modal>
      <Modal show={modalWarning} valign="flex-start">
        <Container554>
          <Body>
            <Row data-align="center">
              <Text data-align="center">
                Эта сделка может привести к техническому дефолту, вы уверены,
                что хотите ее осуществить?
              </Text>
            </Row>
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-wrap="wrap"
            >
              <Button
                data-type="red"
                onClick={() => {
                  setModalWarning(false);
                  warningAction && warningAction[0](...warningAction.slice(1));
                }}
              >
                Подтвердить
              </Button>
              <Button
                data-type="green"
                onClick={() => {
                  setModalWarning(false);
                }}
              >
                Отказаться
              </Button>
            </Row>
          </Body>
        </Container554>
      </Modal>
    </>
  );
}

export default CardModal;
