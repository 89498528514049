import React from "react";

import Modal, {
  Container730,
  Head,
  Body,
  Text,
  TextHead,
  Row,
  Box,
  Button,
} from "../../Modal";

import CustomButton from "../../CustomButton";

const Business = ({ show, hide, action, data, mode }) => {
  const [allBusinesses, setAllBusinesses] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [summ, setSumm] = React.useState(0);
  const [expenses, setExpenses] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      let list = [];
      let s = [];

      for (const onlineBusinessId of Object.keys(data.vars.onlineBusiness)) {
        const onlineBusiness = data.vars.onlineBusiness[onlineBusinessId];
        list.push(
          Object.assign(
            { id: `onlineBusiness-${onlineBusinessId}` },
            onlineBusiness
          )
        );
        if (onlineBusiness.insurance && onlineBusiness.insurance.expenses > 0) {
          s.push(`onlineBusiness-${onlineBusinessId}`);
        }
      }

      for (const offlineBusinessId of Object.keys(data.vars.offlineBusiness)) {
        const offlineBusiness = data.vars.offlineBusiness[offlineBusinessId];
        list.push(
          Object.assign(
            { id: `offlineBusiness-${offlineBusinessId}` },
            offlineBusiness
          )
        );
        if (
          offlineBusiness.insurance &&
          offlineBusiness.insurance.expenses > 0
        ) {
          s.push(`offlineBusiness-${offlineBusinessId}`);
        }
      }

      setAllBusinesses(list);
      setSelected(s);
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    let a = 0;
    let b = 0;
    selected.forEach((s) => {
      allBusinesses.forEach((item) => {
        if (item.id === s) {
          a += item.value;
          b += item.value * 0.01 * 0.5;
        }
      });
    });
    setSumm(a);
    setExpenses(b);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Modal show={show} valign="center">
      <Container730>
        <Head data-align="left">Страхование бизнеса</Head>
        <Body>
          <Row data-align="left">
            Полис страхования бизнеса позволяет защитить владельца бизнеса от
            банкротства.
            <br />
            Страховая сумма – стоимость бизнеса.
            <br />
            Страховой взнос: 0,5% в месяц от страховой суммы.
            <br />
            Страховой случай - банкротство.
          </Row>

          <Row data-align="left" data-mt="20" data-mx="-10" data-desktop="flex">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">
                  <strong>Объект страхования</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mx="5"
              >
                <Text data-text="17">
                  <strong>Стоимость актива</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">
                  <strong>Статус страхования</strong>
                </Text>
              </Box>
            </Box>
          </Row>

          {allBusinesses.map((item, i) => (
            <Row data-align="left" data-mt="10" data-mx="-10" key={i}>
              <Box
                data-round="10"
                data-bg={
                  item.insurance.expenses === 0 &&
                    selected.includes(item.id)
                    ? "lightestGreen"
                    : "lightBlue"
                }
                data-p="10"
                data-w="100"
                data-direction="row"
                data-mobile-direction="column"
              >
                <Box
                  data-round="10"
                  data-bg={
                    item.insurance.expenses === 0 &&
                      selected.includes(item.id)
                      ? "lightGreen"
                      : "lightGray"
                  }
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-mr="5"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-h="50"
                  data-mobile-p="5"
                  data-mobile-align="left"
                >
                  <TextHead data-text="17">Объект страхования</TextHead>
                  <Text data-text="17">{item.name}</Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg={
                    item.insurance.expenses === 0 &&
                      selected.includes(item.id)
                      ? "lightGreen"
                      : "lightGray"
                  }
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-mx="5"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-h="50"
                  data-mobile-p="5"
                  data-mobile-mt="10"
                  data-mobile-align="left"
                >
                  <TextHead data-text="17">Стоимость актива</TextHead>
                  <Text data-text="17">
                    {item.value.toLocaleString("ru-RU")} &#8381;
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="33.33"
                  data-halign="center"
                  data-ml="0"
                  data-direction="row"
                  data-px="0"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  data-mobile-h="50"
                >
                  <CustomButton
                    id={item.id}
                    insurance={true}
                    active={item.insurance.expenses > 0}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Box>
              </Box>
            </Row>
          ))}

          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-align="left"
              >
                <Text data-text="17">Страховая сумма</Text>
                <Text data-text="17">
                  <strong>{summ.toLocaleString("ru-RU")} &#8381;</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-ml="10"
                data-valign="center"
                data-w="33.33"
                data-halign="center"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-align="left"
                data-mobile-mt="10"
              >
                <Text data-text="17">Страховой взнос</Text>
                <Text data-text="17">
                  <strong>
                    {expenses.toLocaleString("ru-RU")} &#8381;
                  </strong>
                </Text>
              </Box>
              <Button
                data-type="blue"
                data-h="60"
                data-ml="10"
                data-mb="0"
                data-mr="0"
                data-w="33.33"
                data-mobile-h="40"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-mt="10"
                onClick={() =>
                  action({ _insurance: { business: selected } })
                }
              >
                Применить
              </Button>
            </Box>
          </Row>

          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-mobile-mt="10"
          >
            <Button data-type="green" onClick={hide}>
              {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
            </Button>
          </Row>
        </Body>
      </Container730>
    </Modal>
  );
};

export default Business;
