import React from "react";

import Modal, {
  Container844,
  Head,
  Body,
  Text,
  Row,
  Box,
  Button,
} from "../../Modal";

import ShieldIcon from "../../../img/ShieldIcon.svg";
import WhiteCrossIcon from "../../../img/WhiteCrossIcon.svg";

const Health = ({ show, hide, action, data, mode }) => {
  return (
    <Modal show={show} valign="center">
      <Container844>
        <Head data-align="left">Страхование здоровья</Head>
        <Body>
          <Row data-align="left">
            <Box>
              <strong>Добровольное медицинское страхование (ДМС):</strong>
              <br />
              При добровольном медицинском страховании Вы приобретаете право
              приоритетного обслуживания в лечебном учреждении, можете получить
              медицинскую помощь, не входящую в программу ОМС (обязательного
              медицинского страхования), таким образом, сэкономив время и
              деньги.
              <br />
              Страховые взносы: 1000 р. в месяц.
              <br />
            </Box>
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10" data-mobile-mx="0">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-mobile-p="5"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="100"
                data-halign="center"
              >
                <Text data-text="17">Страховой взнос в месяц</Text>
                <Text data-text="17">
                  <strong>1000 &#8381;</strong>
                </Text>
              </Box>
              {data && data.vars.dmsInsuranceExpenses > 0 ? (
                <div data-direction="row" data-mobile-mt="5">
                  <Box
                    data-round="10"
                    data-bg="darkBlue"
                    data-p="10"
                    data-h="60"
                    data-valign="center"
                    data-w="100"
                    data-max-w="184"
                    data-mobile-max-w="none"
                    data-halign="center"
                    data-mr="0"
                    data-ml="10"
                    data-mobile-ml="0"
                  >
                    <Text
                      data-text="17"
                      data-align="center"
                      data-valign="center"
                      data-color="white"
                    >
                      <strong>Застрахован</strong>
                      <img src={ShieldIcon} alt="" data-ml="10" />
                    </Text>
                  </Box>
                  <Button
                    data-type="redBg"
                    data-form="square"
                    data-h="60"
                    data-w="60px"
                    data-ml="10"
                    data-mobile-ml="5"
                    data-mb="0"
                    data-mr="0"
                    data-color="white"
                    onClick={() =>
                      action({ _insurance: { health: { dms: false } } })
                    }
                  >
                    <img src={WhiteCrossIcon} alt="" />
                  </Button>
                </div>
              ) : (
                <Button
                  data-type="blue"
                  data-h="60"
                  data-ml="10"
                  data-mb="0"
                  data-mr="0"
                  data-min-w="254"
                  data-mobile-ml="0"
                  data-mobile-mt="5"
                  onClick={() =>
                    action({ _insurance: { health: { dms: true } } })
                  }
                >
                  Застраховать
                </Button>
              )}
            </Box>
          </Row>
          <Row data-align="left" data-mt="40">
            <Box>
              <strong>Страхование от несчастного случая (НС):</strong>
              <br />
              При страховании от несчастного случая Вы защищаете себя от
              расходов при неприятностях со здоровьем, таких как разного рода
              травмы (переломы, растяжения, ушибы и т.п.).
              <br />
              Страховая сумма: 500 тыс. р.
              <br />
              Страховые взносы: 300 р. в месяц.
              <br />
              <br />
              Дополнительные игровые преимущества:
              <br />
              При страховом случае (травме) при наличии полиса по страхованию от
              НС выплата 20 000 р. и сохранение игровых ходов.
            </Box>
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10" data-mobile-mx="0">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-mobile-p="5"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="100"
                data-halign="center"
              >
                <Text data-text="17">Страховая сумма</Text>
                <Text data-text="17">
                  <strong>500 000 &#8381;</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="100"
                data-halign="center"
                data-ml="10"
                data-mobile-ml="0"
                data-mobile-mt="5"
              >
                <Text data-text="17">Страховой взнос в месяц</Text>
                <Text data-text="17">
                  <strong>300 &#8381;</strong>
                </Text>
              </Box>
              {data && data.vars.nsInsuranceExpenses > 0 ? (
                <div data-direction="row" data-mobile-mt="5">
                  <Box
                    data-round="10"
                    data-bg="darkBlue"
                    data-p="10"
                    data-h="60"
                    data-valign="center"
                    data-w="100"
                    data-max-w="184"
                    data-mobile-max-w="none"
                    data-halign="center"
                    data-mr="0"
                    data-ml="10"
                    data-mobile-ml="0"
                  >
                    <Text
                      data-text="17"
                      data-align="center"
                      data-valign="center"
                      data-color="white"
                    >
                      <strong>Застрахован</strong>
                      <img src={ShieldIcon} alt="" data-ml="10" />
                    </Text>
                  </Box>
                  <Button
                    data-type="redBg"
                    data-form="square"
                    data-h="60"
                    data-w="60px"
                    data-ml="10"
                    data-mobile-ml="5"
                    data-mb="0"
                    data-mr="0"
                    data-color="white"
                    onClick={() =>
                      action({ _insurance: { health: { ns: false } } })
                    }
                  >
                    <img src={WhiteCrossIcon} alt="" />
                  </Button>
                </div>
              ) : (
                <Button
                  data-type="blue"
                  data-h="60"
                  data-ml="10"
                  data-mb="0"
                  data-mr="0"
                  data-min-w="254"
                  data-mobile-ml="0"
                  data-mobile-mt="5"
                  onClick={() =>
                    action({ _insurance: { health: { ns: true } } })
                  }
                >
                  Застраховать
                </Button>
              )}
            </Box>
          </Row>
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
          >
            <Button data-type="green" onClick={hide}>
              {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
            </Button>
          </Row>
        </Body>
      </Container844>
    </Modal>
  );
};

export default Health;
