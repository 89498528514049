import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";

import MainWrap, { Container } from "../view/MainWrap";
import { request } from "../components/Utils";

import {
  Head,
  FieldLine,
  Field,
  Input,
  Label,
  Button,
  ButtonLink,
  RadioInput,
  RadioLabel,
  RadioWrap,
  RadioButton,
  ErrorBox,
} from "../view/Form";

import Modal, {
  Container1040,
  Head as ModalHead,
  Body as ModalBody,
  Row,
  Button as ModalButton,
} from "../components/Modal";

const Footer = styled.label`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

const TermsLink = styled.button`
  display: inline;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
  cursor: pointer;
  color: #293a47;
  border-bottom: 1px solid #293a47;
  &:hover {
    border-bottom: 1px solid transparent;
  }
`;

const CheckboxLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #293a47;
  opacity: 0.5;
`;

const Register = ({ app }) => {
  let navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [age, setAge] = React.useState("");
  const [patronymic, setPatronymic] = React.useState("");
  const [gender, setGender] = React.useState("male");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [school, setSchool] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [userType, setUserType] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const [fields, setFields] = React.useState({});

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [serverErrors, setServerErrors] = React.useState({});
  const [touched, setTouched] = React.useState(false);

  const [modalAgreement, setModalAgreement] = React.useState(false);

  React.useEffect(() => {
    const loadData = async () => {
      const resp = await request({
        url: "/api/settings",
        params: {
          method: "GET",
          //redirect: "follow",
          body: {},
        },
        app: app,
      });
      //console.log(resp)
      if (resp && resp.success) {
        //console.log(resp.result.data)
        setSettings(resp.result.data);
      }
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    //debugger;
    if (name.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, name: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, name: false };
      });
    }

    if (surname.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, surname: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, surname: false };
      });
    }

    if (age.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, age: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, age: false };
      });
    }

    if (patronymic.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, patronymic: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, patronymic: false };
      });
    }

    if (phone.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, phone: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, phone: false };
      });
    }

    if (city.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, city: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, city: false };
      });
    }

    if (userType === null) {
      setErrors((prevState) => {
        return { ...prevState, userType: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, userType: false };
      });
    }

    if (school.trim() === "" && userType && userType.value !== "1") {
      setErrors((prevState) => {
        return { ...prevState, school: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, school: false };
      });
    }

    if (email.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, email: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, email: false };
      });
    }

    if (password.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, password: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, password: false };
      });
    }
  }, [
    name,
    surname,
    userType,
    age,
    city,
    patronymic,
    phone,
    email,
    school,
    password,
  ]);

  React.useEffect(() => {
    console.log(errors);
  }, [errors]);

  const send = async () => {
    console.log(1);
    if (!errors.name && !errors.email) {
      console.log(2);
      const resp = await request({
        url: "/api/user/register/",
        params: {
          method: "POST",
          body: {
            name,
            surname,
            gender,
            age,
            city,
            patronymic,
            phone,
            school,
            email,
            password,
            userType,
            fields,
          },
        },
        app: app,
      });

      if (app.current.loggedIn) {
        navigate("/cp");
      }

      if (resp && resp.errors) {
        setServerErrors(resp.errors);
      }
    }
    setTouched(true);
    return false;
  };

  const options = [
    { value: "1", label: "Взрослые" },
    { value: "2", label: "Студенты" },
    { value: "3", label: "Школьники" },
  ];

  const customStyles = {
    control: (provided, state) => {
      // none of react-select's styles are passed to <Control />
      //width: 200,
      //console.log(provided);
      return {
        ...provided,
        height: 50,
        borderColor: "#efefef",
        borderWidth: 0.5,
        "&:hover": { borderColor: "#efefef" },
      };
    },
    singleValue: (provided, state) => {
      //const opacity = state.isDisabled ? 0.5 : 1;
      //const transition = 'opacity 300ms';

      return { ...provided };
    },
  };

  return (
    <>
      <MainWrap app={app} panel="register">
        <Container>
          <Head>Регистрация профиля</Head>
          <FieldLine>
            <Field data-valid={errors.name && touched ? "false" : "true"}>
              <Label>Имя</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Field>
            <Field data-valid={errors.surname && touched ? "false" : "true"}>
              <Label>Фамилия</Label>
              <Input
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Field>
          </FieldLine>
          <FieldLine data-mt="30">
            <Field data-valid={errors.patronymic && touched ? "false" : "true"}>
              <Label>Отчество</Label>
              <Input
                type="text"
                value={patronymic}
                onChange={(e) => setPatronymic(e.target.value)}
              />
            </Field>
            <Field data-valid={errors.age && touched ? "false" : "true"}>
              <Label>Возраст</Label>
              <Input
                type="text"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </Field>
          </FieldLine>
          <Field>
            <Label>Пол</Label>
            <RadioWrap>
              <RadioButton>
                <RadioInput
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === "male"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <RadioLabel>Мужской</RadioLabel>
              </RadioButton>
              <RadioButton>
                <RadioInput
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <RadioLabel>Женский</RadioLabel>
              </RadioButton>
            </RadioWrap>
          </Field>
          <FieldLine data-mt="30">
            <Field data-valid={errors.phone && touched ? "false" : "true"}>
              <Label>Телефон</Label>
              <Input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Field>
            <Field data-valid={errors.city && touched ? "false" : "true"}>
              <Label>Город</Label>
              <Input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Field>
          </FieldLine>
          <Field data-valid={errors.userType && touched ? "false" : "true"}>
            <Label>Группа</Label>
            <Select
              value={userType}
              onChange={setUserType}
              options={options}
              styles={customStyles}
              placeholder="Не выбрано"
            />
          </Field>
          {userType && userType.value !== "1" && (
            <Field data-valid={errors.school && touched ? "false" : "true"}>
              <Label>Наименование учебного учреждения</Label>
              <Input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
              {serverErrors && serverErrors.school && (
                <ErrorBox data-mt="10">{serverErrors.school}</ErrorBox>
              )}
            </Field>
          )}
          {false &&
            userType &&
            settings &&
            settings[userType.value].fields.map((field, i) => (
              <Field key={i}>
                <Label>{field.name}</Label>
                <Input
                  type="text"
                  autoComplete="email"
                  value={fields[i]}
                  onChange={(e) =>
                    setFields((prevState) => {
                      let newState = { ...prevState };
                      newState[i] = e.target.value;
                      return newState;
                    })
                  }
                />
              </Field>
            ))}
          <Field data-valid={errors.email && touched ? "false" : "true"}>
            <Label>Email</Label>
            <Input
              type="text"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {serverErrors && serverErrors.email && (
              <ErrorBox data-mt="10">{serverErrors.email}</ErrorBox>
            )}
          </Field>
          <Field data-valid={errors.password && touched ? "false" : "true"}>
            <Label>Пароль</Label>
            <Input
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {serverErrors && serverErrors.password && (
              <ErrorBox data-mt="10">{serverErrors.password}</ErrorBox>
            )}
          </Field>
          <Button type="button" onClick={send}>
            Зарегистрироваться
          </Button>
          <ButtonLink to="/login" data-type="gray" data-mobile data-mt="10">
            Войти
          </ButtonLink>
        </Container>
        <Footer>
          <CheckboxLabel data-max-w="414px" data-align="center">
            Нажимая на кнопку “Зарегистрироваться”, Вы даете согласие на{" "}
            <TermsLink onClick={() => setModalAgreement(true)}>
              обработку персональных данных
            </TermsLink>
            .
          </CheckboxLabel>
        </Footer>
        <Modal show={modalAgreement} valign="top">
          <Container1040>
            <ModalHead data-align="left">
              Согласие пользователя сайта на обработку персональных данных
            </ModalHead>
            <ModalBody>
              <Row data-align="left">
                1. Настоящим я, далее – «Субъект Персональных Данных», во
                исполнение требований Федерального закона от 27.07.2006 г. №
                152-ФЗ «О персональных данных» (с изменениями и дополнениями)
                свободно, своей волей и в своем интересе даю свое согласие
                Всероссийскому союзу страховщиков, далее – «ВСС», на обработку
                своих персональных данных, указанных при регистрации путем
                заполнения веб-формы на сайте ВСС по адресу
                https://game.ins-union.ru/, направляемой (заполненной) с
                использованием Сайта ВСС.
              </Row>
              <Row data-align="left" data-mt="10">
                2. Обработка персональных данных Субъекта Персональных Данных
                осуществляется исключительно в целях регистрации Субъекта
                Персональных Данных в веб-приложении деловой игры по финансовой
                грамотности «Предел Риска» (далее Игра) на сайте ВСС с
                последующим направлением Субъекту Персональных Данных почтовых
                сообщений и смс-уведомлений от Всероссийского союза
                страховщиков, информационных и новостных рассылок, приглашений
                на мероприятия Игры и другой информации рекламно-новостного
                содержания, а также с целью подтверждения личности Субъекта
                Персональных Данных при посещении мероприятий Игры.
              </Row>
              <Row data-align="left" data-mt="10">
                3. Под персональными данными понимается любая информация,
                относящаяся к прямо или косвенно определенному или определяемому
                физическому лицу (Субъекту Персональных Данных), в том числе для
                целей Игры: фамилия, имя, отчество, год рождения, контактные
                данные (телефон, электронная почта). Под обработкой персональных
                данных понимается любое действие (операция) или совокупность
                действий (операций), совершаемых с использованием средств
                автоматизации или без использования таких средств с
                персональными данными, включая сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление,
                уничтожение персональных данных.
              </Row>
              <Row data-align="left" data-mt="10">
                4. Датой выдачи согласия на обработку персональных данных
                Субъекта Персональных Данных является дата отправки
                регистрационной веб-формы с сайта ВСС по адресу
                https://game.ins-union.ru/.
              </Row>
              <Row data-align="left" data-mt="10">
                5. Обработка персональных данных Субъекта Персональных Данных
                может осуществляться с помощью средств автоматизации и/или без
                использования средств автоматизации в соответствии с действующим
                законодательством РФ и внутренними положениями ВСС.
              </Row>
              <Row data-align="left" data-mt="10">
                6. ВСС принимает необходимые правовые, организационные и
                технические меры или обеспечивает их принятие для защиты
                персональных данных от неправомерного или случайного доступа к
                ним, уничтожения, изменения, блокирования, копирования,
                предоставления, распространения персональных данных, а также от
                иных неправомерных действий в отношении персональных данных, а
                также принимает на себя обязательство сохранения
                конфиденциальности персональных данных Субъекта Персональных
                Данных.
              </Row>
              <Row data-align="left" data-mt="10">
                7. Я ознакомлен(а), что:
                <br />
                • настоящее согласие на обработку моих персональных данных,
                указанных при регистрации на Сайте ВСС
                https://game.ins-union.ru/, направляемых (заполненных) с
                использованием Cайта, действует с момента регистрации на Сайте
                ВСС до момента отзыва согласия в установленном законодательством
                порядке;
                <br />• согласие может быть отозвано мною на основании
                письменного заявления в установленном законодательством порядке.
              </Row>
              <Row data-align="left" data-mt="10">
                Действительно на сайте ВСС с «01» «августа» «2022» года.
              </Row>
              <Row
                data-align="left"
                data-mt="40"
                data-ml="-5"
                data-mr="-5"
                data-mb="-10"
              >
                <ModalButton
                  data-type="green"
                  onClick={() => {
                    setModalAgreement(false);
                  }}
                  data-mobile-w="100"
                >
                  Закрыть
                </ModalButton>
              </Row>
            </ModalBody>
          </Container1040>
        </Modal>
      </MainWrap>
    </>
  );
};

Register.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Register;
