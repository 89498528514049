import React from "react";

import Modal, {
  Container554,
  Head,
  Body,
  Text,
  Row,
  Button,
  BigButton,
} from "../Modal";

import LifeInsuranceIcon2 from "../../img/LifeInsuranceIcon2.svg";
import HealthInsuranceIcon from "../../img/HealthInsuranceIcon.svg";
import RealtyInsuranceIcon from "../../img/RealtyInsuranceIcon.svg";
import CarInsuranceIcon from "../../img/CarInsuranceIcon.svg";
import BusinessInsuranceIcon from "../../img/BusinessInsuranceIcon.svg";
import MortgageInsuranceIcon from "../../img/MortgageInsuranceIcon.svg";
import CreditInsuranceIcon from "../../img/CreditInsuranceIcon.svg";

function InsuranceModal({
  show,
  hide,
  setModalInsuranceLife,
  setModalInsuranceHealth,
  setModalInsuranceCredit,
  setModalInsuranceMortgage,
  setModalInsuranceRealty,
  setModalInsuranceBusiness,
  setModalInsuranceCar,
}) {
  return (
    <>
      <Modal show={show} valign="center">
        <Container554>
          <Head>Страхование</Head>
          <Body>
            <Row data-align="center" data-mt="40" data-mx="-10">
              <BigButton
                data-w="50"
                onClick={() => setModalInsuranceLife(true)}
              >
                <img src={LifeInsuranceIcon2} alt="" />
                <Text data-text="18">Жизнь</Text>
              </BigButton>
              <BigButton
                data-w="50"
                onClick={() => setModalInsuranceHealth(true)}
              >
                <img src={HealthInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Здоровье
                </Text>
              </BigButton>
            </Row>
            <Row data-align="center" data-mt="20" data-mx="-10">
              <BigButton
                data-w="33.33"
                onClick={() => setModalInsuranceRealty(true)}
              >
                <img src={RealtyInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Недвижимость
                </Text>
              </BigButton>
              <BigButton
                data-w="33.33"
                onClick={() => setModalInsuranceCar(true)}
              >
                <img src={CarInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Автомобиль
                </Text>
              </BigButton>
              <BigButton
                data-w="33.33"
                onClick={() => setModalInsuranceBusiness(true)}
              >
                <img src={BusinessInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Бизнес
                </Text>
              </BigButton>
            </Row>
            <Row data-align="center" data-mt="20" data-mx="-10">
              <BigButton onClick={() => setModalInsuranceMortgage(true)}>
                <img src={MortgageInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Ипотека
                </Text>
              </BigButton>
              <BigButton onClick={() => setModalInsuranceCredit(true)}>
                <img src={CreditInsuranceIcon} alt="" />
                <Text data-text="18" data-mt="10">
                  Потребительский кредит
                </Text>
              </BigButton>
            </Row>
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <Button data-type="green" onClick={hide}>
                К игре
              </Button>
            </Row>
          </Body>
        </Container554>
      </Modal>
    </>
  );
}

export default InsuranceModal;
