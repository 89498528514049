import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import MainWrap, { Container } from "../view/MainWrap";
import { request } from "../components/Utils";

import { Head, Field, Input, Label, Button, ButtonLink, LinkStyled } from "../view/Form";

const Login = ({ app }) => {
  let navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState(false);

  React.useEffect(() => {
    if (email.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, email: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, email: false };
      });
    }
  }, [email]);

  const send = async () => {
    if (!errors.name && !errors.email) {
      await request({
        url: "/api/user/login/",
        params: {
          method: "POST",
          body: {
            email: email,
            password: password,
          },
        },
        app: app,
      });

      if (app.current.loggedIn) {
        navigate("/cp");
      }
    }
    setTouched(true);
    return false;
  };

  return (
    <MainWrap app={app} panel="login">
      <Container style={{width: '100%', maxWidth: '475px'}}>
        <Head>Вход в профиль</Head>
        <Field data-valid={errors.name && touched ? "false" : "true"}>
          <Label>Email</Label>
          <Input
            type="text"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Field>
        <Field data-valid={errors.name && touched ? "false" : "true"}>
          <Label>Пароль</Label>
          <Input
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Field>
        <Button type="button" onClick={send}>
          Войти
        </Button>
        <ButtonLink to="/register" data-type="gray" data-mobile="block" data-mt="10">
            Зарегистрироваться
          </ButtonLink>
      </Container>
      <LinkStyled onClick={() => navigate("/remind")}>Забыли пароль?</LinkStyled>
    </MainWrap>
  );
};

Login.propTypes = {
  app: PropTypes.object,
};

export default Login;
