import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { request } from "../components/Utils";
import { useNavigate } from "react-router-dom";

import NewGameIcon from "../img/NewGameIcon.svg";
import TournamentsIcon from "../img/TournamentsIcon.png";
import OveralRatingIcon from "../img/OveralRatingIcon.svg";
import SettingsIcon from "../img/SettingsIcon.svg";
import WhiteCrossIcon from "../img/WhiteCrossIcon.svg";

import Modal, {
  Container530,
  Head,
  Body,
  Field,
  Row,
  Button,
} from "../components/Modal";

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 37px 0 0;
  width: 100%;
  & > [data-id="1"] {
    width: 33.33%;
    padding-right: 20px;
  }
  & > [data-id="2"] {
    width: 33.33%;
    padding-left: 10px;
    padding-right: 10px;
  }
  & > [data-id="3"] {
    width: 33.33%;
    padding-left: 20px;
  }
  @media (max-width: 900px) {
    & > [data-id="1"] {
      width: 50%;
      padding-right: 5px;
    }
    & > [data-id="2"] {
      width: 50%;
      padding-left: 5px;
      padding-right: 0;
    }
    & > [data-id="3"] {
      width: 100%;
      padding-left: 0;
      padding-top: 10px;
    }
  }
`;

const MainButton = styled.button`
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #f4f7ff;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
  @media (max-width: 900px) {
    height: 141px;
    padding: 15px 0;
  }
`;

const MainButtonLink = styled(Link)`
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #f4f7ff;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  @media (max-width: 900px) {
    height: 141px;
    padding: 15px 0;
  }
`;

const ButtonIcon = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #293a47;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

const SmallRadio = styled.button`
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  border-radius: 10px;
  border: 0;
  margin-right: 10px;
  color: #293a47;
  background: #e8ebf3;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
  &[data-active="true"] {
    font-weight: 700;
    color: #fdfdfd;
    background: #286fb7;
  }
`;

const SquareButton = styled.button`
  width: 40px;
  height: 40px;
  background: #be1622;
  border: 1px solid #be1622;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SettingsModal = ({ app, show, hide }) => {
  const [mode, setMode] = React.useState(1);
  const [timer, setTimer] = React.useState(null);
  const [value, setValue] = React.useState({
    1: { fields: [] },
    2: { fields: [] },
    3: { fields: [] },
  });

  React.useEffect(() => {
    const loadData = async () => {
      const resp = await request({
        url: "/api/settings",
        params: {
          method: "GET",
          //redirect: "follow",
          body: {},
        },
        app: app,
      });
      if (resp && resp.success) {
        setValue(resp.result.data);
      }
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        await request({
          url: "/api/settings/save",
          params: {
            method: "POST",
            //redirect: "follow",
            body: {
              value,
            },
          },
          app: app,
        });
      }, 1000)
    );
    // eslint-disable-next-line
  }, [value]);

  return (
    <Modal show={show} valign="center">
      <Container530>
        <Head data-align="left">Редактирование полей регистрации</Head>
        <Body>
          <Row data-mb="30">
            <SmallRadio data-active={mode === 1} onClick={() => setMode(1)}>
              Взрослые
            </SmallRadio>
            <SmallRadio data-active={mode === 2} onClick={() => setMode(2)}>
              Студенты
            </SmallRadio>
            <SmallRadio data-active={mode === 3} onClick={() => setMode(3)}>
              Школьники
            </SmallRadio>
          </Row>
          {value[mode].fields.map((field, i) => (
            <Row key={i} data-mb="10">
              <Field
                type="text"
                value={value[mode].fields[i].name}
                onChange={(e) =>
                  setValue((prevState) => {
                    let newState = { ...prevState };
                    newState[mode].fields[i].name = e.target.value;
                    return newState;
                  })
                }
                data-ml="0"
                data-w="100"
                data-align="left"
                data-pl="15"
              />
              <SquareButton
                onClick={() =>
                  setValue((prevState) => {
                    let newState = { ...prevState };
                    newState[mode].fields.splice(i, 1);
                    return newState;
                  })
                }
                data-ml="10"
              >
                <img src={WhiteCrossIcon} alt="Remove icon" />
              </SquareButton>
            </Row>
          ))}
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
          >
            <Button
              data-type="green"
              onClick={() =>
                setValue((prevState) => {
                  let newState = { ...prevState };
                  newState[mode].fields.push({ name: "" });
                  return newState;
                })
              }
            >
              Добавить поле
            </Button>
            <Button data-type="blue" onClick={hide}>
              Закрыть
            </Button>
          </Row>
        </Body>
      </Container530>
    </Modal>
  );
};

const Menu = ({ app }) => {
  let navigate = useNavigate();
  const [modalSettings, setModalSettings] = React.useState(false);

  const createNewGame = async () => {
    const resp = await request({
      url: "/api/game/",
      params: {
        method: "POST",
        //redirect: "follow",
        body: {},
      },
      app: app,
    });
    if (resp.result.id != null) {
      navigate(`/cp/game/${resp.result.id}`);
    }
  };
  return (
    <>
      <ButtonsWrap>
        <div data-id="1">
          <MainButton data-id="1" onClick={createNewGame}>
            <ButtonIcon>
              <img src={NewGameIcon} alt="New game" />
            </ButtonIcon>
            <ButtonText>Новая игра</ButtonText>
          </MainButton>
        </div>
        <div data-id="2">
          <MainButtonLink data-id="2" to="/cp/tournaments">
            <ButtonIcon>
              <img src={TournamentsIcon} alt="Tournaments" />
            </ButtonIcon>
            <ButtonText>Турниры</ButtonText>
          </MainButtonLink>
        </div>
        <div data-id="3">
          <MainButtonLink data-id="3" to="/cp/rating">
            <ButtonIcon>
              <img src={OveralRatingIcon} alt="Overal rating" />
            </ButtonIcon>
            <ButtonText>Общий рейтинг</ButtonText>
          </MainButtonLink>
        </div>
        {false && app.current.user.role === "admin" && (
          <MainButton onClick={() => setModalSettings(true)}>
            <ButtonIcon>
              <img src={SettingsIcon} alt="New game" />
            </ButtonIcon>
            <ButtonText>Регистрация</ButtonText>
          </MainButton>
        )}
      </ButtonsWrap>
      <SettingsModal
        app={app}
        show={modalSettings}
        hide={() => setModalSettings(false)}
      />
    </>
  );
};

export default Menu;
