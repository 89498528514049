import React from "react";

import Modal, {
  Container730,
  Head,
  Body,
  Text,
  Row,
  Box,
  Button,
  ButtonsField,
} from "../Modal";

const RepayCreditModal = ({ show, hide, toggleAction, data }) => {
  const [bid, setBid] = React.useState("");
  return (
    <Modal show={show} valign="center">
      <Container730>
        <Head data-align="left">{{
          repayCreditCard: "Кредитная карта",
          repayConsumerCredit: "Потребительский кредит",
          repayFlatCredit: "Ипотека (1 к. квартира)",
          repayRoomCredit: "Ипотека (комната)",
          repayCarCredit: "Кредит (автомобиль)"
        }[data.type]}</Head>
        <Body>
          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="66.66"
                data-halign="center"
              >
                <Text data-text="17">Сумма кредита</Text>
                <Text data-text="17">
                  <strong>
                    {data.summ !== undefined &&
                      data.summ.toLocaleString("ru-RU")}{" "}
                    &#8381;
                  </strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="66.66"
                data-halign="center"
                data-ml="10"
              >
                <Text data-text="17">% в мес.</Text>
                <Text data-text="17">
                  <strong>{data.rate}%</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="66.66"
                data-halign="center"
                data-ml="10"
              >
                <Text data-text="17">Расход</Text>
                <Text data-text="17">
                  <strong>
                    {data.expenses !== undefined &&
                      data.expenses.toLocaleString("ru-RU")}{" "}
                    &#8381;
                  </strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="66.66"
                data-halign="center"
                data-ml="10"
              >
                <Text data-text="17">Наличные</Text>
                <Text data-text="17">
                  <strong>
                    {data.cash !== undefined &&
                      data.cash.toLocaleString("ru-RU")}{" "}
                    &#8381;
                  </strong>
                </Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10">
            <ButtonsField
              type="number"
              min="0"
              step="1"
              placeholder="Введите сумму"
              value={bid}
              onChange={(e) => setBid(e.target.value)}
              data-w="100"
            />
            <Button
              data-type="blue"
              onClick={() => {
                toggleAction({ [data.type]: { id: data.id, value: bid } });
                hide();
              }}
            >
              Погасить
            </Button>
            <Button data-type="green" onClick={hide}>
              Назад
            </Button>
          </Row>
        </Body>
      </Container730>
    </Modal>
  );
};

export default RepayCreditModal;
