import React from "react";
import styled from "styled-components";

import Modal, { Container580, Head, Body, Button } from "../Modal";

import { plural } from "../Utils";

const Col1 = styled.div`
  width: 100%;
  display: flex;
  &[data-col] {
    flex-direction: column;
  }
  align-items: flex-start;
  & + & {
    padding-left: 10px;
  }
`;

const ColIcon = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background: #edeff5;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  border-radius: 10px;
  margin-right: 10px;
  &:empty {
    opacity: 0;
  }
  &[data-big] {
    height: 60px;
  }
  &[data-blue] {
    background: #286fb7;
    color: #fff;
  }
  &[data-disabled] {
    opacity: 0.3;
  }
`;

const ColWrap = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  color: #293a47;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  background: #edeff5;
  padding-left: 30px;
  white-space: nowrap;
  &[data-head] {
    font-weight: 700;
  }
  &[data-small] {
    flex: 0 1 auto;
    width: 123px;
    justify-content: center;
    padding-left: 0;
    text-align: сутеук;
  }
  &[data-big] {
    height: 60px;
  }
  &[data-expand] {
    height: 100%;
    justify-content: center;
    padding-left: 0;
  }
  & + & {
    margin-left: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: 10px;
  }
`;

const BodyContainer = styled(Body)`
  overflow: auto;
  max-height: 400px;
`;

const Footer = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
`;

const activesNames = {
  car: "Автомобиль",
  room: "Комната",
  flat: "Квартира",
  partnership: "Партнёрство",
  offlineBusiness: "Малый офлайн бизнес",
  onlineBusiness: "Малый онлайн бизнес",
};

const getObjectName = (action) => {
  let name = action.data && action.data.name;
  if (name === undefined) {
    name = activesNames[action.value];
  }
  if (action.type === "insertElement" && action.value === "hobby") {
    name = {
      1: "Игра на гитаре",
      2: "Уроки живописи",
      3: "Выжигание по дереву",
      4: "Уроки танцев",
    }[action.data.hobby];
  }
  return name;
};

const getActionDescription = (action) => {
  let name = "";
  switch (action.type) {
    case "moveStudy":
      name = `Выбрано обучение вместо хода.`;
      break;
    case "techDefolt":
      name = `Технический дефолт.`;
      break;
    case "moveRoll":
      name = `Бросок кубика. Выпало ${action.value}`;
      break;
    case "initCard":
      name =
        action.value === "nextMonth"
          ? "Завершён месяц"
          : `Выпала карточка "${action.name}"`;
      break;
    case "insertElement":
      name = `Добавлен актив "${getObjectName(action)}"`;
      break;
    case "removeElement":
      name = `Выбыл актив "${getObjectName(action)}"`;
      break;
    case "creditCardAdd":
      name = `Снятие с кредитной карты на сумму ${action.value.toLocaleString(
        "ru-RU"
      )}`;
      break;
    case "consumerCreditAdd":
      name = `Взят потребительский кредит на сумму ${action.value.toLocaleString(
        "ru-RU"
      )}`;
      break;
    case "movesLeft":
      name = `Пропуск ${Math.abs(action.value)} ${plural(
        action.value,
        ["хода", "ходов", "ходов"]
      )}`;
      break;
    default:
      break;
  }
  return name;
};

const HistoryModal = ({ show, hide, data }) => {
  return (
    <Modal show={show} valign="center">
      <Container580>
        <Head data-align="center">История ходов</Head>
        <BodyContainer>
          <Row data-mb="20">
            <Col1>
              <ColIcon>№</ColIcon>
              <ColWrap data-head>Название действия</ColWrap>
            </Col1>
          </Row>
          {[...data.history].reverse().map((move, i) => (
            <React.Fragment key={i}>
              {[...move].reverse().map((action, j) => (
                <Row key={j}>
                  <Col1>
                    <ColIcon>{j === 0 ? data.history.length - i : ""}</ColIcon>
                    <ColWrap>{getActionDescription(action)}</ColWrap>
                  </Col1>
                </Row>
              ))}
            </React.Fragment>
          ))}
        </BodyContainer>
        <Footer data-align="flex-center">
          <Row
            data-align="flex-center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-w="100"
          >
            <Button data-type="green" onClick={hide}>
              К игре
            </Button>
          </Row>
        </Footer>
      </Container580>
    </Modal>
  );
};

export default HistoryModal;
