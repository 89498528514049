import React from "react";

import Modal, {
  Container730,
  Container1040,
  Head,
  Body,
  Text,
  TextHead,
  Row,
  Box,
  Button,
} from "../../Modal";

import CustomButton from "../../CustomButton";

import HelpIcon from "../../../img/HelpIcon.svg";

const Realty = ({ show, hide, action, data, mode }) => {
  const [modalRealtyTerms, setModalRealtyTerms] = React.useState(false);
  const [allRealty, setAllRealty] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [summ, setSumm] = React.useState(0);
  const [expenses, setExpenses] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      let list = [];
      let s = [];
      if (data.vars.startFlat) {
        let startFlat = Object.assign({ id: "startFlat" }, data.vars.startFlat);
        list.push(startFlat);
        if (data.vars.startFlat.insurance && data.vars.startFlat.insurance.go) {
          s.push("startFlat_go");
        }
        if (
          data.vars.startFlat.insurance &&
          data.vars.startFlat.insurance.amount
        ) {
          s.push("startFlat_amount");
        }
      }

      for (const flatId of Object.keys(data.vars.flat)) {
        const flat = data.vars.flat[flatId];
        list.push(Object.assign({ id: `flat-${flatId}` }, flat));
        if (flat.insurance && flat.insurance.go) {
          s.push(`flat-${flatId}_go`);
        }
        if (flat.insurance && flat.insurance.amount) {
          s.push(`flat-${flatId}_amount`);
        }
      }

      for (const roomId of Object.keys(data.vars.room)) {
        const room = data.vars.room[roomId];
        list.push(Object.assign({ id: `room-${roomId}` }, room));
        if (room.insurance && room.insurance.go) {
          s.push(`room-${roomId}_go`);
        }
        if (room.insurance && room.insurance.amount) {
          s.push(`room-${roomId}_amount`);
        }
      }
      setAllRealty(list);
      setSelected(s);
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    let a = 0;
    let b = 0;
    selected.forEach((s) => {
      const path = s.split("_");
      allRealty.forEach((item) => {
        if (item.id === path[0]) {
          // eslint-disable-next-line
          switch (path[1]) {
            case "go":
              a += 400000;
              b += 200;
              break;
            case "amount":
              a += item.value;
              b +=
                item.credit != null && item.credit > 0
                  ? item.name === "Комната"
                    ? 200
                    : 400
                  : item.value * 0.01 * 0.05;
              break;
          }
        }
      });
    });
    setSumm(a);
    setExpenses(b);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <>
      <Modal show={show} valign="center">
        <Container1040>
          <Head data-align="space-between" data-direction="row">
            Страхование недвижимости
            <Button
              data-type="blueBg"
              data-form="square"
              data-h="40"
              data-w="40px"
              data-px="20"
              data-mobile="flex"
              onClick={() => setModalRealtyTerms(true)}
            >
              <img src={HelpIcon} alt="" />
            </Button>
            <Button
              data-type="blue"
              data-w="auto"
              data-px="20"
              data-desktop="flex"
              onClick={() => setModalRealtyTerms(true)}
            >
              Условия
            </Button>
          </Head>
          <Body>
            <Row data-align="left">
              <Box>
                <strong>Страхование имущества.</strong>
                Вы получаете защиту недвижимости от стихийных бедствий, пожара,
                затопления, противоправных действий третьих лиц. <br />
                Возможно застраховать недвижимость на полную стоимость или
                частично, при этом Вы получите выплату пропорционально
                застрахованной части.
                <br />
                <br />
                <strong>Страхование гражданской ответственности.</strong>
                Вы можете застраховать свою гражданскую ответственность по
                каждому объекту недвижимости, это защитит Вас от расходов на
                случай вашей вины, например, при затоплении соседей.
              </Box>
            </Row>


            <Row data-align="left" data-mt="20" data-desktop="flex">
              <Box
                data-round="10"
                data-bg="lightBlue"
                data-p="10"
                data-w="100"
                data-direction="row"
              >
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="left"
                  data-mr="5"
                >
                  <Text data-text="17">
                    <strong>Объект страхования</strong>
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="5"
                >
                  <Text data-text="17">
                    <strong>Стоимость актива</strong>
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="30"
                >
                  <Text data-text="17">
                    <strong>Страхование имущества</strong>
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="30"
                >
                  <Text data-text="17">
                    <strong>Гражданская ответственность</strong>
                  </Text>
                </Box>
              </Box>
            </Row>

            {allRealty.map((item, i) => (
              <Row data-align="left" data-mt="10" key={i}>
                <Box
                  data-round="10"
                  data-bg="lightBlue"
                  data-p="10"
                  data-w="100"
                  data-direction="row"
                  data-mobile-direction="column"
                >
                  <Box
                    data-round="10"
                    data-bg="lightGray"
                    data-p="10"
                    data-h="40"
                    data-valign="center"
                    data-w="25"
                    data-mobile-w="100"
                    data-mobile-mx="0"
                    data-mobile-h="50"
                    data-mobile-p="5"
                    data-mobile-align="left"
                    data-halign="left"
                    data-align="left"
                    data-mr="5"
                  >
                    <TextHead data-text="17">Объект страхования</TextHead>
                    <Text data-text="17">{item.name}</Text>
                  </Box>
                  <Box
                    data-round="10"
                    data-bg="lightGray"
                    data-p="10"
                    data-h="40"
                    data-valign="center"
                    data-w="25"
                    data-mobile-w="100"
                    data-mobile-mx="0"
                    data-mobile-mt="10"
                    data-mobile-h="50"
                    data-halign="center"
                    data-mobile-align="left"
                    data-ml="5"
                  >
                    <TextHead data-text="17">Стоимость актива</TextHead>
                    <Text data-text="17">
                      {item.value.toLocaleString("ru-RU")} &#8381;
                    </Text>
                  </Box>
                  <Box
                    data-round="10"
                    data-p="10"
                    data-h="40"
                    data-valign="center"
                    data-w="25"
                    data-mobile-w="100"
                    data-mobile-mx="0"
                    data-mobile-mt="10"
                    data-mobile-h="50"
                    data-mobile-align="left"
                    data-halign="center"
                    data-ml="30"
                    data-direction="row"
                    data-px="0"
                  >
                    <CustomButton
                      id={`${item.id}_amount`}
                      insurance={true}
                      disabled={
                        item.insurance &&
                        item.insurance.amount &&
                        item.insurance.amount.cancelable === false
                      }
                      active={item.insurance && item.insurance.amount}
                      selected={selected}
                      setSelected={setSelected}
                      title="Страхование имущества"
                    />
                  </Box>
                  <Box
                    data-round="10"
                    data-p="10"
                    data-h="40"
                    data-valign="center"
                    data-w="25"
                    data-mobile-w="100"
                    data-mobile-mx="0"
                    data-mobile-mt="10"
                    data-halign="center"
                    data-mobile-align="left"
                    data-ml="30"
                    data-direction="row"
                    data-px="0"
                  >
                    <CustomButton
                      id={`${item.id}_go`}
                      insurance={true}
                      disabled={
                        item.insurance &&
                        item.insurance.go &&
                        item.insurance.go.cancelable === false
                      }
                      active={item.insurance && item.insurance.go}
                      selected={selected}
                      setSelected={setSelected}
                      title="Гражданская ответственность"
                    />
                  </Box>
                </Box>
              </Row>
            ))}

            <Row data-align="left" data-mt="20">
              <Box
                data-round="10"
                data-bg="lightBlue"
                data-p="10"
                data-w="100"
                data-direction="row"
                data-mobile-direction="column"
              >
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="60"
                  data-valign="center"
                  data-w="25"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-align="left"
                  data-halign="center"
                >
                  <Text data-text="17">Страховая сумма</Text>
                  <Text data-text="17">
                    <strong>{summ.toLocaleString("ru-RU")} &#8381;</strong>
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="60"
                  data-ml="10"
                  data-valign="center"
                  data-w="25"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  data-halign="center"
                  data-mobile-align="left"
                >
                  <Text data-text="17">Страховая премия</Text>
                  <Text data-text="17">
                    <strong>0,05%</strong>
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="60"
                  data-ml="10"
                  data-valign="center"
                  data-w="25"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  data-halign="center"
                  data-mobile-align="left"
                >
                  <Text data-text="17">Страховой взнос в месяц</Text>
                  <Text data-text="17">
                    <strong>
                      {expenses.toLocaleString("ru-RU")} &#8381;
                    </strong>
                  </Text>
                </Box>
                <Button
                  data-type="blue"
                  data-h="60"
                  data-ml="10"
                  data-mb="0"
                  data-mr="0"
                  data-w="25"
                  data-mobile-h="40"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  onClick={() =>
                    action({ _insurance: { realty: selected } })
                  }
                >
                  Применить
                </Button>
              </Box>
            </Row>

            <Row
              data-align="center"
              data-mt="40"
              data-mobile-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <Button data-type="green" onClick={hide}>
                {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
              </Button>
            </Row>
          </Body>
        </Container1040>
      </Modal >
      <ModalRealtyTerms
        show={modalRealtyTerms}
        hide={() => setModalRealtyTerms(false)}
      />
    </>
  );
};

const ModalRealtyTerms = ({ show, hide }) => {
  return (
    <Modal show={show} valign="center">
      <Container730>
        <Head data-align="center">Страхование имущества</Head>
        <Body>
          <Row data-align="center" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">
                  <strong>Страховая сумма (стоимость квартиры)</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">
                  <strong>Взносы в месяц</strong>
                </Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="center" data-mt="10" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">1 000 000 &#8381;</Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">500 &#8381;</Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="left" data-mt="10" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">1 500 000 &#8381;</Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">750 &#8381;</Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="center" data-mt="10" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">2 000 000 &#8381;</Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">1000 &#8381;</Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="center" data-mt="20" data-mx="-10">
            <Head data-align="center">Гражданская ответственность</Head>
          </Row>
          <Row data-align="center" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">
                  <strong>Страховая сумма</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">
                  <strong>Взносы в месяц</strong>
                </Text>
              </Box>
            </Box>
          </Row>
          <Row data-align="left" data-mt="10" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">400 000 &#8381;</Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="50"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">200 &#8381;</Text>
              </Box>
            </Box>
          </Row>

          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
          >
            <Button data-type="green" onClick={hide}>
              Назад
            </Button>
          </Row>
        </Body>
      </Container730>
    </Modal>
  );
};

export default Realty;
