import React from "react";

import Modal, {
  Container994,
  Head,
  Body,
  Text,
  TextHead,
  Row,
  Box,
  Button,
} from "../../Modal";

import CustomButton from "../../CustomButton";

const Car = ({ show, hide, action, data, mode }) => {
  const [allCars, setAllCars] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [summ, setSumm] = React.useState(0);
  const [kasko, setKasko] = React.useState(0);
  const [dosago, setDosago] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      let list = [];
      let s = [];

      for (const carId of Object.keys(data.vars.car)) {
        const car = data.vars.car[carId];
        list.push(Object.assign({ id: `car-${carId}` }, car));
        if (car.insurance && car.insurance.kasko) {
          s.push(`car-${carId}_kasko`);
        }
        if (car.insurance && car.insurance.dosago) {
          s.push(`car-${carId}_dosago`);
        }
      }

      setAllCars(list);
      setSelected(s);
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    let a = 0;
    let b = 0;
    let c = 0;
    selected.forEach((s) => {
      const path = s.split("_");
      allCars.forEach((item) => {
        if (item.id === path[0]) {
          // eslint-disable-next-line
          a += item.value;
          switch (item.type) {
            case "sport":
              if (path[1] === "kasko") {
                b += 5000;
              }
              if (path[1] === "dosago") {
                c += 2000;
              }
              break;
            default:
              if (path[1] === "kasko") {
                b += 3000;
              }
              if (path[1] === "dosago") {
                c += 1000;
              }
              break;
          }
        }
      });
    });
    setSumm(a);
    setKasko(b);
    setDosago(c);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Modal show={show} valign="center">
      <Container994>
        <Head data-align="left">Страхование автомобиля</Head>
        <Body>
          <Row data-align="left">
            При страховании автомобиля вы можете приобрести:
            <br />
            - полис КАСКО - страхование от угона, ущерба и гибели автомобиля по
            различным причинам;
            <br />
            - полис ОСАГО – обязательное страхование автогражданской
            ответственности от расходов в случае вашей виды при ДТП (лимит в
            части возмещения вреда имуществу 400 тыс. р., в части вреда жизни
            или здоровью 500 тыс. р.);
            <br />- полис ДОСАГО – добровольное страхование автогражданской
            ответственности от расходов в случае вашей виды при ДТП (увеличение
            лимита ответственности сверх полиса ОСАГО до 1 млн. р., страховая
            премия 200 р. в месяц).
          </Row>

          <Row data-align="left" data-mt="20" data-mx="-10" data-desktop="flex">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-mr="5"
              >
                <Text data-text="17">
                  <strong>Объект страхования</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-ml="5"
              >
                <Text data-text="17">
                  <strong>Стоимость актива</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-ml="30"
              >
                <Text data-text="17">
                  <strong>КАСКО</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="40"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-ml="30"
              >
                <Text data-text="17">
                  <strong>ДОСАГО</strong>
                </Text>
              </Box>
            </Box>
          </Row>
          {allCars.map((item, i) => (
            <Row data-align="left" data-mt="10" data-mx="-10" key={i}>
              <Box
                data-round="10"
                data-bg="lightBlue"
                data-p="10"
                data-w="100"
                data-direction="row"
                data-mobile-direction="column"
              >
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-mr="5"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-h="50"
                  data-mobile-p="5"
                  data-mobile-align="left"
                >
                  <TextHead data-text="17">Объект страхования</TextHead>
                  <Text data-text="17">{item.name}</Text>
                </Box>
                <Box
                  data-round="10"
                  data-bg="lightGray"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="5"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-h="50"
                  data-mobile-p="5"
                  data-mobile-mt="10"
                  data-mobile-align="left"
                >
                  <TextHead data-text="17">Стоимость актива</TextHead>
                  <Text data-text="17">
                    {item.value.toLocaleString("ru-RU")} &#8381;
                  </Text>
                </Box>
                <Box
                  data-round="10"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="30"
                  data-direction="row"
                  data-px="0"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  data-mobile-h="50"
                >
                  <CustomButton
                    id={`${item.id}_kasko`}
                    disabled={
                      item.insurance &&
                      item.insurance.kasko &&
                      item.insurance.kasko.cancelable === false
                    }
                    insurance={true}
                    active={item.insurance && item.insurance.kasko}
                    selected={selected}
                    setSelected={setSelected}
                    title="КАСКО"
                  />
                </Box>
                <Box
                  data-round="10"
                  data-p="10"
                  data-h="40"
                  data-valign="center"
                  data-w="25"
                  data-halign="center"
                  data-ml="30"
                  data-direction="row"
                  data-px="0"
                  data-mobile-w="100"
                  data-mobile-mx="0"
                  data-mobile-mt="10"
                  data-mobile-h="50"
                >
                  <CustomButton
                    id={`${item.id}_dosago`}
                    insurance={true}
                    disabled={
                      item.insurance &&
                      item.insurance.dosago &&
                      item.insurance.dosago.cancelable === false
                    }
                    active={item.insurance && item.insurance.dosago}
                    selected={selected}
                    setSelected={setSelected}
                    title="ДОСАГО"
                  />
                </Box>
              </Box>
            </Row>
          ))}
          <Row data-align="left" data-mt="20" data-mx="-10">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-align="left"
              >
                <Text data-text="17">Страховая сумма</Text>
                <Text data-text="17">
                  <strong>{summ.toLocaleString("ru-RU")} &#8381;</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-ml="10"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-mt="10"
                data-mobile-align="left"
              >
                <Text data-text="17">КАСКО в месяц</Text>
                <Text data-text="17">
                  <strong>{kasko.toLocaleString("ru-RU")} &#8381;</strong>
                </Text>
              </Box>
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-ml="10"
                data-valign="center"
                data-w="25"
                data-halign="center"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-mt="10"
                data-mobile-align="left"
              >
                <Text data-text="17">ДОСАГО в месяц</Text>
                <Text data-text="17">
                  <strong>{dosago.toLocaleString("ru-RU")} &#8381;</strong>
                </Text>
              </Box>
              <Button
                data-type="blue"
                data-h="60"
                data-ml="10"
                data-mb="0"
                data-mr="0"
                data-w="25"
                data-mobile-h="40"
                data-mobile-w="100"
                data-mobile-mx="0"
                data-mobile-mt="10"
                onClick={() => action({ _insurance: { car: selected } })}
              >
                Применить
              </Button>
            </Box>
          </Row>

          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-mobile-mt="10"
          >
            <Button data-type="green" onClick={hide}>
              {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
            </Button>
          </Row>
        </Body>
      </Container994>
    </Modal>
  );
};

export default Car;
