import React from "react";
import styled, { keyframes } from "styled-components";

import LoaderImg from "../img/LoaderIcon.svg";
import MainWrap from "../view/MainWrap";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrap = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Loader = styled.img`
  animation: ${rotate} 2s linear infinite;
  width: 50px;
  height: 50px;
`;

const Loading = () => (
  <MainWrap panel="loading">
    <Wrap>
      <Loader src={LoaderImg} alt="Loader" />
    </Wrap>
  </MainWrap>
);

export default Loading;
