import React from "react";
import styled from "styled-components";

import Modal, { XLContainer, Button } from "../Modal";

import RepayCreditModal from "../modals/RepayCreditModal";

import ComputingIcon from "../../img/ComputingIcon.svg";
//import CloseIcon from "../../img/CloseIcon.svg";

const Body = styled.div`
  margin-top: 30px;
  /*overflow-x: auto;*/
`;

const BodyContainer = styled.div`
  /*min-width: 1000px;*/
  margin-bottom: 10px;
`;

const Col1 = styled.div`
  width: 50%;
  display: flex;
  &[data-col] {
    flex-direction: column;
  }
  align-items: flex-start;
  & + & {
    padding-left: 10px;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Col2 = styled.div`
  width: 25%;
  position: relative;
  padding-left: 10px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Col3 = styled.div`
  width: 25%;
  padding-left: 10px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Head = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
    & > ${Col1} {
      width: 100%;
    }
    & > ${Col2} {
      @media (max-width: 900px) {
        display: flex;
        width: 100%;
        padding-left: 0;
        padding-top: 25px;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #293a47;
  margin-top: -3px;
`;

const Switch = styled.div`
  display: flex;
  margin-top: 3px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const RadioButton = styled.div`
  width: 90px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #293a47;
  background: #e8ebf3;
  cursor: pointer;
  font-size: 17px;
  line-height: 22px;
  &[data-active="true"] {
    font-weight: 700;
    color: #fdfdfd;
    background: #286fb7;
    &[data-mode="passive"] {
      background: #be1622;
    }
  }
  border-radius: 10px;
  & + & {
    margin-left: 10px;
  }
  @media (max-width: 900px) {
    width: 50%;
  }
`;

const ResultIconWrap = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  background: #eaf1f8;
  border-radius: 10px;
  left: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    left: 0;
    position: relative;
  }
`;

const ResultIcon = styled.img``;

const ResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: #eaf1f8;
  border-radius: 10px;
  height: 60px;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media (max-width: 900px) {
    flex: 1 1 auto;
    margin-left: 10px;
  }
`;

const ResultName = styled.div`
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #286fb7;
`;

const ResultValue = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #286fb7;
`;

const CloseButton = styled.div`
  border-radius: 10px;
  border: 1px solid #009769;
  height: 60px;
  flex: 1 1 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #009769;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    height: 44px;
  }
`;

const ColIcon = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background: #edeff5;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  border-radius: 10px;
  margin-right: 10px;
  &:empty {
    opacity: 0;
  }
  &[data-big] {
    height: 60px;
  }
  &[data-blue] {
    background: #286fb7;
    color: #fff;
  }
  &[data-disabled] {
    opacity: 0.3;
  }
`;

const ColWrap = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  color: #293a47;
  border-radius: 10px;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  background: #edeff5;
  padding-left: 30px;
  /*white-space: nowrap;*/
  &[data-head] {
    font-weight: 700;
  }
  & [data-text] {
    font-weight: 400;
  }
  &[data-small] {
    flex: 0 1 auto;
    width: 123px;
    justify-content: center;
    padding-left: 0;
    text-align: сутеук;
  }
  &[data-big] {
    min-height: 60px;
  }
  &[data-expand] {
    height: 100%;
    justify-content: center;
    padding-left: 0;
  }
  & + & {
    margin-left: 10px;
  }
  &[data-right] {
    padding-right: 30px;
    padding-left: 0;
    justify-content: flex-end;
  }
  @media (max-width: 900px) {
    padding-left: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: 10px;
  }
  &[data-hoverable] {
    flex-direction: column;
    & ${Button}:not([data-mobile-button="true"]) {
      display: none;
    }
    & ${ColIcon} {
      opacity: 0.3;
    }
  }
  @media (min-width: 900px) {
    &[data-hoverable] {
      flex-direction: row;
    }
    &[data-hoverable]:hover {
      & ${ColWrap} {
        background: #dfe9f4;
      }
      & ${Button} {
        display: block;
      }
      & [data-hover-hidden] {
        display: none;
      }
      & ${ColIcon} {
        opacity: 1;
      }
    }
  }
`;

function Register({
  show,
  hide,
  data,
  toggleAction,
  registerMode,
  setRegisterMode,
}) {
  const [skills, setSkills] = React.useState([]);
  const [repayCreditModal, setRepayCreditModal] = React.useState(false);
  const [repayCreditData, setRepayCreditData] = React.useState({});

  const repayCredit = (type, id) => {
    // eslint-disable-next-line
    switch (type) {
      case "repayCreditCard":
        setRepayCreditModal(true);
        setRepayCreditData({
          type: type,
          summ: data.vars.creditCardCost,
          expenses: data.vars.creditCardExpenses,
          rate: data.vars.creditCardRate * 100,
          cash: data.vars.cash,
        });
        break;
      case "repayConsumerCredit":
        setRepayCreditModal(true);
        setRepayCreditData({
          type: type,
          summ: data.vars.consumerCreditCost,
          expenses: data.vars.consumerCreditExpenses,
          rate: data.vars.consumerCreditRate * 100,
          cash: data.vars.cash,
        });
        break;
      case "repayFlatCredit":
        setRepayCreditModal(true);
        setRepayCreditData({
          type: type,
          id: id,
          summ: data.vars.flat[id].credit,
          expenses: data.vars.flat[id].expenses,
          rate: data.vars.creditRate * 100,
          cash: data.vars.cash,
        });
        break;
      case "repayRoomCredit":
        setRepayCreditModal(true);
        setRepayCreditData({
          type: type,
          id: id,
          summ: data.vars.room[id].credit,
          expenses: data.vars.room[id].expenses,
          rate: data.vars.creditRate * 100,
          cash: data.vars.cash,
        });
        break;
      case "repayCarCredit":
        setRepayCreditModal(true);
        setRepayCreditData({
          type: type,
          id: id,
          summ: data.vars.car[id].credit,
          expenses: data.vars.car[id].expenses,
          rate: data.vars.creditRate * 100,
          cash: data.vars.cash,
        });
        break;
    }
  };

  React.useEffect(() => {
    if (data) {
      let s = [];
      if (data.vars.profDev) {
        s.push({ name: "Профессиональное развитие" });
      }
      if (data.vars.newProfession) {
        s.push({ name: "Новая профессия" });
      }
      if (data.vars.netMarketing) {
        s.push({ name: "Интернет-маркетинг" });
      }
      setSkills(s);
    }
  }, [data]);

  return (
    <>
      {data && (
        <>
          <Modal show={show}>
            <XLContainer>
              <Head>
                <Col1 data-col>
                  <Title>Игровая ведомость</Title>
                  <Switch>
                    <RadioButton
                      data-active={registerMode === "active" ? "true" : "false"}
                      onClick={() => setRegisterMode("active")}
                    >
                      Активы
                    </RadioButton>
                    <RadioButton
                      data-mode="passive"
                      data-active={
                        registerMode === "passive" ? "true" : "false"
                      }
                      onClick={() => setRegisterMode("passive")}
                    >
                      Пассивы
                    </RadioButton>
                  </Switch>
                </Col1>
                <Col2>
                  <ResultIconWrap>
                    <ResultIcon src={ComputingIcon} alt="Computing icon" />
                  </ResultIconWrap>
                  <ResultWrap>
                    <ResultName>Фин.результат</ResultName>
                    <ResultValue>
                      {data.vars.finResult.toLocaleString("ru-RU")} &#8381;
                    </ResultValue>
                  </ResultWrap>
                </Col2>
                <Col3 data-desktop="block">
                  <CloseButton onClick={hide}>К игре</CloseButton>
                </Col3>
              </Head>
              <Body>
                <BodyContainer>
                  {registerMode === "active" && (
                    <>
                      <Row>
                        <Col1>
                          <ColIcon>№</ColIcon>
                          <ColWrap data-head>Вид актива / доход</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head>Стоимость актива</ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head>Доход</ColWrap>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon>1</ColIcon>
                          <ColWrap data-head>Оплата труда</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap></ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allWage.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon data-desktop="block"></ColIcon>
                          <ColWrap data-mobile-p="10">
                            Зарплата
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                            >
                              <div>Доход:</div>
                              <div>
                                {data.vars.salaryResult.toLocaleString("ru-RU")}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap></ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-right>
                            {data.vars.salaryResult.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      {data.vars.partTimeJob > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              Подработка
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Доход:</div>
                                <div>
                                  {data.vars.partTimeJob.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.partTimeJob.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}
                      <Row data-mobile="flex">
                        <Col1>
                          <ColIcon>&sum;</ColIcon>
                          <ColWrap data-head data-mobile-p="10">
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                            >
                              <div>ИТОГО:</div>
                              <div>
                                {data.vars.allWage.toLocaleString("ru-RU")}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon>2</ColIcon>
                          <ColWrap data-head>
                            Активы (включая инвестиции)
                          </ColWrap>
                          <ColWrap data-head data-small data-desktop="flex">
                            % в мес.
                          </ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head data-right>
                            {data.vars.allActiveCost.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allActivesIncome.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon data-desktop="block"></ColIcon>
                          <ColWrap data-mobile-p="10">
                            Денежные средства (наличные)
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                            >
                              <div>Стоимость актива:</div>
                              <div>
                                {data.vars.cash.toLocaleString("ru-RU")}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-right>
                            {data.vars.cash.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap></ColWrap>
                        </Col3>
                      </Row>
                      {data.vars.startFlat && (
                        <Row>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {data.vars.startFlat.name}
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>
                                  {data.vars.startFlat.value.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.startFlat.value.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap></ColWrap>
                          </Col3>
                        </Row>
                      )}
                      {Object.values(data.vars.flat).map((flat, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {flat.name}
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>{flat.value.toLocaleString("ru-RU")}</div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Доход:</div>
                                <div>{flat.income.toLocaleString("ru-RU")}</div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {flat.value.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {flat.income.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      {Object.values(data.vars.room).map((room, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {room.name}
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>{room.value.toLocaleString("ru-RU")}</div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Доход:</div>
                                <div>{room.income.toLocaleString("ru-RU")}</div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {room.value.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {room.income.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      {Object.values(data.vars.car).map((car, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {car.name}
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>{car.value.toLocaleString("ru-RU")}</div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Доход:</div>
                                <div>
                                  {car.income > 0 &&
                                    car.income.toLocaleString("ru-RU")}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {car.value.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {car.income > 0 &&
                                car.income.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      {Object.values(data.vars.onlineBusiness).map(
                        (onlineBusiness, i) => (
                          <Row key={i}>
                            <Col1>
                              <ColIcon data-desktop="block"></ColIcon>
                              <ColWrap data-mobile-p="10">
                                {onlineBusiness.name}
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Стоимость актива:</div>
                                  <div>
                                    {onlineBusiness.value.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </div>
                                </div>
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Доход:</div>
                                  <div>
                                    {onlineBusiness.income.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </div>
                                </div>
                              </ColWrap>
                            </Col1>
                            <Col2>
                              <ColWrap data-right>
                                {onlineBusiness.value.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col2>
                            <Col3>
                              <ColWrap data-right>
                                {onlineBusiness.income.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col3>
                          </Row>
                        )
                      )}
                      {Object.values(data.vars.offlineBusiness).map(
                        (offlineBusiness, i) => (
                          <Row key={i}>
                            <Col1>
                              <ColIcon data-desktop="block"></ColIcon>
                              <ColWrap data-mobile-p="10">
                                {offlineBusiness.name}{" "}
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Стоимость актива:</div>
                                  <div>
                                    {offlineBusiness.value.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </div>
                                </div>
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Доход:</div>
                                  <div>
                                    {offlineBusiness.income.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </div>
                                </div>
                              </ColWrap>
                            </Col1>
                            <Col2>
                              <ColWrap data-right>
                                {offlineBusiness.value.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col2>
                            <Col3>
                              <ColWrap data-right>
                                {offlineBusiness.income.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col3>
                          </Row>
                        )
                      )}
                      {Object.values(data.vars.partnership).map(
                        (partnership, i) => (
                          <Row key={i}>
                            <Col1>
                              <ColIcon data-desktop="block"></ColIcon>
                              <ColWrap data-mobile-p="10">
                                {partnership.name}
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Стоимость актива:</div>
                                  <div>
                                    {partnership.value.toLocaleString("ru-RU")}
                                  </div>
                                </div>
                                <div
                                  data-mobile="flex"
                                  data-mobile-w="100"
                                  data-align="space-between"
                                >
                                  <div>Доход:</div>
                                  <div>
                                    {partnership.income.toLocaleString("ru-RU")}
                                  </div>
                                </div>
                              </ColWrap>
                            </Col1>
                            <Col2>
                              <ColWrap data-right>
                                {partnership.value.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col2>
                            <Col3>
                              <ColWrap data-right>
                                {partnership.income.toLocaleString("ru-RU")}
                              </ColWrap>
                            </Col3>
                          </Row>
                        )
                      )}
                      {Object.values(data.vars.book).map((book, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              Авторское право (книга)
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Доход:</div>
                                <div>{book.income.toLocaleString("ru-RU")}</div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {book.income.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      {Object.values(data.vars.stuff).map((stuff, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {stuff.name}
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>{stuff.value.toLocaleString("ru-RU")}</div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {stuff.value.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap></ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      {data.vars.buyLifeInsuranceAmount > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              ИСЖ (программа "{data.vars.buyLifeInsuranceType}")
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>
                                  {data.vars.buyLifeInsuranceAmount.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>% в мес.:</div>
                                <div>0,5%</div>
                              </div>
                            </ColWrap>
                            <ColWrap data-small data-desktop="flex">
                              0,5%
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.buyLifeInsuranceAmount.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap></ColWrap>
                          </Col3>
                        </Row>
                      )}
                      {data.vars.deposit > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-desktop="block"></ColIcon>
                            <ColWrap data-mobile-p="10">
                              Депозит
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>Стоимость актива:</div>
                                <div>
                                  {data.vars.deposit.toLocaleString("ru-RU")}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                              >
                                <div>% в мес.:</div>
                                <div>0,5%</div>
                              </div>
                            </ColWrap>
                            <ColWrap data-small data-desktop="flex">
                              0,5%
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.deposit.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.depositIncome.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}
                      <Row data-mobile="flex">
                        <Col1>
                          <ColIcon>&sum;</ColIcon>
                          <ColWrap data-head data-mobile-p="10">
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                            >
                              <div>ИТОГО:</div>
                              <div>
                                {data.vars.allActiveCost.toLocaleString(
                                  "ru-RU"
                                )}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                      </Row>
                      <Row data-desktop="flex">
                        <Col1>
                          <ColIcon>&sum;</ColIcon>
                          <ColWrap data-head>ИТОГО</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head data-right>
                            {data.vars.allActiveCost.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allIncome.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      {skills.length > 0 && (
                        <Row>
                          <Col1 data-w="100">
                            <ColIcon data-big data-bg="lightGreen">
                              №
                            </ColIcon>
                            <ColWrap data-big data-head data-bg="lightGreen">
                              Навыки, полученные в игре
                            </ColWrap>
                          </Col1>
                        </Row>
                      )}
                      {skills.map((skill, i) => (
                        <Row>
                          <Col1 data-direction="column" data-w="100">
                            <Row data-w="100">
                              <ColIcon data-bg="lightGreen">{i + 1}</ColIcon>
                              <ColWrap data-head data-bg="lightGreen">
                                {skill.name}
                              </ColWrap>
                            </Row>
                          </Col1>
                        </Row>
                      ))}
                      <Row data-mobile-direction="column">
                        <Col1>
                          <ColWrap
                            data-big
                            data-head
                            data-bg="lightGreen"
                            data-mobile-w="100"
                            data-mobile-align="center"
                          >
                            Чистые активы <br data-desktop="block" />
                            (Пассивы – Активы)
                            <div data-mobile-w="100" data-mobile="block">
                              {data.vars.netAssets.toLocaleString("ru-RU")}
                            </div>
                          </ColWrap>
                        </Col1>
                        <Col1 data-mobile-pl="0" data-mobile-pt="10">
                          <ColWrap
                            data-big
                            data-head
                            data-bg="lightGreen"
                            data-mobile-w="100"
                            data-mobile-align="center"
                          >
                            Мес. сбережения <br data-desktop="block" />
                            (Доходы – Расходы)
                            <div data-mobile-w="100" data-mobile="block">
                              {data.vars.monthlySavings.toLocaleString("ru-RU")}
                            </div>
                          </ColWrap>
                        </Col1>
                      </Row>
                      <Row data-desktop="flex">
                        <Col1>
                          <ColWrap
                            data-head
                            data-expand
                            data-bg="lightGreen"
                            data-h="40"
                            data-right
                          >
                            {data.vars.netAssets.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col1>
                        <Col1>
                          <ColWrap
                            data-head
                            data-expand
                            data-bg="lightGreen"
                            data-h="40"
                            data-right
                          >
                            {data.vars.monthlySavings.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col1>
                      </Row>
                    </>
                  )}
                  {registerMode === "passive" && (
                    <>
                      <Row>
                        <Col1>
                          <ColIcon>№</ColIcon>
                          <ColWrap data-head>Вид пассива / расход</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head>Стоимость пассива</ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head>Расход</ColWrap>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon>1</ColIcon>
                          <ColWrap data-head data-mobile-p="10">
                            Текущие расходы
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                              data-text
                            >
                              <div>Расход:</div>
                              <div>
                                {data.vars.lifeExpenses.toLocaleString("ru-RU")}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head></ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.lifeExpenses.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      <Row>
                        <Col1>
                          <ColIcon>2</ColIcon>
                          <ColWrap data-head>Пассивы</ColWrap>
                          <ColWrap data-head data-small data-desktop="flex">
                            % в мес.
                          </ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head data-right>
                            {data.vars.allPassivesCost.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allPassivesExpenses.toLocaleString(
                              "ru-RU"
                            )}
                          </ColWrap>
                        </Col3>
                      </Row>
                      {data.vars.creditCardCost > 0 && (
                        <Row data-hoverable>
                          <Col1>
                            <ColIcon data-big data-blue data-desktop="flex">
                              &#8381;
                            </ColIcon>
                            <Button
                              data-type="blueBg"
                              data-h="60"
                              data-flex="grow"
                              data-m="0"
                              data-max-w="none"
                              onClick={() => repayCredit("repayCreditCard")}
                            >
                              Погасить
                            </Button>
                            <ColWrap
                              data-big
                              data-hover-hidden
                              data-mobile-p="10"
                            >
                              Кредитная карта <br data-desktop="block" />
                              (лимит 200 000)
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Стоимость пассива:</div>
                                <div>
                                  {data.vars.creditCardCost.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.creditCardExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>% в мес.:</div>
                                <div>2%</div>
                              </div>
                            </ColWrap>
                            <ColWrap data-big data-small data-desktop="flex" >
                              2%
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-big data-right>
                              {data.vars.creditCardCost.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-big data-right>
                              {data.vars.creditCardExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                          <Button
                            data-mobile-button="true"
                            data-mobile="flex"
                            data-type="blueBg"
                            data-h="40"
                            data-flex="grow"
                            data-mb="0"
                            data-mt="10"
                            data-ml="0"
                            data-mr="0"
                            data-max-w="none"
                            onClick={() => repayCredit("repayCreditCard")}
                          >
                            Погасить
                          </Button>
                        </Row>
                      )}
                      {data.vars.consumerCreditCost > 0 && (
                        <Row data-hoverable>
                          <Col1>
                            <ColIcon data-big data-blue data-desktop="flex">
                              &#8381;
                            </ColIcon>
                            <Button
                              data-type="blueBg"
                              data-h="60"
                              data-flex="grow"
                              data-m="0"
                              data-max-w="none"
                              onClick={() => repayCredit("repayConsumerCredit")}
                            >
                              Погасить
                            </Button>
                            <ColWrap
                              data-big
                              data-hover-hidden
                              data-mobile-p="10"
                            >
                              Потребительский кредит <br data-descktop="flex" />
                              (страхование кредита 5%)
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Стоимость пассива:</div>
                                <div>
                                  {data.vars.consumerCreditCost.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.consumerCreditExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>% в мес.:</div>
                                <div>2%</div>
                              </div>
                            </ColWrap>
                            <ColWrap data-big data-small data-desktop="flex">
                              2%
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-big data-right>
                              {data.vars.consumerCreditCost.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-big data-right>
                              {data.vars.consumerCreditExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                          <Button
                            data-mobile-button="true"
                            data-mobile="flex"
                            data-type="blueBg"
                            data-h="40"
                            data-flex="grow"
                            data-mb="0"
                            data-mt="10"
                            data-ml="0"
                            data-mr="0"
                            data-max-w="none"
                            onClick={() => repayCredit("repayConsumerCredit")}
                          >
                            Погасить
                          </Button>
                        </Row>
                      )}
                      {Object.keys(data.vars.flat).map((flatId, i) => (
                        <React.Fragment key={i}>
                          {data.vars.flat[flatId].credit > 0 && (
                            <Row data-hoverable>
                              <Col1>
                                <ColIcon data-big data-blue data-desktop="flex">
                                  &#8381;
                                </ColIcon>
                                <Button
                                  data-type="blueBg"
                                  data-h="60"
                                  data-flex="grow"
                                  data-m="0"
                                  data-max-w="none"
                                  onClick={() =>
                                    repayCredit("repayFlatCredit", flatId)
                                  }
                                >
                                  Погасить
                                </Button>
                                <ColWrap
                                  data-big
                                  data-hover-hidden
                                  data-mobile-p="10"
                                >
                                  Ипотечный кредит <br data-descktop="flex" />(
                                  {data.vars.flat[flatId].name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Стоимость пассива:</div>
                                    <div>
                                      {data.vars.flat[
                                        flatId
                                      ].credit.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {data.vars.flat[
                                        flatId
                                      ].expenses.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>% в мес.:</div>
                                    <div>0,8%</div>
                                  </div>
                                </ColWrap>
                                <ColWrap data-big data-small data-desktop="flex">
                                  0,8%
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-big data-right>
                                  {data.vars.flat[flatId].credit.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-big data-right>
                                  {data.vars.flat[
                                    flatId
                                  ].expenses.toLocaleString("ru-RU")}
                                </ColWrap>
                              </Col3>
                              <Button
                                data-mobile-button="true"
                                data-mobile="flex"
                                data-type="blueBg"
                                data-h="40"
                                data-flex="grow"
                                data-mb="0"
                                data-mt="10"
                                data-ml="0"
                                data-mr="0"
                                data-max-w="none"
                                onClick={() =>
                                  repayCredit("repayFlatCredit", flatId)
                                }
                              >
                                Погасить
                              </Button>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.keys(data.vars.room).map((roomId, i) => (
                        <React.Fragment key={i}>
                          {data.vars.room[roomId].credit > 0 && (
                            <Row data-hoverable>
                              <Col1>
                                <ColIcon data-big data-blue data-desktop="flex">
                                  &#8381;
                                </ColIcon>
                                <Button
                                  data-type="blueBg"
                                  data-h="60"
                                  data-flex="grow"
                                  data-m="0"
                                  data-max-w="none"
                                  onClick={() =>
                                    repayCredit("repayRoomCredit", roomId)
                                  }
                                >
                                  Погасить
                                </Button>
                                <ColWrap
                                  data-big
                                  data-hover-hidden
                                  data-mobile-p="10"
                                >
                                  Ипотечный кредит <br data-mobile="block" />(
                                  {data.vars.room[roomId].name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Стоимость пассива:</div>
                                    <div>
                                      {data.vars.room[
                                        roomId
                                      ].credit.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {data.vars.room[
                                        roomId
                                      ].expenses.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>% в мес.:</div>
                                    <div>0,8%</div>
                                  </div>
                                </ColWrap>
                                <ColWrap data-big data-small data-desktop="flex">
                                  0,8%
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-big data-right>
                                  {data.vars.room[roomId].credit.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-big data-right>
                                  {data.vars.room[
                                    roomId
                                  ].expenses.toLocaleString("ru-RU")}
                                </ColWrap>
                              </Col3>
                              <Button
                                data-mobile-button="true"
                                data-mobile="flex"
                                data-type="blueBg"
                                data-h="40"
                                data-flex="grow"
                                data-mb="0"
                                data-mt="10"
                                data-ml="0"
                                data-mr="0"
                                data-max-w="none"
                                onClick={() =>
                                  repayCredit("repayRoomCredit", roomId)
                                }
                              >
                                Погасить
                              </Button>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.keys(data.vars.car).map((carId, i) => (
                        <React.Fragment key={i}>
                          {data.vars.car[carId].credit > 0 && (
                            <Row data-hoverable>
                              <Col1>
                                <ColIcon data-big data-blue data-desktop="flex">
                                  &#8381;
                                </ColIcon>
                                <Button
                                  data-type="blueBg"
                                  data-h="60"
                                  data-flex="grow"
                                  data-m="0"
                                  data-max-w="none"
                                  onClick={() =>
                                    repayCredit("repayCarCredit", carId)
                                  }
                                >
                                  Погасить
                                </Button>
                                <ColWrap
                                  data-big
                                  data-hover-hidden
                                  data-mobile-p="10"
                                >
                                  Автокредит <br data-desktop="block" />(
                                  {data.vars.car[carId].name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Стоимость пассива:</div>
                                    <div>
                                      {data.vars.car[
                                        carId
                                      ].credit.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {data.vars.car[
                                        carId
                                      ].expenses.toLocaleString("ru-RU")}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>% в мес.:</div>
                                    <div>0,8%</div>
                                  </div>
                                </ColWrap>
                                <ColWrap data-big data-small data-desktop="flex">
                                  0,8%
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-big data-right>
                                  {data.vars.car[carId].credit.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-big data-right>
                                  {data.vars.car[carId].expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                              <Button
                                data-mobile-button="true"
                                data-mobile="flex"
                                data-type="blueBg"
                                data-h="40"
                                data-flex="grow"
                                data-mb="0"
                                data-mt="10"
                                data-ml="0"
                                data-mr="0"
                                data-max-w="none"
                                onClick={() =>
                                  repayCredit("repayCarCredit", carId)
                                }
                              >
                                Погасить
                              </Button>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      <Row>
                        <Col1>
                          <ColIcon>3</ColIcon>
                          <ColWrap data-head>Страховые взносы</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head>Страховая сумма</ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head>Расход</ColWrap>
                        </Col3>
                      </Row>
                      {data.vars.lifeInsuranceExpenses > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              Страхование жизни
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.lifeInsuranceExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.lifeInsuranceExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}
                      {data.vars.dmsInsuranceExpenses > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              ДМС
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.dmsInsuranceExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.dmsInsuranceExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}
                      {data.vars.nsInsuranceExpenses > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              НС
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.nsInsuranceExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.nsInsuranceExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}

                      {data.vars.startFlat.insurance.amount !== undefined && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              Недвижимость ({data.vars.startFlat.name})
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Страховая сумма:</div>
                                <div>
                                  {data.vars.startFlat.insurance.amount.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.startFlat.insurance.amount.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.startFlat.insurance.amount.summ.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.startFlat.insurance.amount.expenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}

                      {Object.values(data.vars.flat).map((flat, i) => (
                        <React.Fragment key={i}>
                          {flat.insurance.amount !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Недвижимость ({flat.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {flat.insurance.amount.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {flat.insurance.amount.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {flat.insurance.amount.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {flat.insurance.amount.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.room).map((room, i) => (
                        <React.Fragment key={i}>
                          {room.insurance.amount !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Недвижимость ({room.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {room.insurance.amount.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {room.insurance.amount.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {room.insurance.amount.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {room.insurance.amount.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}

                      {data.vars.startFlat.insurance.go !== undefined && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              Гражданская ответственность (
                              {data.vars.startFlat.name})
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Страховая сумма:</div>
                                <div>
                                  {data.vars.startFlat.insurance.go.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.startFlat.insurance.go.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.startFlat.insurance.go.summ.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.startFlat.insurance.go.expenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}

                      {Object.values(data.vars.flat).map((flat, i) => (
                        <React.Fragment key={i}>
                          {flat.insurance.go !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">
  
                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Гражданская ответственность ({flat.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {flat.insurance.go.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {flat.insurance.go.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {flat.insurance.go.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {flat.insurance.go.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.room).map((room, i) => (
                        <React.Fragment key={i}>
                          {room.insurance.go !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Гражданская ответственность ({room.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {room.insurance.go.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {room.insurance.go.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {room.insurance.go.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {room.insurance.go.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.flat).map((flat, i) => (
                        <React.Fragment key={i}>
                          {flat.insurance.mortgage !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Ипотека ({flat.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {flat.insurance.mortgage.amount.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {flat.insurance.mortgage.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {flat.insurance.mortgage.amount.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {flat.insurance.mortgage.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.room).map((room, i) => (
                        <React.Fragment key={i}>
                          {room.insurance.mortgage !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  Ипотека ({room.name})
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {room.insurance.mortgage.amount.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {room.insurance.mortgage.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {room.insurance.mortgage.amount.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {room.insurance.mortgage.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}

                      {Object.values(data.vars.car).map((car, i) => (
                        <React.Fragment key={i}>
                          {car.insurance.osago !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  {car.name} ОСАГО
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {car.insurance.osago.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap></ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {car.insurance.osago.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.car).map((car, i) => (
                        <React.Fragment key={i}>
                          {car.insurance.dosago !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  {car.name} ДОСАГО
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {car.insurance.dosago.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {car.insurance.dosago.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {car.insurance.dosago.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {car.insurance.dosago.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}
                      {Object.values(data.vars.car).map((car, i) => (
                        <React.Fragment key={i}>
                          {car.insurance.kasko !== undefined && (
                            <Row>
                              <Col1>
                                <ColIcon data-blue data-desktop="flex">

                                </ColIcon>
                                <ColWrap data-mobile-p="10">
                                  {car.name} КАСКО
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Страховая сумма:</div>
                                    <div>
                                      {car.insurance.kasko.summ.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    data-mobile="flex"
                                    data-mobile-w="100"
                                    data-align="space-between"
                                    data-text
                                  >
                                    <div>Расход:</div>
                                    <div>
                                      {car.insurance.kasko.expenses.toLocaleString(
                                        "ru-RU"
                                      )}
                                    </div>
                                  </div>
                                </ColWrap>
                              </Col1>
                              <Col2>
                                <ColWrap data-right>
                                  {car.insurance.kasko.summ.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col2>
                              <Col3>
                                <ColWrap data-right>
                                  {car.insurance.kasko.expenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </ColWrap>
                              </Col3>
                            </Row>
                          )}
                        </React.Fragment>
                      ))}

                      {Object.values(data.vars.onlineBusiness).map(
                        (onlineBusiness, i) => (
                          <React.Fragment key={i}>
                            {onlineBusiness.insurance.expenses > 0 && (
                              <Row>
                                <Col1>
                                  <ColIcon data-blue data-desktop="flex">

                                  </ColIcon>
                                  <ColWrap data-mobile-p="10">
                                    Страхование бизнеса {onlineBusiness.name}
                                    <div
                                      data-mobile="flex"
                                      data-mobile-w="100"
                                      data-align="space-between"
                                      data-text
                                    >
                                      <div>Страховая сумма:</div>
                                      <div>
                                        {onlineBusiness.insurance.amount.toLocaleString(
                                          "ru-RU"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      data-mobile="flex"
                                      data-mobile-w="100"
                                      data-align="space-between"
                                      data-text
                                    >
                                      <div>Расход:</div>
                                      <div>
                                        {onlineBusiness.insurance.expenses.toLocaleString(
                                          "ru-RU"
                                        )}
                                      </div>
                                    </div>
                                  </ColWrap>
                                </Col1>
                                <Col2>
                                  <ColWrap data-right>
                                    {onlineBusiness.insurance.amount.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </ColWrap>
                                </Col2>
                                <Col3>
                                  <ColWrap data-right>
                                    {onlineBusiness.insurance.expenses.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </ColWrap>
                                </Col3>
                              </Row>
                            )}
                          </React.Fragment>
                        )
                      )}

                      {Object.values(data.vars.offlineBusiness).map(
                        (offlineBusiness, i) => (
                          <React.Fragment key={i}>
                            {offlineBusiness.insurance.expenses > 0 && (
                              <Row>
                                <Col1>
                                  <ColIcon data-blue data-desktop="flex">

                                  </ColIcon>
                                  <ColWrap data-mobile-p="10">
                                    Страхование бизнеса {offlineBusiness.name}
                                    <div
                                      data-mobile="flex"
                                      data-mobile-w="100"
                                      data-align="space-between"
                                      data-text
                                    >
                                      <div>Страховая сумма:</div>
                                      <div>
                                        {offlineBusiness.insurance.amount.toLocaleString(
                                          "ru-RU"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      data-mobile="flex"
                                      data-mobile-w="100"
                                      data-align="space-between"
                                      data-text
                                    >
                                      <div>Расход:</div>
                                      <div>
                                        {offlineBusiness.insurance.expenses.toLocaleString(
                                          "ru-RU"
                                        )}
                                      </div>
                                    </div>
                                  </ColWrap>
                                </Col1>
                                <Col2>
                                  <ColWrap data-right>
                                    {offlineBusiness.insurance.amount.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </ColWrap>
                                </Col2>
                                <Col3>
                                  <ColWrap data-right>
                                    {offlineBusiness.insurance.expenses.toLocaleString(
                                      "ru-RU"
                                    )}
                                  </ColWrap>
                                </Col3>
                              </Row>
                            )}
                          </React.Fragment>
                        )
                      )}
                      {false && data.vars.consumerCreditInsuranceExpense > 0 && (
                        <Row>
                          <Col1>
                            <ColIcon data-blue data-desktop="flex">

                            </ColIcon>
                            <ColWrap data-mobile-p="10">
                              Страхование потребительского кредита
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Страховая сумма:</div>
                                <div>
                                  {data.vars.consumerCreditInsuranceAmount.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {data.vars.nsInsuranceExpenses.toLocaleString(
                                    "ru-RU"
                                  )}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap data-right>
                              {data.vars.consumerCreditInsuranceAmount.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {data.vars.nsInsuranceExpenses.toLocaleString(
                                "ru-RU"
                              )}
                            </ColWrap>
                          </Col3>
                        </Row>
                      )}

                      <Row>
                        <Col1>
                          <ColIcon>4</ColIcon>
                          <ColWrap data-head>Прочие расходы</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head></ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allHobbyExpenses.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                      {Object.values(data.vars.hobby).map((hobby, i) => (
                        <Row key={i}>
                          <Col1>
                            <ColIcon></ColIcon>
                            <ColWrap data-mobile-p="10">
                              {
                                {
                                  1: "Игра на гитаре",
                                  2: "Уроки живописи",
                                  3: "Выжигание по дереву",
                                  4: "Уроки танцев",
                                }[hobby.hobby]
                              }
                              <div
                                data-mobile="flex"
                                data-mobile-w="100"
                                data-align="space-between"
                                data-text
                              >
                                <div>Расход:</div>
                                <div>
                                  {hobby.expenses.toLocaleString("ru-RU")}
                                </div>
                              </div>
                            </ColWrap>
                          </Col1>
                          <Col2>
                            <ColWrap></ColWrap>
                          </Col2>
                          <Col3>
                            <ColWrap data-right>
                              {hobby.expenses.toLocaleString("ru-RU")}
                            </ColWrap>
                          </Col3>
                        </Row>
                      ))}
                      <Row data-mobile="flex">
                        <Col1>
                          <ColIcon>&sum;</ColIcon>
                          <ColWrap data-head data-mobile-p="10">
                            <div
                              data-mobile="flex"
                              data-mobile-w="100"
                              data-align="space-between"
                            >
                              <div>ИТОГО:</div>
                              <div>
                                {data.vars.allExpenses.toLocaleString("ru-RU")}
                              </div>
                            </div>
                          </ColWrap>
                        </Col1>
                      </Row>
                      <Row data-desktop="flex">
                        <Col1>
                          <ColIcon>&sum;</ColIcon>
                          <ColWrap data-head>ИТОГО</ColWrap>
                        </Col1>
                        <Col2>
                          <ColWrap data-head></ColWrap>
                        </Col2>
                        <Col3>
                          <ColWrap data-head data-right>
                            {data.vars.allExpenses.toLocaleString("ru-RU")}
                          </ColWrap>
                        </Col3>
                      </Row>
                    </>
                  )}
                </BodyContainer>
              </Body>
              <CloseButton onClick={hide} data-mt="10">
                К игре
              </CloseButton>
            </XLContainer>
          </Modal>
          <RepayCreditModal
            show={repayCreditModal}
            hide={() => setRepayCreditModal(false)}
            data={repayCreditData}
            toggleAction={toggleAction}
          />
        </>
      )}
    </>
  );
}

export default Register;
