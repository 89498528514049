import React from "react";
import { useNavigate } from "react-router-dom";

import { request } from "../components/Utils";

function Logout({ app }) {
  let navigate = useNavigate();
  const logout = async () => {
    const resp = await request({
      url: "/api/user/token/logout",
      params: {
        method: "GET",
      },
      app: app,
    });
    if (resp && resp.success) {
      app.current.setToken(null);
      app.current.setLoggedIn(false);
      navigate("/login");
    }
  };
  logout();
  return <></>;
}

export default Logout;
