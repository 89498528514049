const makeRequest = async ({ url, params, app }) => {
  const prms = JSON.parse(JSON.stringify(params));
  prms.headers = {
    "Content-Type": "application/json",
  };
  if (app.current.token != null) {
    prms.headers.Authorization = `Bearer ${app.current.token}`;
  }
  if (prms.method.toLowerCase() === "get") {
    url = `${url}${prms.body != null ? "?" : ""}${new URLSearchParams(
      prms.body
    )}`;
    delete prms.body;
  } else {
    prms.body = JSON.stringify(prms.body);
  }

  try {
    const response = await fetch(url, prms);
    const token = response.headers.get("Authorization");
    if (token != null) {
      const user = parseJwt(token);
      app.current.setUser(user);
      app.current.setToken(token);
      app.current.setLoggedIn(true);
    }
    /*
    const refreshToken = response.headers.get("Refresh-Token");
    if (refreshToken != null) {
      cookie.create('rt', refreshToken, 30, '/api/user/token/')
    }
    */
    return await response.json();
  } catch (e) {
    //console.log(e);
    return { networkError: true };
  }
};

export const sleep = function (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const request = async ({ url, params, app }) => {
  let result = await makeRequest({ url, params, app });
  if (!result.success && result.token === "expired") {
    //Обновляем токен
    await makeRequest({
      url: "/api/user/token/",
      params: {
        method: "GET",
      },
      app: app,
    });
    result = await makeRequest({ url, params, app });
  }

  //Повторяем, пока не получим ответ
  if (result.networkError) {
    while (result.networkError === true) {
      await sleep(5000);
      result = await makeRequest({ url, params, app });
      if (!result.success && result.token === "expired") {
        //Обновляем токен
        await makeRequest({
          url: "/api/user/token/",
          params: {
            method: "GET",
          },
          app: app,
        });
        result = await makeRequest({ url, params, app });
      }
    }
  }

  return result;
};

export const plural = function (n, forms) {
  //['число', 'числа', 'чисел']
  return n % 10 === 1 && n % 100 !== 11
    ? forms[0]
    : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
    ? forms[1]
    : forms[2];
};

export const parseJwt = function (token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const cookie = {
  find: function (cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  },
  create: function (cname, cvalue, exdays, path) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    console.log(cname + "=" + cvalue + ";" + expires + ";path=" + path);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path;
  },
};

export default request;
