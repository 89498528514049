import React from "react";

import Modal, {
  Container580,
  Head,
  Body,
  Text,
  Row,
  Box,
  Button,
} from "../../Modal";

import ShieldIcon from "../../../img/ShieldIcon.svg";
import WhiteCrossIcon from "../../../img/WhiteCrossIcon.svg";

const Life = ({ show, hide, action, data, mode }) => {
  return (
    <Modal show={show} valign="center">
      <Container580>
        <Head data-align="left">Страхование жизни</Head>
        <Body>
          <Row data-align="left">
            Полис страхования жизни позволяет получить дополнительный доход при
            дожитии до определенного возраста, срока или события (накопительная
            часть) либо при наступлении несчастных случаев, болезни, смерти
            (рисковая часть).
            <br />
            <br />
            Страховой взнос: 3000 р. в месяц.
            <br />
            <br />
            Игровые преимущества: возможность переброса кубика в сделках с
            различными исходами.
          </Row>
          <Row data-align="left" data-mt="20" data-mx="-10" data-mobile-mx="0">
            <Box
              data-round="10"
              data-bg="lightBlue"
              data-p="10"
              data-w="100"
              data-direction="row"
              data-mobile-direction="column"
            >
              <Box
                data-round="10"
                data-bg="lightGray"
                data-p="10"
                data-h="60"
                data-valign="center"
                data-w="100"
                data-halign="center"
                data-mobile-mb="10"
              >
                <Text data-text="17">Страховой взнос в месяц</Text>
                <Text data-text="17">
                  <strong>3000 &#8381;</strong>
                </Text>
              </Box>
              {data && data.vars.lifeInsurance ? (
                <div data-direction="row">
                  <Box
                    data-round="10"
                    data-bg="darkBlue"
                    data-p="10"
                    data-h="60"
                    data-valign="center"
                    data-w="100"
                    data-max-w="184"
                    data-mobile-max-w="none"
                    data-halign="center"
                    data-mr="0"
                    data-ml="10"
                    data-mobile-mx="0"
                  >
                    <Text
                      data-text="17"
                      data-align="center"
                      data-valign="center"
                      data-color="white"
                    >
                      <strong>Застрахован</strong>
                      <img src={ShieldIcon} alt="" data-ml="10" />
                    </Text>
                  </Box>
                  <Button
                    data-type="redBg"
                    data-h="60"
                    data-w="60px"
                    data-ml="10"
                    data-mb="0"
                    data-mr="0"
                    data-color="white"
                    data-form="square"
                    onClick={() => action({ _insurance: { life: false } })}
                  >
                    <img src={WhiteCrossIcon} alt="" />
                  </Button>
                </div>
              ) : (
                <Button
                  data-type="blue"
                  data-h="60"
                  data-ml="10"
                  data-mb="0"
                  data-mr="0"
                  data-min-w="254"
                  data-mobile-mx="0"
                  onClick={() => action({ _insurance: { life: true } })}
                >
                  Застраховать
                </Button>
              )}
            </Box>
          </Row>
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-mobile-mt="15"
          >
            <Button data-type="green" onClick={hide}>
              {["info", "study"].includes(mode) ? "Понятно" : "Назад"}
            </Button>
          </Row>
        </Body>
      </Container580>
    </Modal>
  );
};

export default Life;
