import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const BigButton = styled.button`
  flex: 1 1 auto;
  flex-direction: column;
  border: 0;
  background: #e8ebf3;
  border-radius: 10px;
  height: 130px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextBlock = styled.div`
  background: #f4f7ff;
  border-radius: 10px;
  width: 210px;
  height: 171px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #898b90;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & + & {
    margin-left: 10px;
  }
  &[data-selected="true"] {
    background: #009769;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #fdfdfd;
    & > img {
      display: block;
      right: 10px;
      top: 10px;
    }
  }
`;

export const TextBlockIcon = styled.img`
  position: absolute;
  display: none;
`;

export const AmorphButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  & > img {
    display: block;
  }
  & + & {
    margin-left: 20px;
  }
  cursor: pointer;
`;
export const Col = styled.div`
  &[data-grow] {
    flex: 1 1 auto;
  }
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Block = styled.div``;

export const Field = styled.input`
  height: 40px;
  border: 1px solid #949da3;
  border-radius: 10px;
  margin-left: 30px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  outline: 0;
  display: flex;
`;

export const ButtonsField = styled.input`
  height: 44px;
  border: 1px solid #949da3;
  border-radius: 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  color: #000000;
  outline: 0;
  display: flex;
`;

export const Die = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 2px solid #c5c5c5;
  position: relative;
  &[data-active="true"] {
    border: 2px solid #662483;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
    & > i {
      background: #662483;
    }
  }
  & > i {
    position: absolute;
    width: 7px;
    height: 7px;
    background: #242424;
    border-radius: 7px;
  }
  & > i[data-i="1"] {
    left: 5px;
    top: 5px;
  }
  & > i[data-i="2"] {
    left: 5px;
    top: 15px;
  }
  & > i[data-i="3"] {
    left: 5px;
    bottom: 4px;
  }
  & > i[data-i="4"] {
    right: 5px;
    top: 5px;
  }
  & > i[data-i="5"] {
    right: 5px;
    top: 15px;
  }
  & > i[data-i="6"] {
    right: 5px;
    bottom: 4px;
  }
  & > i[data-i="7"] {
    left: 15px;
    top: 15px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  z-index: 9999;
  padding: 16px;
`;

const Overlay = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  z-index: 0;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 0;
  z-index: 1;
  overflow: auto;
  padding: 40px;
  @media (max-width: 900px) {
    padding: 100px 10px 30px;
    align-items: flex-start;
  }
`;

const spinAnimation = keyframes`
 100% {  transform:rotate(360deg); }
`;

export const Loader = styled.img`
  display: none;
  animation-name: ${spinAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 25px;
  margin-right: 10px;
`;

export const XLContainer = styled.div`
  max-width: 968px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container1040 = styled.div`
  max-width: 1040px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container994 = styled.div`
  max-width: 994px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container730 = styled.div`
  max-width: 730px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container844 = styled.div`
  max-width: 844px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container530 = styled.div`
  max-width: 530px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container520 = styled.div`
  max-width: 520px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container620 = styled.div`
  max-width: 620px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container554 = styled.div`
  max-width: 554px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container286 = styled.div`
  max-width: 286px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Container580 = styled.div`
  max-width: 580px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 45px 41px;
  @media (max-width: 900px) {
    padding: 30px 10px 10px;
  }
`;

export const Head = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #293a47;
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 23px;
  text-align: left;
  align-items: center;
  color: #ffff;
  height: 60px;
  display: flex;
  border-radius: 10px;
  padding: 0 20px;
  flex: 1 1 auto;
  &[data-panel="5"] {
    background: #e98b3a;
  }
  &[data-panel="1"] {
    background: #69b42e;
  }
  &[data-panel="2"] {
    background: #1d71b8;
  }
  &[data-panel="3"] {
    background: #69b42e;
  }
  &[data-panel="4"] {
    background: #da404b;
  }
  &[data-panel="5"] {
    background: #e98b3a;
  }
  &[data-panel="6"] {
    background: #d62631;
  }
  &[data-panel="7"] {
    background: #009769;
  }
  &[data-panel="8"] {
    background: #662483;
  }
  &[data-panel="9"] {
    background: #009769;
  }
  &[data-panel="10"] {
    background: #da404b;
  }
  &[data-panel="11"] {
    background: #009769;
  }
  &[data-panel="12"] {
    background: #d60b52;
  }
  &[data-panel="13"] {
    background: #d62631;
  }
  &[data-panel="14"] {
    background: #F0BD3A;
  }
`;

export const HeaderIcon = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  color: #ffff;
  height: 60px;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  &[data-panel="5"] {
    background: #e98b3a;
  }
  &[data-panel="1"] {
    background: #69b42e;
  }
  &[data-panel="2"] {
    background: #1d71b8;
  }
  &[data-panel="3"] {
    background: #69b42e;
  }
  &[data-panel="4"] {
    background: #da404b;
  }
  &[data-panel="5"] {
    background: #e98b3a;
  }
  &[data-panel="6"] {
    background: #d62631;
  }
  &[data-panel="7"] {
    background: #009769;
  }
  &[data-panel="8"] {
    background: #662483;
  }
  &[data-panel="9"] {
    background: #009769;
  }
  &[data-panel="10"] {
    background: #da404b;
  }
  &[data-panel="11"] {
    background: #009769;
  }
  &[data-panel="12"] {
    background: #d60b52;
  }
  &[data-panel="13"] {
    background: #d62631;
  }
  &[data-panel="14"] {
    background: #F0BD3A;;
  }
`;
export const ErrorBox = styled.div`
  color: #d71c1c;
  background: #f5d7d7;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
`;

export const HobbyName = styled.div`
  height: 40px;
  padding: 15px;
  border: 1px solid rgba(41, 58, 71, 0.5);
  border-radius: 10px;
  font-size: 17px;
  color: #0000009e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #293a47;
  white-space: pre-wrap;
  &[data-align="center"] {
    text-align: center;
  }
`;

export const TextHead = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  color: #293a47;
  display: none;
  white-space: pre-wrap;
  &[data-align="center"] {
    text-align: center;
  }
  @media (max-width: 900px) {
    display: block;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: 22px;
`;

//С wrap будет Box
export const Row = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  &[data-align="center"] {
    justify-content: center;
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  background: #f4f7ff;
  border-radius: 10px;
  padding: 10px;
`;

export const Button = styled.button`
  flex: 0 1 auto;
  border: 1px solid #009769;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  height: 44px;
  color: #009769;
  cursor: pointer;
  background: transparent;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:not([data-form="square"]) {
    padding: 0 30px;
    @media (max-width: 900px) {
      flex: 1 1 auto;
    }
  }
  &[data-type="blue"] {
    border: 1px solid #286fb7;
    color: #286fb7;
  }
  &[data-type="blueBg"] {
    border: 1px solid #286fb7;
    background: #286fb7;
    color: #fdfdfd;
  }
  &[data-type="greenBg"] {
    border: 1px solid #009769;
    background: #009769;
    color: #fdfdfd;
  }
  &[data-type="red"] {
    border: 1px solid #be1622;
    color: #be1622;
  }
  &[data-type="redBg"] {
    background: #BE1622;
    border-radius: 10px;
    border: 0;
  }
  &[data-type="gray"] {
    border: 0;
    color: #fdfdfd;
    background-color: #767778;
    opacity: 0.5;
    cursor: default;
  }
  &[data-type="grayBorder"] {
    border: 1px solid #767778;
    color: #767778;
    background-color: transparent;
  }
  &[data-type="orange"] {
    border: 1px solid #e27a00;
    color: #e27a00;
  }
  &[data-loading="true"] > [data-loader] {
    display: block;
  }
  &[data-disabled="true"] {
    pointer-events: none;
  }
  &[data-type="greenBgDisabled"] {
    border: 0;
    color: #E2F2EE;
    background-color: #7AC7B4;
    cursor: default;
  }
  &[data-type="blueBgDisabled"] {
    border: 0;
    color: #E6EEF6;
    background-color: #8EB3DB;
    cursor: default;
  }
`;

export const ButtonLink = styled(Link)`
  max-width: 209px;
  text-decoration: none;
  width: 100%;
  border: 1px solid #009769;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  height: 44px;
  color: #009769;
  cursor: pointer;
  background: transparent;
  white-space: nowrap;
  padding: 0 30px;
  & + & {
    margin-left: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-type="blue"] {
    border: 1px solid #286fb7;
    color: #286fb7;
  }
  &[data-type="red"] {
    border: 1px solid #be1622;
    color: #be1622;
  }
  &[data-type="orange"] {
    border: 1px solid #e27a00;
    color: #e27a00;
  }
  &[data-loading="true"] > [data-loader] {
    display: block;
  }
  &[data-disabled="true"] {
    pointer-events: none;
  }
`;

export const List = styled.div`
  flex: 1 1 auto;
`;
export const ListHead = styled.div`
  padding: 10px;
  background: #f4f7ff;
  border-radius: 10px;
  display: flex;
  margin-bottom: 10px;
`;
export const ListHeadName = styled.div`
  flex: 1 1 auto;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  display: flex;
  align-items: center;
  padding-left: 30px;
`;
export const ListHeadControl = styled.div`
  width: 40px;
  height: 40px;
  background: #e8ebf3;
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListItemName = styled.div`
  flex: 1 1 auto;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

export const ListItemControl = styled.div`
  width: 40px;
  height: 40px;
  background: #e8ebf3;
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #286fb7;
`;

export const ListItem = styled.button`
  padding: 10px;
  background: #f4f7ff;
  border-radius: 10px;
  display: flex;
  width: 100%;
  outline: 0;
  border: 0;
  cursor: pointer;
  & + & {
    margin-top: 10px;
  }
  &[data-checked="true"] {
    background: #e6f5f0;
    & > ${ListItemName} {
      background: #cfece2;
    }
    & > ${ListItemControl} {
      background: #009769;
    }
  }
`;

export const ValueBlock = styled.div`
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  padding: 15px;
  background: #f4f7ff;
  border-radius: 10px;
  color: #293A47;
`;

function ModalComponent({ show, close, children, go, back, valign }) {
  const [open, setOpen] = useState(show != null ? show : true);

  useEffect(() => {
    if (show != null) {
      setOpen(show);
    }
  }, [show]);

  const closeHandler = () => {
    if (close != null) {
      close();
    } else {
      setOpen(!open);
    }
  };

  const overlayClose = () => {
    if (false && close != null) {
      closeHandler();
    }
  };

  return (
    <>
      {open && (
        <Modal>
          <Overlay onClick={overlayClose} />
          <Wrap>{children}</Wrap>
        </Modal>
      )}
    </>
  );
}

ModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func,
  children: PropTypes.object.isRequired,
  go: PropTypes.func,
  back: PropTypes.string,
};

export default ModalComponent;
