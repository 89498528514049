import { Text, Box, Button, TextHead } from "./Modal";

import ShieldIcon from "../img/ShieldIcon.svg";
import WhiteCrossIcon from "../img/WhiteCrossIcon.svg";
import WhitePlusIcon from "../img/WhitePlusIcon.svg";
import WhiteCheckIcon from "../img/WhiteCheckIcon.svg";

const CustomButton = ({ id, active, selected, setSelected, insurance, disabled, title }) => {
  return (
    <>
      {insurance && (
        <Box
          data-round="10"
          data-bg={
            active && selected.includes(id)
              ? "darkBlue"
              : selected.includes(id)
                ? "lightGreen"
                : "lightGray"
          }
          data-p="10"
          data-h="40"
          data-mobile-h="50"
          data-valign="center"
          data-w="100"
          data-halign="center"
          data-mobile-align="left"
          data-mr="0"
          data-ml="5"
          data-mobile-mx="0"
        >
          {title &&
            <TextHead data-text="17">{title}</TextHead>}
          <Text
            data-text="17"
            data-align="center"
            data-valign="center"
            data-color={active && selected.includes(id) ? "white" : ""}
          >
            {active && selected.includes(id) ? (
              <>
                <strong>Застрахован</strong>
                <img src={ShieldIcon} alt="" data-ml="10" />
              </>
            ) : (
              "Не застрахован"
            )}
          </Text>
        </Box>
      )}
      <Button
        data-type={active ? "blue" : selected.includes(id) ? "greenBg" : "blue"}
        data-h="40"
        data-w="40px"
        data-form="square"
        data-mb="0"
        data-mr="0"
        data-bg={active ? "darkBlue" : selected.includes(id) ? "" : "darkBlue"}
        data-color="white"
        data-mobile-mt="5"
        onClick={() => !disabled &&
          setSelected((prev) => {
            let res = prev.includes(id)
              ? prev.filter((j) => j !== id)
              : [...prev, id];
            return res;
          })
        }
      >
        {selected.includes(id) && !active && (
          <img src={WhiteCheckIcon} alt="" />
        )}
        {active && selected.includes(id) && <img src={WhiteCrossIcon} alt="" />}
        {!selected.includes(id) && <img src={WhitePlusIcon} alt="" />}
      </Button>
    </>
  );
};

export default CustomButton;
