import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";

import MainWrap from "../view/MainWrap";
import { ButtonSmallLink } from "../view/Form";
import Table, {
  TableWrap,
  Head as TableHead,
  Row as TableRow,
  Col as TableCol,
} from "../components/Table";
import { request } from "../components/Utils";

import ArrowIcon from "../img/ArrowIcon.svg";
import WhitePlusIcon from "../img/WhitePlusIcon.svg";
import RemoveItemIcon from "../img/RemoveItemIcon.svg";
import RemoveIcon from "../img/RemoveIcon.svg";
import CloseIcon from "../img/CloseIcon.svg";
import ArrowRightWhiteIcon from "../img/ArrowRightWhiteIcon.svg";

import Modal, {
  Container620,
  Container554,
  Head,
  Body,
  Row,
  Box,
  Button,
  ButtonLink,
} from "../components/Modal";

import CardModal from "../components/modals/CardModal";

import { FieldLine, Field, Input, Label } from "../view/Form";

const Col = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background: #f4f7ff;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #293a47;
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 0;
  background: #e8ebf3;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: rotate(-180deg);
  margin-right: 30px;
`;

const CreateButton = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: #009769;
  cursor: pointer;
`;

const SuggestWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 5px rgb(0 0 0 / 10%);
  position: absolute;
  top: 85px;
  z-index: 10;
`;

const SuggestItem = styled.button`
  width: 100%;
  border: 0;
  background: transparent;
  height: 43px;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #293a47;
  padding-left: 20px;
  cursor: pointer;
  & + & {
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
  }
`;

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const TagsItem = styled.div`
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  height: 44px;
  padding: 11px 36px 11px 17px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
`;
const TagsItemRemove = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  right: 8px;
  cursor: pointer;
`;

const DatePickerStyle = styled.div`
  width: 100%;
  & > .react-datetime-picker {
    height: 50px;
    width: 100%;
    & > .react-datetime-picker__wrapper {
      border-radius: 10px;
      background: #ffffff;
      border: 0.5px solid #efefef;
      padding: 0 15px;
    }
  }
`;

const TableColKey = styled(TableCol)`
  width: 50%;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 18px;
`;

const KeyInput = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  padding: 10px;
  text-align: center;
  font-size: 17px;
  color: #293a47;
  outline: 0;
`;

export const AddModal = ({ app, show, hide, setReloadTable }) => {
  const [params, setParams] = React.useState({});
  const [touched, setTouched] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const send = async () => {
    if (!errors.name && !errors.email) {
      const resp = await request({
        url: "/api/tournament/add/",
        params: {
          method: "POST",
          body: params,
        },
        app: app,
      });
      setErrors(resp.errors);
      if (resp.success) {
        hide();
        setReloadTable(+new Date());
      }
    }
    setTouched(true);
    return false;
  };

  return (
    <Modal show={show} valign="center">
      <Container620 data-bg="lightBlue">
        <Head data-align="left">Создание турнира</Head>
        <Body>
          <TournamentForm
            app={app}
            setParams={setParams}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            setErrors={setErrors}
          />
          <Row
            data-align="center"
            data-mt="40"
            data-ml="-5"
            data-mr="-5"
            data-mb="-10"
            data-mobile-direction="column"
          >
            <Button data-type="red" onClick={hide} data-mobile-w="100" data-mobile-mx="0">
              Закрыть
            </Button>
            <Button data-type="green" onClick={send} data-mobile-w="100" data-mobile-mx="0">
              Создать турнир
            </Button>
          </Row>
        </Body>
      </Container620>
    </Modal>
  );
};

export const EditModal = ({
  app,
  show,
  hide,
  setReloadTable,
  tournamentId,
}) => {
  const [params, setParams] = React.useState({});
  const [touched, setTouched] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [data, setData] = React.useState({});
  const [restartModal, setRestartModal] = React.useState(false);
  const [removeTournamentModal, setRemoveTournamentModal] =
    React.useState(false);

  React.useEffect(() => {
    if (tournamentId !== null) {
      const load = async () => {
        const resp = await request({
          url: "/api/tournament",
          params: {
            method: "GET",
            body: {
              id: tournamentId,
            },
          },
          app: app,
        });
        if (resp && resp.success) {
          setData(resp.result.data);
        }
      };
      load();
    }
    // eslint-disable-next-line
  }, [tournamentId]);

  const closeTournament = async () => {
    await request({
      url: "/api/tournament/close/",
      params: {
        method: "POST",
        body: {
          id: tournamentId,
        },
      },
      app: app,
    });
    setReloadTable(+new Date());
  };

  const send = async () => {
    if (!errors.name && !errors.email) {
      const resp = await request({
        url: "/api/tournament/edit/",
        params: {
          method: "POST",
          body: { ...params, id: tournamentId },
        },
        app: app,
      });
      if (resp.success) {
        hide();
        setReloadTable(+new Date());
      }
    }
    setTouched(true);
    return false;
  };

  const removeTournament = async () => {
    if (!errors.name && !errors.email) {
      await request({
        url: "/api/tournament/remove/",
        params: {
          method: "POST",
          body: { id: tournamentId },
        },
        app: app,
      });
      hide();
      setReloadTable(+new Date());
    }
    setTouched(true);
    return false;
  };

  const toggleTournament = async () => {
    if (!errors.name && !errors.email) {
      await request({
        url: "/api/tournament/toggle/",
        params: {
          method: "POST",
          body: { id: tournamentId },
        },
        app: app,
      });
      hide();
      setReloadTable(+new Date());
    }
    setTouched(true);
    return false;
  };

  const restart = async () => {
    if (!errors.name && !errors.email) {
      await request({
        url: "/api/tournament/restart/",
        params: {
          method: "POST",
          body: { id: tournamentId },
        },
        app: app,
      });
      hide();
      setReloadTable(+new Date());
    }
    setTouched(true);
    return false;
  };

  const toggleResults = async () => {
    if (!errors.name && !errors.email) {
      await request({
        url: "/api/tournament/reactivate/",
        params: {
          method: "POST",
          body: { id: tournamentId },
        },
        app: app,
      });
      hide();
      setReloadTable(+new Date());
    }
    setTouched(true);
    return false;
  };

  return (
    <>
      <Modal show={show} valign="center">
        <Container620 data-bg="lightBlue">
          <Head data-align="left">Редактирование турнира</Head>
          <Body>
            <TournamentForm
              app={app}
              setParams={setParams}
              touched={touched}
              setTouched={setTouched}
              errors={errors}
              setErrors={setErrors}
              data={data}
            />
            <Row
              data-align="center"
              data-mt="40"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <Button
                data-type="red"
                onClick={() => setRemoveTournamentModal(true)}
                data-mobile-w="100" data-mobile-mx="0">
                Удалить турнир
              </Button>
              {data.status === 0 && +new Date(data.started_at) < +new Date() && (
                <Button data-type="blue" onClick={closeTournament} data-mobile-w="100" data-mobile-mx="0">
                  Подвести итог
                </Button>
              )}
              {false && (
                <>
                  {data.active === 1 && (
                    <Button data-type="blue" onClick={toggleTournament}>
                      Отключить турнир
                    </Button>
                  )}
                  {data.active === 0 && (
                    <Button data-type="grayBorder" onClick={toggleTournament}>
                      Турнир отключен
                    </Button>
                  )}
                </>
              )}
            </Row>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <Button data-type="red" onClick={() => setRestartModal(true)} data-mobile-w="100" data-mobile-mx="0">
                Удалить результаты
              </Button>
              {data.status === 1 && (
                <Button data-type="blue" onClick={toggleResults} data-mobile-w="100" data-mobile-mx="0">
                  Отключить результаты
                </Button>
              )}
              {data.status === 0 && (
                <Button data-type="grayBorder" onClick={toggleTournament} data-mobile-w="100" data-mobile-mx="0">
                  Результаты отключены
                </Button>
              )}
            </Row>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
              data-mobile-direction="column"
            >
              <Button data-type="red" onClick={hide} data-mobile-w="100" data-mobile-mx="0">
                Закрыть
              </Button>
              <Button data-type="green" onClick={send} data-mobile-w="100" data-mobile-mx="0">
                Сохранить
              </Button>
            </Row>
          </Body>
        </Container620>
      </Modal>
      <Modal show={restartModal} valign="center">
        <Container554 data-bg="lightBlue">
          <Box data-valign="center" data-mb="20">
            <img src={RemoveIcon} alt="Remove icon" />
          </Box>
          <Head data-align="center">
            Вы уверены, что хотите удалить результаты?
          </Head>
          <Body>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <Button
                data-type="red"
                onClick={() => {
                  setRestartModal(false);
                  restart();
                }}
              >
                Да, удалить
              </Button>
              <Button data-type="green" onClick={() => setRestartModal(false)}>
                Отменить
              </Button>
            </Row>
          </Body>
        </Container554>
      </Modal>
      <Modal show={removeTournamentModal} valign="center">
        <Container554 data-bg="lightBlue">
          <Box data-valign="center" data-mb="20">
            <img src={RemoveIcon} alt="Remove icon" />
          </Box>
          <Head data-align="center">
            Вы уверены, что хотите удалить турнир?
          </Head>
          <Body>
            <Row
              data-align="center"
              data-mt="10"
              data-ml="-5"
              data-mr="-5"
              data-mb="-10"
            >
              <Button
                data-type="red"
                onClick={() => {
                  setRemoveTournamentModal(false);
                  removeTournament();
                }}
              >
                Да, удалить
              </Button>
              <Button
                data-type="green"
                onClick={() => setRemoveTournamentModal(false)}
              >
                Отменить
              </Button>
            </Row>
          </Body>
        </Container554>
      </Modal>
    </>
  );
};

const TournamentForm = ({
  app,
  setParams,
  touched,
  setTouched,
  errors,
  setErrors,
  data,
}) => {
  const [name, setName] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [movesCount, setMovesCount] = React.useState(40);
  const [startAt, setStartAt] = React.useState("");
  const [endAt, setEndAt] = React.useState("");
  const [key, setKey] = React.useState("");
  const [participants, setParticipants] = React.useState({});

  const [suggests, setSuggests] = React.useState([]);
  const [suggestsBlock, setSuggestsBlock] = React.useState(false);
  const [timer, setTimer] = React.useState(null);

  React.useEffect(() => {
    console.log(errors);
  }, [errors]);

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setName(data.name);
      setMovesCount(data.moves);
      setStartAt(new Date(data.started_at));
      setEndAt(new Date(data.ended_at));
      setKey(data.key);
      setParticipants(data.participants);
    }
  }, [data]);

  React.useEffect(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        if (search !== "") {
          setSuggestsBlock(false);
          const resp = await request({
            url: "/api/tournament/suggest",
            params: {
              method: "GET",
              //redirect: "follow",
              body: {
                search,
              },
            },
            app: app,
          });
          if (resp.success) {
            setSuggestsBlock(true);
            setSuggests(resp.result.data);
          }
        } else {
          setSuggests([]);
        }
      }, 1000)
    );
    // eslint-disable-next-line
  }, [search]);

  React.useEffect(() => {
    //debugger;
    if (name.trim() === "") {
      setErrors((prevState) => {
        return { ...prevState, name: true };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, name: false };
      });
    }
    setParams({ name, participants, movesCount, startAt, endAt, key });
    // eslint-disable-next-line
  }, [name, participants, movesCount, startAt, endAt, key]);

  return (
    <>
      <Row>
        <FieldLine>
          <Field
            data-valid={errors.name && touched ? "false" : "true"}
            data-w="50"
          >
            <Label>Название</Label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Field>
          <Field
            data-valid={errors.key && touched ? "false" : "true"}
            data-w="50"
          >
            <Label>Слово идентификатор</Label>
            <Input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
          </Field>
        </FieldLine>
      </Row>
      <Row data-mt="30">
        <Field
          data-valid={errors.search && touched ? "false" : "true"}
          data-w="100"
        >
          <Label>Добавление участников по логину</Label>
          <Input
            type="text"
            value={search}
            placeholder="Начните вводить email участника"
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setSuggestsBlock(true)}
          />
        </Field>
        {suggests.length > 0 && suggestsBlock === true && (
          <SuggestWrap>
            {suggests.map((suggest, i) => (
              <SuggestItem
                key={i}
                onClick={() => {
                  setParticipants((prevState) => {
                    return { ...prevState, [suggest.id]: suggest };
                  });
                  setSuggestsBlock(false);
                }}
              >
                {suggest.email}
              </SuggestItem>
            ))}
          </SuggestWrap>
        )}
      </Row>
      <Row data-mt="30">
        <Field data-valid={errors.participants && touched ? "false" : "true"}>
          <Label>Участники</Label>
          <TagsWrap>
            {Object.values(participants).map((participant, i) => (
              <TagsItem key={i}>
                {participant.email}
                <TagsItemRemove
                  onClick={() =>
                    setParticipants((prevState) => {
                      let s = { ...prevState };
                      delete s[participant.id];
                      return s;
                    })
                  }
                >
                  <img src={RemoveItemIcon} alt="Remove" />
                </TagsItemRemove>
              </TagsItem>
            ))}
            {data && (
              <ButtonLink to={`/cp/tournaments/${data.id}/participants`}>
                Список участников
              </ButtonLink>
            )}
          </TagsWrap>
        </Field>
      </Row>
      <Row data-mt="30">
        <FieldLine data-mobile-direction="column">
          <Field
            data-valid={errors.startAt && touched ? "false" : "true"}
            data-w="50"
            data-mobile-w="100"
          >
            <Label>Дата и время старта</Label>
            <DatePickerStyle>
              <DateTimePicker
                onChange={(v) => {
                  setStartAt(v);
                }}
                value={startAt}
              />
            </DatePickerStyle>
          </Field>
          <Field
            data-valid={errors.endAt && touched ? "false" : "true"}
            data-w="50"
            data-mobile-w="100"
            data-mobile-mx="0"
            data-mobile-mt="30"
          >
            <Label>Дата и время окончания</Label>
            <DatePickerStyle>
              <DateTimePicker
                onChange={(v) => {
                  setEndAt(v);
                }}
                value={endAt}
              />
            </DatePickerStyle>
          </Field>
        </FieldLine>
      </Row>
      <Row data-mt="30">
        <FieldLine>
          <Field
            data-valid={errors.movesCount && touched ? "false" : "true"}
            data-w="50"
            data-mobile-w="100"
          >
            <Label>Остановить игру после хода</Label>
            <Input
              type="text"
              value={movesCount}
              onChange={(e) => setMovesCount(e.target.value)}
            />
          </Field>
          <Field data-w="50" data-desktop="block"></Field>
        </FieldLine>
      </Row>
    </>
  );
};

const Tournaments = ({ app }) => {
  const [modalAdd, setModalAdd] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalError, setModalError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [tournamentId, setTournamentId] = React.useState(null);
  const [key, setKey] = React.useState("");
  const [reloadTable, setReloadTable] = React.useState(+new Date());

  const removeTournament = async (id) => {
    await request({
      url: "/api/tournament/remove/",
      params: {
        method: "POST",
        body: {
          id,
        },
      },
      app: app,
    });
    setReloadTable(+new Date());
  };

  const participateTournament = async () => {
    const resp = await request({
      url: "/api/tournament/participate/",
      params: {
        method: "POST",
        body: {
          key,
        },
      },
      app: app,
    });
    if (resp.errors && resp.errors.key) {
      setError(resp.errors.key);
      setModalError(true);
    }
    setReloadTable(+new Date());
  };

  return (
    <>
      <CardModal
        show={modalError}
        hide={() => setModalError(false)}
        modal={{
          type: "error",
          text: error,
          buttons: [
            {
              type: "green",
              name: "Закрыть",
            },
          ],
        }}
        action={() => {
          setModalError(false);
        }}
      />
      <MainWrap app={app} panel="main">
        <div data-direction="row" data-w="100" data-mt="40">
          <Col data-w="100">
            <BackButton to="/cp">
              <img src={ArrowIcon} alt="Back icon" />
            </BackButton>
            Турниры
          </Col>
          {app.current.user.role === "admin" && (
            <Col data-ml="10">
              <CreateButton onClick={() => setModalAdd(true)}>
                <img src={WhitePlusIcon} alt="Back icon" />
              </CreateButton>
            </Col>
          )}
        </div>
        <TableWrap data-mt="10">
          <TableHead>
            <TableRow data-mobile-wrap="true">
              <TableColKey>
                <strong>Введите идентификатор турнира</strong>
              </TableColKey>
              <TableCol style={{ width: "25%" }}>
                <KeyInput
                  type="text"
                  placeholder="Идентификатор турнира"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                />
              </TableCol>
              <TableCol style={{ width: "25%" }}>
                <Button
                  data-type="green"
                  data-m="0"
                  data-max-w="none"
                  data-w="100"
                  data-bg="white"
                  onClick={participateTournament}
                >
                  Применить
                </Button>
              </TableCol>
            </TableRow>
          </TableHead>
        </TableWrap>
        <Table
          source="/api/tournament/my/"
          app={app}
          rows={10}
          reload={reloadTable}
          fields={[
            {
              key: "name",
              name: "Название",
              smallName: "",
              bold: true
            },
            {
              key: "started_at",
              name: "Дата начала",
              render: (row) => (
                <>
                  {new Date(row.started_at).toLocaleDateString("ru-ru", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}{" "}
                  {new Date(row.started_at).toLocaleTimeString("ru-ru")}
                </>
              ),
            },
            {
              key: "ended_at",
              name: "Дата завершения",
              render: (row) => (
                <>
                  {row.ended_at !== null && (
                    <>
                      {new Date(row.ended_at).toLocaleDateString("ru-ru", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      {new Date(row.ended_at).toLocaleTimeString("ru-ru")}
                    </>
                  )}
                </>
              ),
            },
            {
              key: "status",
              name: "Действие",
              smallName: "",
              noPadding: true,
              render: (row) => (
                <>
                  {app.current.user.role === "user" && (
                    <>
                      {row.status === 0 &&
                        +new Date(row.started_at) > +new Date() &&
                        row.tournament_status === 0 && (
                          <Button
                            data-type="greenBgDisabled"
                            data-m="0"
                            data-max-w="none"
                            data-w="100"
                          >
                            Ожидает
                          </Button>
                        )}
                      {row.status === 0 &&
                        +new Date(row.started_at) < +new Date() &&
                        +new Date(row.ended_at) > +new Date() &&
                        row.tournament_status === 0 && (
                          <ButtonSmallLink to={`/cp/game/${row.id}`}>
                            Играть
                          </ButtonSmallLink>
                        )}
                      {((row.status === 0 &&
                        +new Date(row.ended_at) < +new Date()) ||
                        row.status === 1) &&
                        row.tournament_status === 0 && (
                          <>
                            <Button
                              data-type="blueBgDisabled"
                              data-m="0"
                              data-max-w="none"
                              data-w="100"
                            >
                              Ожидает результата
                            </Button>
                            <ButtonSmallLink
                              to={`/cp/game/${row.id}`}
                              data-form="square"
                              data-bg="green"
                              data-h="40"
                              data-w="40px"
                              data-mb="0"
                              data-mr="0"
                              data-ml="10"
                            >
                              <img
                                src={ArrowRightWhiteIcon}
                                alt="Game"
                                style={{ verticalAlign: "text-bottom" }}
                              />
                            </ButtonSmallLink>
                          </>
                        )}
                      {row.tournament_status === 1 && (
                        <ButtonSmallLink
                          to={`/cp/tournaments/${row.tournament_id}/rating`}
                          data-bg="blue"
                          data-color="white"
                        >
                          Итоги турнира
                        </ButtonSmallLink>
                      )}
                    </>
                  )}
                  {app.current.user.role === "admin" && (
                    <>
                      {row.status === 0 && (
                        <Button
                          data-type="green"
                          onClick={() => {
                            setTournamentId(row.id);
                            setModalEdit(true);
                          }}
                          data-m="0"
                          data-bg="white"
                          data-max-w="none"
                          data-w="100"
                        >
                          Редактировать
                        </Button>
                      )}
                      {row.status === 1 && (
                        <>
                          <ButtonSmallLink
                            to={`/cp/tournaments/${row.tournament_id}/rating`}
                            data-bg="blue"
                            data-color="white"
                          >
                            Результаты турнира
                          </ButtonSmallLink>
                          <Button
                            data-type="redBg"
                            onClick={() => removeTournament(row.id)}
                            data-m="0"
                            data-ml="10"
                            data-h="40"
                            data-w="40px"
                            data-px="0"
                          >
                            <img src={CloseIcon} alt="Close icon" />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              ),
            },
          ]}
        />
      </MainWrap>
      <AddModal
        app={app}
        show={modalAdd}
        hide={() => setModalAdd(false)}
        setReloadTable={setReloadTable}
      />
      <EditModal
        app={app}
        show={modalEdit}
        hide={() => {
          setModalEdit(false);
          setTournamentId(null);
        }}
        setReloadTable={setReloadTable}
        tournamentId={tournamentId}
      />
    </>
  );
};

Tournaments.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Tournaments;
