import styled from "styled-components";

export const Wrap = styled.div`
  background: linear-gradient(
      0deg,
      rgba(246, 248, 252, 0.2),
      rgba(246, 248, 252, 0.2)
    ),
    #e1eafb;
  padding: 42px 20px;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: auto;

  &[data-rolling="true"] [data-chip] {
    display: none;
  }

  &[data-rolling="false"] [data-moving-chip] {
    display: none;
  }

  &[data-rolling="true"] [data-moving-chip] {
    display: block;
  }
`;

export const Container = styled.div`
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 900px) {
    align-items: center;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
`;

export const LogoBCC = styled.img`
  max-height: 82px;
  margin-right: 36px;
  @media (max-width: 900px) {
    max-width: 66px;
    margin-right: 16px;
  }
`;

export const LogoGame = styled.img`
  max-height: 88px;
  @media (max-width: 900px) {
    max-width: 48px;
  }
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: flex-end;
  & > div {
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #293a47;
    margin-left: 36px;
  }
`;

export const Body = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  witdh: 100%;
  max-width: 270px;
  @media (max-width: 900px) {
    margin-top: 10px;
    max-width: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CashWrap = styled.div`
  position: fixed;
  width: 100%;
  top: 128px;
  pointer-events: none;
  @media (max-width: 900px) {
    top: -40px;
  }
  left: -8px;
  z-index: 99999;
  padding: 42px 28px;
`;

export const CashContainer = styled.div`
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const CashBox = styled.div`
  width: 100%;
  max-width: 270px;
`;

export const Parameter = styled.div`
  background: #f4f7ff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    margin-top: 10px;
    margin-bottom: 0;
    max-width: none;
    width: 49%;
    &:nth-child(2n) {
      margin-left: 2.5px;
    }
    &:nth-child(2n + 1) {
      margin-right: 2.5px;
    }
    padding: 5px;
  }
`;

export const ParameterIcon = styled.div`
  & > img {
    width: 40px;
    height: 40px;
  }
  background: #e8ebf3;
  border-radius: 10px;
  padding: 10px;
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
    & > img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ParameterTitle = styled.div`
  background: #e8ebf3;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
  flex: 1 1 auto;
  @media (max-width: 900px) {
    padding: 3px;
    border-radius: 6px;
    margin-left: 5px;
    background: transparent;
  }
`;

export const ParameterName = styled.div`
  font-size: 17px;
  line-height: 22px;
  color: #293a47;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 11px;
    line-height: 16px;
  }
`;

export const ParameterValue = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #293a47;
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const RegisterButton = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: linear-gradient(0deg, #009769, #009769), #f0f0f0;
  border-radius: 10px;
  height: 60px;
  border: 0;
  cursor: pointer;
  padding: 10px;
  display: flex;
  text-decoration: none;
  width: 100%;
  & > img {
    width: 24px;
    height: 24px;
    margin-right: 7px;
  }
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #fdfdfd;
  @media (max-width: 900px) {
    height: 40px;
    font-size: 16px;
    border-radius: 6px;
  }
`;

export const UserChip = styled.img`
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
  z-index: 9999;
  position: absolute;
  &[data-panel="6"] {
    left: auto;
    right: 45px;
    top: 63px;
  }
  &[data-panel="13"] {
    left: 46px;
    top: 51px;
  }
  filter: drop-shadow(0px 7px 0px rgba(0, 0, 0, 0.3));
  transition: all 0.5s ease;
  @media (max-width: 900px) {
    height: 27px;
    width: 27px;
    margin-left: -15px;
    margin-top: -15px;
    &[data-panel="6"] {
      left: auto;
      right: 15px;
      top: 45px;
    }
    &[data-panel="13"] {
      left: 45px;
      top: 45px;
    }
  }
`;

export const ResultIconWrap = styled.div`
  & > img {
    width: 40px;
    height: 40px;
  }
  background: #e0eaf8;
  border-radius: 10px;
  padding: 10px;
  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
    & > img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ResultTitle = styled.div`
  background: #e0eaf8;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
  flex: 1 1 auto;
  @media (max-width: 900px) {
    padding: 3px;
    border-radius: 6px;
    margin-left: 5px;
  }
`;

export const ResultName = styled.div`
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #286fb7;
  @media (max-width: 900px) {
    font-size: 11px;
    line-height: 16px;
  }
`;

export const ResultValue = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #286fb7;
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ResultDescription = styled.div`
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #293a47;
  opacity: 0.4;
  margin-top: 10px;
`;

export const GameField = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 30px;
  @media (max-width: 900px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

export const GamePanelColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  padding: 5px;
`;

export const GamePanel = styled.div`
  position: relative;
  height: 140px;
  & + & {
    margin-top: 10px;
  }
  @media (max-width: 900px) {
    height: 50px;
  }
`;

export const GamePanelContainer = styled.div`
  border-radius: 10px;
  position: absolute;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.5s ease;
  overflow: hidden;
  &[data-active="true"] {
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -8px;
  }
`;

export const GamePanelIcon = styled.img`
  position: absolute;
  margin: 18px 28px;
  &[data-premium] {
    margin: 0;
  }
  &[data-oportunity] {
    margin-left: 15px;
    margin-top: 0;
  }
  &[data-roam] {
    margin-left: 10px;
    margin-top: 6px;
  }
  &[data-expenses] {
    margin-top: 38px;
  }
  @media (max-width: 900px) {
    margin: 16px -4px;
    width: 50px;
    &[data-expenses] {
      margin-top: 19px;
    }
    &[data-premium] {
      margin-left: -18px;
      margin-top: -10px;
      width: 100px;
    }
    &[data-oportunity] {
      margin-left: -17px;
      margin-top: 8px;
      width: 100px;
    }
    &[data-roam] {
      margin-left: -15px;
      margin-top: -5px;
      width: 70px;
    }
  }
`;

export const GamePanelTitle = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  text-align: center;
  color: #fcfcfc;
  position: absolute;
  right: 20px;
  top: 15px;
  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 16px;
    top: 7px;
    &[data-trauma] {
      top: 20px;
    }
  }
`;

export const GamePanelIcon2 = styled.img`
  position: absolute;
  margin: 18px 28px;
  right: 0;
  &[data-expenses] {
    margin-top: 38px;
  }
  &[data-trauma] {
    margin-top: 46px;
  }
  @media (max-width: 900px) {
    width: 50px;
    &[data-trauma] {
      margin-top: 26px;
      margin-right: -6px;
      display: none;
    }
  }
`;

export const GamePanelTitle2 = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  text-align: center;
  color: #fcfcfc;
  position: absolute;
  left: 20px;
  top: 15px;
  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 16px;
    top: 7px;
    &[data-expenses] {
      left: auto;
      right: 20px;
    }
  }
`;

export const GameWrap = styled.div`
  display: flex;
`;

export const GameContainer = styled.div`
  display: flex;
  background: #f4f7ff;
  border-radius: 10px;
  padding: 5px;
  flex-direction: column;
  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const GameButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #f4f7ff;
  border-radius: 10px;
  padding: 5px;
  margin-top: 15px;
  @media (max-width: 900px) {
    padding-top: 0;
  }
`;

export const GameButton = styled.button`
  background: #286fb7;
  border-radius: 10px;
  border: 0;
  height: 60px;
  flex: 1 1 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #fcfcfc;
  cursor: pointer;
  margin: 5px;
  @media (max-width: 900px) {
    width: 49%;
    flex: 0 1 auto;
    margin: 5px 0 0 0;
    &:nth-child(2n) {
      margin-left: 2.5px;
    }
    &:nth-child(2n + 1) {
      margin-right: 2.5px;
    }
  }
`;

export const DiceField = styled.div`
  height: 460px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  @media (max-width: 900px) {
    height: 190px;
  }
`;

export const DiceFieldHead = styled.div`
  display: flex;
  @media (max-width: 900px) {
    padding: 0 5px 5px;
  }
`;

export const RollsHistoryButton = styled.button`
  height: 44px;
  background: #e8ebf3;
  border-radius: 10px;
  flex: 1 1 auto;
  margin-right: 10px;
  color: #76818c;
  font-size: 15px;
  line-height: 22px;
  border: 0;
  padding: 11px 15px;
  white-space: nowrap;
  cursor: pointer;
  & > img {
    margin-right: 7px;
    width: 12px;
    height: 12px;
  }
`;

export const RollsRemain = styled.div`
  height: 44px;
  background: #e8ebf3;
  border-radius: 10px;
  flex: 1 1 auto;
  color: #76818c;
  font-size: 15px;
  line-height: 22px;
  border: 0;
  padding: 11px 15px;
  white-space: nowrap;
  @media (max-width: 900px) {
    font-size: 11px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    background: transparent;
    height: 24px;
    padding: 0;
  }
`;
export const DiceFieldBody = styled.div`
  flex: 1 1 auto;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 900px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
export const DiceFieldFoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TestRollButton = styled.button`
  border: 1px solid #009769;
  background: #f4f7ff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  height: 44px;
  color: #009769;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 24px;
    background: #009769;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #fcfcfc;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RollButton = styled.button`
  border: 1px solid #009769;
  background: #009769;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  height: 44px;
  color: #fcfcfc;
  cursor: pointer;
`;

export const PanelInfo = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 20px;
  bottom: 15px;
  @media (max-width: 900px) {
    bottom: 5px;
  }
`;

export const WeekIcon = styled.img`
  @media (max-width: 900px) {
    width: 24px;
    height: 24px;
    margin-left: 5px !important;
  }
`;

export const StartFieldIcon = styled.div`
  height: 30px;
  border: 2px solid #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  color: #fcfcfc;
  padding: 0 10px;
  line-height: 27px;
  margin-top: 3px;
  margin-left: 10px;
  @media (max-width: 900px) {
    font-size: 9px;
    line-height: 12px;
    height: 14px;
    border: 1px solid #fff;
    border-radius: 3px;
  }
`;

export const RulesBody = styled.div`
  font-size: 18px;
  background: #ffffff;
  color: #293a47;
  border-radius: 10px;
  padding: 30px 40px;
`;
