import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

//import View from "./_View";
//import Panel from "../components/Panel";
import Loading from "../pages/Loading";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Remind from "../pages/Remind";
import Main from "../pages/Main";
import Game from "../pages/Game";
import Tournaments from "../pages/Tournaments";
import Rating from "../pages/Rating";
import Participants from "../pages/Participants";
import NotFound from "../pages/NotFound";

import { request } from "../components/Utils";
import Logout from "../components/Logout";

function App() {
  //const [user, setUser] = useState({});
  //const [activePanel, setActivePanel] = useState("loading");
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const app = React.useRef(null);

  app.current = {
    loggedIn,
    setLoggedIn,
    token,
    setToken,
    user,
    setUser,
  };
  const api_regex = /^\/api\/.*/;
  // if using "/api/" in the pathname, don't use React Router
  if (api_regex.test(window.location.pathname)) {
    return <div />; // must return at least an empty div
  } else {
    return (
      <Init app={app}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <NotLoggedIn app={app}>
                  <Login app={app} />
                </NotLoggedIn>
              }
            ></Route>
            <Route
              path="/login"
              element={
                <NotLoggedIn app={app}>
                  <Login app={app} />
                </NotLoggedIn>
              }
            ></Route>
            <Route
              path="/register"
              element={
                <NotLoggedIn app={app}>
                  <Register app={app} />
                </NotLoggedIn>
              }
            ></Route>
            <Route
              path="/remind"
              element={
                <NotLoggedIn app={app}>
                  <Remind app={app} />
                </NotLoggedIn>
              }
            ></Route>
            <Route
              path="/logout"
              element={
                <RequireAuth app={app}>
                  <Logout app={app} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/cp"
              element={
                <RequireAuth app={app}>
                  <Main app={app} />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/cp/game/:gameId"
              element={
                <RequireAuth app={app}>
                  <Game app={app} />
                </RequireAuth>
              }
            />
            <Route
              path="/cp/tournaments"
              element={
                <RequireAuth app={app}>
                  <Tournaments app={app} />
                </RequireAuth>
              }
            />

            <Route
              path="/cp/rating"
              element={
                <RequireAuth app={app}>
                  <Rating app={app} />
                </RequireAuth>
              }
            />

            <Route
              path="/cp/tournaments/:tournamentId/rating"
              element={
                <RequireAuth app={app}>
                  <Rating app={app} />
                </RequireAuth>
              }
            />

            <Route
              path="/cp/tournaments/:tournamentId/participants"
              element={
                <RequireAuth app={app}>
                  <Participants app={app} />
                </RequireAuth>
              }
            />

            <Route path="*" element={<NotFound />} />

            <Route path="/register" element={<Register app={app} />}></Route>
          </Routes>
        </BrowserRouter>
      </Init>
    );
  }
}

const Init = ({ app, children }) => {
  const [loading, setLoading] = React.useState(true);

  //Загрузка всех необходимых данных
  React.useEffect(() => {
    const load = async () => {
      await request({
        url: "/api/user/token/",
        params: {
          method: "GET",
        },
        app: app,
      });
      setLoading(false);
    };
    setTimeout(() => {
      load();
    }, 1000);
  }, [app]);

  return (
    <>
      {loading && <Loading />}
      {!loading && <>{children}</>}
    </>
  );
};

const RequireAuth = ({ app, children }) => {
  let location = useLocation();
  if (!app.current.loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

const NotLoggedIn = ({ app, children }) => {
  let location = useLocation();
  if (app.current.loggedIn) {
    return <Navigate to="/cp" state={{ from: location }} replace />;
  }
  return children;
};

export default App;
